/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  DialogActions,
  DialogContent,
  Paper,
  Hidden,
  Dialog,
  Slide,
} from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

import DSPCreationSteper from '../../../../utils/DSPCreationSteper';
import DSPBreadCrums from './DSPBreadCrums';
import axios from 'axios';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { StringLiteralLike } from 'typescript';

const useStyles = makeStyles((theme) => ({
  DSPCard: {
    marginBottom: '48px',
  },

  DSPItem: {
    width: '100%',
  },

  DSPContent: {
    position: 'relative',
    padding: "0px",
  },

  DSPFooter: {
    backgroundColor: '#009B74',
    width: '100%',
  },

  footerText: {
    color: '#fff',
    verticalAlign: 'middle',
    padding: '10px 90px',
  },

  cardContent: {
  },



  textfield: {
    width: '100%',
    height: '20vh',
    fontFamily: 'Arial',
    lineHeight: '1.5em',
    fontSize: '16px',
    background: 'white',
    border: '1px solid #707070',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      height: '15vh',
    },
    '& textarea': {
      height: '90%',
      lineHeight: '1.5rem',
      paddingRight: '20px'
    },
    '& .MuiInputBase-root': {
      height: '95%',
      paddingTop: 0,
      paddingBottom: 0
    },
    '& fieldset': {
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',

      },
    },
    // "& .Mui-focused fieldset": {
    //   borderColor: "#C52328",
    //   borderWidth: "2px"
    // },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent'
      },
      "&.Mui-focused textarea:read-only fieldset": {
        borderColor: 'white'
      },
      "&.Mui-focused fieldset": {
        borderWidth: "3px",
        borderColor: '#64A0C8 !important',
      }
    }
  },

  titleBox: {
    marginTop: '5px',
    marginBottom: '1.5rem'

  },

  templateButton: {
    color: '#E37222',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
  },

  backBtn: {
    color: '#0046AD',
    borderColor: '#0046AD',
    borderRadius: '10px'
  },
  selectBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#0046AD',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    }
  },
  breadcrumb: {
    //tablet
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0 1rem'
    },
  },
  age: {
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    margin: '14px 0 0 14px',

  },
  editBtn: {
    width: 'fit-content',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    paddingRight: '2rem',
    fontSize: '1.2rem',
    color: '#009B74',
    marginBottom: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginBottom: '0',
    },
    '& .MuiButton-label': {
      flexDirection: 'column-reverse'
    }
  },
  btns_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2em 0',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      margin: '3em auto',
      width: '50%',
      '& $backBtn': {
        marginTop: '2rem',
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      margin: '3em auto',
      width: '80%',
      '& $backBtn': {
        marginTop: '2rem',
      }
    },
  },
  content_grid: {
    [theme.breakpoints.down('md')]: {

      '& + $content_wrapper': {
        height: '50vh',

      }
    },
  },
  content_wrapper: {
  },
  manual_text: {
    color: 'black',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem'
    }
  },
  strategyListButton: {
    borderRadius: '10px',
    color: '#009B74',
    borderColor: '#009B74',
    textTransform: 'none',
    marginDown:'5px',
    fontSize: '1rem',
  },
  dialogTitle: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dialogTitleText: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#156670',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  saveButton: {
    borderRadius: '8px',
    width: '160px',
    backgroundColor: '#0046AD',
    marginBottom: '10px',
    color: "#FFFFFF",
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '1px solid gray',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      width: '70%',
    },
    '&:disabled': {
      background: 'gray',
      color: '#FFFFFF',
      border: '1px solid gray',

    },
    '&:hover': {
      color: '#0046AD',
      border: '1px solid #0046AD',
      backgroundColor: 'white',
    }
  },
  strategyCard: {
    borderRadius: '10px',
    marginTop: '2%',
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  selectedStrategyCard: {
    borderRadius: '10px',
    marginBottom: '2%',
    marginTop: '2%',
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderColor: '#0046AD',
    borderWidth: '2px',
    borderStyle: 'solid',
    color: '#0046AD',
  },
  dialog: {
    //'& .MuiDialog-scrollPaper': {
      //justifyContent: ''
    //}
    paddingLeft:'5px',
    paddingRight:'5px'
  },
  dialogContent: {
    padding: '0 5% 5%'
  }
}));

const dialogStyles = makeStyles((theme) => ({
  paper: {
    // maxWidth: '1500px',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      maxWidth: '90vw',
      margin: 0,
      bottom: 0,
      position: 'absolute',
    },
  },
}));
// should be: 'completed', 'current', 'uncompleted'
interface StepValue {
  stepNum: string;
  status: string;
  name: string;
}

interface Goal {
  id: number;
  details: string;
  suggestedAge: string | null;
}


interface Category {
  title: string;
  subtitle: string;
  children: Goal[];
  level2Id: number;
  level3Id: number;
}


function DSPCreationStep2(props: any): JSX.Element {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [strategyListModalOpen, setStrategyListModalOpen] = useState(false);

  const classes = useStyles();

  const stepValues: StepValue[] = [
    {
      stepNum: '1',
      status: 'completed',
      name: 'What I can already do'
    },
    {
      stepNum: '2',
      status: 'current',
      name: 'My next goal'
    },
    {
      stepNum: '3',
      status: 'uncompleted',
      name: 'How you can help me reach my goal'
    },
    {
      stepNum: '4',
      status: 'uncompleted',
      name: 'Why this is important for me'
    }
  ]


  const [selectedGoals, setSelectedGoals] = useState<Goal | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category>({} as Category);

  const [categoryList, setCategoryList] = useState<Category[]>([]);

  useEffect(() => {
    if (categoryList.length === 0)
      initGoalList();

    if (sessionStorage.getItem(props.domain.key) === 'completed' ||
      (sessionStorage.getItem(`${props.domain.value.prefix}step2_value`) !== null&&sessionStorage.getItem(`${props.domain.value.prefix}step2_value`) !== '')) {
      // let savedObj = {
      //   level2Id: selectedCategory.level2Id,
      //   level2: selectedCategory.title,
      //   level3Id: selectedCategory.level3Id,
      //   level3: selectedCategory.subtitle,
      //   level4Id: selectedGoals?.id,
      //   level4: textAreaValue,
      // }

      const retrievedObj = JSON.parse(sessionStorage.getItem(`${props.domain.value.prefix}step2_value`) as string)

      const tempGoal: Goal = {
        id: retrievedObj.level4Id,
        details: retrievedObj.level4,
        suggestedAge: null
      }

      const tempCategory: Category = {
        level2Id: retrievedObj.level2Id,
        title: retrievedObj.level2,
        subtitle: retrievedObj.level3,
        level3Id: retrievedObj.level3Id,
        children: []
      }
      tempCategory.children.push(tempGoal);

      setSelectedCategory(tempCategory)
      setSelectedGoals(tempGoal);
      setTextAreaValue(retrievedObj.level4)

    }
  }, []);

  const initGoalList = async () => {
    //TODO: .../{domain}/...
    const domainStr = props.domain.key[0].toLowerCase() + props.domain.key.split(' ').join('').slice(1);
    //console.log({ domainStr });
    try {
      const allCategories = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}catalog/strategies/${domainStr}/level/2`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          'access_token': sessionStorage.getItem('token'),
          crossdomain: true
        }
      })
      console.log(allCategories.data);

      if (allCategories.data.strategies) {
        const allCategoriesData = allCategories.data.strategies;

        const catListValue: Category[] = [];
        for (let i = 0; i < allCategoriesData.length; i++) {
          const temp: Category = {} as Category;
          temp.title = allCategoriesData[i].details;
          temp.level2Id = allCategoriesData[i].id
          catListValue.push(temp)
        }

        const allGoals = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}catalog/strategies/${domainStr}/level/3`,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-type': 'application/json',
            'api_key': process.env.REACT_APP_API_KEY,
            'access_token': sessionStorage.getItem('token'),
            crossdomain: true
          }
        });
        console.log("2");
        const allGoalsData: any = allGoals.data.strategies;
        //console.log({ allGoalsData });
        console.log(allGoalsData.length);
        for (let i = 0; i < allGoalsData.length; i++) {
          console.log(allGoalsData[i].details);
          catListValue[i].subtitle = allGoalsData[i].details ? allGoalsData[i].details : '';

          catListValue[i].level3Id = allGoalsData[i].id;

          catListValue[i].children = allGoalsData[i].children;
        }
        console.log("3");
        //console.log({ catListValue });
         setCategoryList(catListValue);
        // console.log(allGoals.data.strategies);

      }

    } catch (error) {
      console.log({ error });
    }

  }

  const handleSelectGoal = (category: Category, goal: Goal) => {
    let goalContent=goal.details;
    if(goalContent)
      goalContent=goalContent.substring(goalContent.indexOf(":")+1)
    setTextAreaValue(goalContent);
    setSelectedGoals(goal);
    setSelectedCategory(category);
    //setStrategyListModalOpen(false);
  };

  const selectStrategies = () => {
    console.log("in");
    setStrategyListModalOpen(false);
  }

  const renderDialogContents = () => {
    //console.log({ categoryList });
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.dialogTitle}>
              <IconButton style={{ position: 'absolute', left: 0, top: 0 }} onClick={() => setStrategyListModalOpen(false)}>
                <CloseIcon />
              </IconButton>
              <p className={classes.dialogTitleText}>
                Goal List
              </p>
            </div>
            <DialogActions style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '20px' }}>
              <Button className={classes.saveButton} style={{ margin: 0, maxWidth: '100px' }} onClick={selectStrategies}
                disabled={selectedGoals ? false : true}
              >
                Select
              </Button>
            </DialogActions>
            <DialogContent className={classes.dialogContent}>
              {
                categoryList.map((cat, index) => {
                  return (
                    <>
                      <p><b>{cat.title}</b></p>
                      <p>{cat.subtitle}</p>
                      {
                        cat.children.map((child, index) => (
                          <>
                            <Paper
                              key={index}
                              elevation={2}
                              // className={JSON.stringify(selectedGoals) === JSON.stringify(child) ? classes.selectedStrategyCard : classes.strategyCard}
                              className={selectedGoals?.id === child.id ? classes.selectedStrategyCard : classes.strategyCard}
                              onClick={() => handleSelectGoal(cat, child)}>
                              <p>
                                {child.details}
                              </p>
                            </Paper>
                          </>
                        ))
                      }

                    </>
                  )
                })}
            </DialogContent>
          </Grid>
        </Grid>
      </>
    )
  }

  const dialogClasses = dialogStyles();

  const renderStrategyListDialog = () => {
    return (
      <>
        <Dialog
          fullWidth maxWidth={'md'}
          classes={dialogClasses}
          open={strategyListModalOpen}
          onClose={() => setStrategyListModalOpen(false)}
          className={classes.dialog}
        >
          {renderDialogContents()}
        </Dialog>


        {/* <Dialog classes={dialogClasses} fullWidth maxWidth={'md'}
                        open={strategyListModalOpen}
                        onClose={() => setStrategyListModalOpen(false)}
                        >
                        {renderDialogContents()}
                    </Dialog> */}


      </>
    )
  }

  const handleBackBtn = (event: any) => {
    //if (window.confirm('Are you sure you want to leave? Save your DSP locally to keep your progress.')) {
      props.setSession('step1');
    //}
  }

  const handleNextBtn = () => {
    const savedObj = {
      level2Id: selectedCategory.level2Id,
      level2: selectedCategory.title,
      level3Id: selectedCategory.level3Id,
      level3: selectedCategory.subtitle,
      level4Id: selectedGoals?.id,
      level4: textAreaValue,
    }
    sessionStorage.setItem(`${props.domain.value.prefix}step2_value`, JSON.stringify(savedObj));
    props.setSession('step3')
  }

  const renderBtns = (): JSX.Element => {
    return (
      <Box className={classes.btns_wrapper}>
        <Button
          variant="outlined"
          className={classes.backBtn}
          onClick={handleBackBtn}
        >
          Back
        </Button>
        <Button
          variant="contained"
          className={classes.selectBtn}
          disabled={!selectedGoals?.id}
          onClick={handleNextBtn}
        >
          Next
        </Button>
      </Box>
    )
  }
  const handleChange = (e: any): void => {
    let goalContent=e.target.value;
    if(goalContent)
      goalContent=goalContent.substring(goalContent.indexOf(":")+1)
    
    setTextAreaValue(goalContent);
  }
  const defaultShowing = (): JSX.Element => {
    return (
      <>
        <div className={classes.content_wrapper} style={{ background: 'white', padding: '20px' }}>
          <Typography
            variant={'h5'}
            className={classes.manual_text}
          >
            In this section, please select ONE goal that you think is most appropriate for the child.
           
          </Typography>
          <Button variant="outlined" className={classes.strategyListButton} onClick={() => setStrategyListModalOpen(true)}>
              Goal List
          </Button>
          <p></p>
          <Box className={classNames(classes.DSPItem, classes.DSPContent)}>

            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              className={classes.textfield}
              value={textAreaValue}
              onChange={handleChange}
              focused={false}
              disabled={!selectedGoals?.id}
              placeholder="Please select the goal from the Goal List. You can always edit the context if needed."
            />
          
          </Box>
          {renderStrategyListDialog()}
        </div>
        {renderBtns()}
      </>
    )
  }

  const renderSteps = (): JSX.Element => {
    return (
      <DSPCreationSteper steps={stepValues} />
    )
  }
  return (
    <>
      <Grid
        container
        item
        xs={11}
        style={{
          margin: '0 auto',
          display: 'block',
          height: 'unset',
        }}
      >

        <DSPBreadCrums
          setSession={props.setSession}
          session={props.session}
          setExpandMenu={props.setExpandMenu}
          items={[
            { name: 'Create New Plan', link: 'main' },
            { name: 'DSP', link: 'main' },
            { name: props.domain.value.title, link: props.session },
          ]}
        />
        <Box
          className={classNames(classes.DSPItem, classes.titleBox)}
          display={{ xs: 'none', md: 'block' }}
        >
          <Typography
            variant={'h4'}
            style={{ color: '#0046AD', fontWeight: 'bold' }}
          >
            {props.domain.value.title}
          </Typography>
        </Box>

        {renderSteps()}
        <Grid container item md={12} style={{ display: 'flex', flexDirection: 'column' }} className={classes.content_grid}>
          {defaultShowing()}
        </Grid>

      </Grid>



    </>
  )
}

export default DSPCreationStep2;