/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import signin768 from "../img/signin768.png";
import signin992 from "../img/signin992.png";
import signin1200 from "../img/signin1200.png";
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import mockup from '../img/mockup.png';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import AlertDialog from './component/AlertDialog';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    backgroundImage: 'url(' + signin1200 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {
      backgroundImage: 'url(' + signin1200 + ')',
    },
    //tablet
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'url(' + signin992 + ')',
    },
    //mobile
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(' + signin768 + ')',
    }
  },

  signinTitle: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
    margin: '20px auto',
    fontWeight: 600,
    color: '#0046AD'
  },

  loginBox: {
   

    
    margin: 'auto',
    padding: '35px 50px',
    textAlign: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, .7)',
    height: '540px',
    width: '460px',
    justifyContent: 'center',
    [theme.breakpoints.between('md', 'md')]: {
      width: '550px',
      padding: '50px 100px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: '400px',
      padding: '20px 20px',
    },
  },

  inner: {
    padding: '7% 0',
  },

  inputField: {
    margin: '10px auto',
    borderRadius: '8px',
    'input': {
      borderRadius: '8px',
      border: 'none',
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      boxShadow: '0 3px 3px #e5e5e5',
      borderColor: 'rgb(255 255 255)'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046AD'
    }
  },

  submitBtn: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0 20px 0',
    },
    backgroundColor: 'white',
    border: '2px solid #D71F85',
    fontSize: '20px',
    color: '#D71F85',
    fontWeight: 600,
    borderRadius: '10px',
    marginBottom: '40px',
    marginTop: '20px',
  },

  dividerContainer: {
    display: "flex",
    alignItems: "center"
  },

  dividerBorder: {
    borderBottom: "2px solid #727679",
    width: "100%"
  },

  dividerContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: "#727679"
  },

  mockup: {
    [theme.breakpoints.between('md', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      order: '2',
    },
  },

  mockupInner: {
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      marginTop: '30px',
    },
  },

  outerLogin: {
    justifyContent: 'flex-end',
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      order: '3',
    },
  },

  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },

  forgetPW: {
    color: '#707070',
    textDecoration: 'underline',
    float: 'right',
    marginTop: '18px',
    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
      float: 'none'
    },
  },

  rememberMe: {
    float: 'left',
    marginTop: '10px',
  }

}));

export default function LoginPageRev(): JSX.Element {
  const classes = useStyles();
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(0);
  const [alertMsg, setAlertMsg] = useState('');
  const history =useHistory();

  useEffect(() => {
    if(localStorage.getItem('email')) {
      setRememberMe(true);
    }
  }, []);

  const onLoginClickhandler = (e: any) => {
    e.preventDefault();
    const form = e.target;

    if (!isEmailValid) { setIsEmailValid(false); }
    else if (!isPasswordValid) { setIsPasswordValid(false); }
    else {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}user/login`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        },
        data: {
          email: form.email.value,
          password: form.password.value,
        },
      }).then(res => {
        sessionStorage.setItem('token', res.data.accessToken);
        sessionStorage.setItem('email', res.data.user.email);
        sessionStorage.setItem('userRole', res.data.user.userRole);
        if (rememberMe) {
          localStorage.setItem('email', res.data.user.email);
        }
        else {
          localStorage.removeItem('email');
        }
        history.push('/customerDashBoard')
      }).catch(error => {
        console.log('exception');
        setAlertMsg(error.response.data.error);
        setIsAlertOpen(isAlertOpen + 1);
      });
    }

  }

  const onEmailBlurHandler = (e: any) => {
    const regEx: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value !== '' && e.target.value !== null && regEx.test(e.target.value)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };

 

  return (
    <>
      <AlertDialog isOpen={isAlertOpen} msg={alertMsg} />
      <Header />
      <Container maxWidth={false} disableGutters={true} >
      <div className={classes.root}>
      <p>&nbsp; </p>
      <Grid container component="main" className={classes.image} direction="row" alignItems='center' justifyContent='center'>
         
        <Box className={classes.inner}>
          <Grid container justifyContent="center" alignItems="center" className={classes.gridContainer}>
            <Grid item md={12} sm={12} xs={12} alignItems="center" justifyContent="center">
              <Box className={classes.loginBox}>
                <Typography variant="h4" className={classes.signinTitle}>Sign In</Typography>
                <form noValidate onSubmit={onLoginClickhandler}>
                  <TextField
                    variant="outlined"
                    error={!isEmailValid}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    className={classes.inputField}
                    onChange={onEmailBlurHandler}
                    helperText={!isEmailValid ? 'Email invalid' : ''}
                    InputProps={{
                      defaultValue: localStorage.getItem('email') ? localStorage.getItem('email') : ''
                    }}
                  />
                  <TextField
                    variant="outlined"
                    type={passwordShown ? "text" : "password"}
                    error={!isPasswordValid}
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    onChange={(e) => { if (e.target.value !== '' && e.target.value !== undefined) { setIsPasswordValid(true); } else { setIsPasswordValid(false) } }}
                    className={classes.inputField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setPasswordShown(!passwordShown)}
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {passwordShown ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControlLabel control={<Checkbox id="rememberMe" checked={rememberMe} onChange={(e: any) => setRememberMe(e.target.checked)}/>} label="Remember Me" className={classes.rememberMe} />
                  <Link href="/ForgotPassword" className={classes.forgetPW}>Forgot password?</Link>
                  <Button
                    fullWidth
                    type="submit"
                    className={classes.submitBtn}
                  >
                    Sign IN
                  </Button>
                </form>
                <div className={classes.dividerContainer}>
                  <div className={classes.dividerBorder} />
                  <span className={classes.dividerContent}>Or</span>
                  <div className={classes.dividerBorder} />
                </div>
                <Typography variant="body1" style={{ color: '#727679', margin: '20px 0' }}>
                  No Account? <Link href="/SignUp" style={{ color: '#0046AD' }}>Sign Up Now</Link>
                </Typography>
              </Box>
              
            </Grid>
            
          </Grid>
        </Box>
        </Grid>
        </div>
      </Container>
      <Footer />
    </>
  )
}