
/* eslint-disable */
import React, { useState } from "react";
import axios from "axios";
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, 
    DialogActions, TextField, MenuItem, InputLabel, Select, FormControl } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useDialogStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '3%',
        borderRadius: '10px',
    },
    root: {
        '& .MuiTypography-root': {
            textAlign: 'center',
        },
        '& .MuiTypography-h6': {
            fontSize: '3rem',
            lineHeight: 1.3,
            "@media (max-width: 600px)": {
                fontSize: '1.3rem',
            },
            fontWeight: 'bold',
            color: '#0046AD'
        },
        '& .MuiTypography-body1': {
            "@media (max-width: 600px)": {
                fontSize: '12px',
            },
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    menu: {
        "& .MuiPaper-root": {
            marginTop: '8px',
            borderColor: '#0046AD',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '10px',
            maxHeight: '20%',
            overflowY: 'scroll',
        },
        "& .MuiListItem-root": {
            "@media (max-width: 600px)": {
                fontSize: '12px',
            },
        },
    },
    submitButton: {
        borderRadius: '25px',
        width: '60%',
        background: 'linear-gradient(0deg, #002357, #0044A9)',
        marginTop: '10px',
        marginBottom: '5%',
        color: "#FFFFFF",
        fontSize: '20px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        "@media (max-width: 600px)": {
            fontSize: '12px',
        },
        '&:disabled': {
            background: 'gray',
            color: '#FFFFFF',
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        width: '65%',
        marginBottom: '5%',
        '@media (max-width: 600px)': {
            width: '90%',
            '& .MuiSelect-root': {
                fontSize: '12px',
            },
            '& .MuiFormLabel-root': {
                fontSize: '12px',
            },
        },
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        width: '60%',
    }

}));

export default function FirstTimeProfileDialog(props: any): JSX.Element {
    const dialogClasses = useDialogStyles();
    const classes = useStyles();

    const { open, toggleOpen } = props;
    const [profession, setProfession] = useState('');
    const [otherProfession, setOtherProfession] = useState('');
    const [country, setCountry] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');

    const handleSubmit = () => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/profile/location`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
                'api_key': process.env.REACT_APP_API_KEY,
                'access_token': sessionStorage.getItem('token'),
                crossdomain: true
            },
            data: {
                country: country,
                province: province,
                city: city,
            }
        }).then(res => {
            
            axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}user/profile/profession`,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'api_key': process.env.REACT_APP_API_KEY,
                    'access_token': sessionStorage.getItem('token'),
                    crossdomain: true
                },
                data: {
                    profession: otherProfession ? otherProfession : profession,
                }
            }).then(res => {
                // successfully updated profile
                toggleOpen();
            }).catch(error => {
                console.log('Error updating profession: ', error);
            });

        }).catch(error => {
            console.log('Error updating location: ', error);
        });
    }

    const renderProfessionMenu = () => {
        const menuOptions = [
            "Children's Mental Health",
            "Early Learning and Early Care",
            "Child Welfare / Child Protection",
            "Nurses",
            "Social Workers",
            "Physicians",
            "Other, please specify"
        ];

        const handleChange = (event: any) => {
            setProfession(event.target.value);
            setOtherProfession('');
        };

        return (
            <FormControl className={classes.input} >
                <InputLabel id="profession-select-label" style={{ marginLeft: '15px' }}>Profession/Sector *</InputLabel>
                <Select
                    labelId="profession-select-label"
                    id="profession-select"
                    value={profession}
                    onChange={handleChange}
                    variant="outlined"
                    label="Profession/Sector *"
                    required
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        className: classes.menu,
                    }}
                >

                    {menuOptions.map((option: string, index: number) =>
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

    const renderCountryMenu = () => {
        const menuOptions = [
            "Canada",
            "Other"
        ];

        const handleChange = (event: any) => {
            setCountry(event.target.value);
            // if (event.target.value == "Other") {
            //     setProvince('');
            // }
            setProvince('');
        };

        return (
            <FormControl className={classes.input} >
                <InputLabel id="country-select-label" style={{ marginLeft: "15px" }}>Country *</InputLabel>
                <Select
                    labelId="country-select-label"
                    id="country-select"
                    value={country}
                    onChange={handleChange}
                    variant="outlined"
                    label="Country/Region *"
                    required
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        className: classes.menu,
                    }}
                >

                    {menuOptions.map((option: string, index: number) =>
                        <MenuItem key={index} value={option}>{option}</MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

    const renderProvinceMenu = () => {
        const menuOptionsAll = [
            "Alberta",
            "British Columbia",
            "Manitoba",
            "New Brunswick",
            "Newfoundland and Labrador",
            "Northwest Territories",
            "Nova Scotia",
            "Nunavut",
            "Ontario",
            "Prince Edward Island",
            "Quebec",
            "Saskatchewan",
            "Yukon",
            "Other"
        ];

        const menuOptionsOther = [
            "Other"
        ];

        const handleChange = (event: any) => {
            setProvince(event.target.value);
        };

        return (
            <FormControl className={classes.input} >
                <InputLabel id="province-select-label" style={{ marginLeft: "15px" }}>Province *</InputLabel>
                <Select
                    labelId="province-select-label"
                    id="province-select"
                    value={province}
                    onChange={handleChange}
                    variant="outlined"
                    label="Province *"
                    required
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        className: classes.menu,
                    }}
                >

                    {country == "Other" ?
                        menuOptionsOther.map((option: string, index: number) =>
                            <MenuItem key={index} value={option}>{option}</MenuItem>
                        ) :
                        menuOptionsAll.map((option: string, index: number) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                    }
                </Select>
            </FormControl>
        )
    }

    return (
        <Dialog classes={dialogClasses} open={open} >
            <DialogTitle>Complete Your Profile</DialogTitle>
            <DialogContent>
                <DialogContentText>
                   In order to access the dashboard, please enter the following information.
                </DialogContentText>

                <div className={classes.form}>
                    {renderProfessionMenu()}
                    { profession == "Other, please specify" && <TextField className={classes.input} label="Profession / Sector - Other" variant="outlined" onChange={(event) => setOtherProfession(event.target.value)}/> }
                    {renderCountryMenu()}
                    {renderProvinceMenu()}
                    <TextField className={classes.input} required label="City" variant="outlined" onChange={(event) => setCity(event.target.value)}/>
                </div>

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.submitButton} onClick={handleSubmit} 
                    disabled={!profession || !country || !province || !city}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )

}