/* eslint-disable no-unused-vars */

import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import faqs from '../../../img/FAQs.png';
import video from '../../../img/Video.png';
import faqsWideImg from '../../../img/FAQsWide.png';
import videoWideImg from '../../../img/VideoWide.png';
import {globalStyles} from "../../../GlobalStyles";
import FAQs from "./FAQs";
import Video from "./Video";


const useStyles = makeStyles((theme) => ({
  newDsplistIcon: {

    '&:hover': {
      cursor: "pointer"
    },
  },
  updateDsplistIcon: {

    '&:hover': {
      cursor: "pointer"

    },
  },
  listText: {
    fontWeight: 600,

    margin: '0 auto',
  },
  listContainer: {
    textAlign: 'center',
  },
}));

const HelpCenter = (props:any): JSX.Element => {
  const classes = useStyles();
  const globalClasses=globalStyles();
  const [item,setHelp]=useState('help');



    useEffect(() => {
      console.log('in');
      if(props.selectmenu==='help')
        setHelp("help");
    },[]);

    const onClickFAQ=()=>{
      setHelp("faqs");
    }
    const onClickVideo=()=>{
      setHelp("video");
    }
  return (
    <>
     {item==='faqs'&&<FAQs setHelp={setHelp}></FAQs>}
     {item==='video'&&<Video setHelp={setHelp}></Video>}
     
      {item==='help'&&<>
        <Grid item xs={12}>
              <Typography
                variant='h4'
                className={globalClasses.titleText}
              >
                Help Centre
              </Typography>
            </Grid><Grid container style={{ marginTop: '-15px' }}>


            <Hidden smDown>
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={onClickFAQ}>
              <img src={faqs} alt='FAQ' className={classes.newDsplistIcon} />
      
            </Grid>
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={onClickVideo}>
            <img src={video} alt='Video' className={classes.updateDsplistIcon} />
            </Grid>
            </Hidden>

            <Hidden mdUp>
              <Grid item xs={12}  alignItems='center' justifyContent="center" style={{marginTop:'60px'}} >
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={onClickFAQ}>
            <img src={faqsWideImg} alt='FAQ' className={classes.newDsplistIcon} style={{ width: '70%' }} />
      
            </Grid>
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={onClickVideo}>
            <img src={videoWideImg} alt='Video' className={classes.updateDsplistIcon} style={{ width: '70%' }} />
        </Grid>
        </Grid>
              </Hidden>

      </Grid>
    </>
    }
    
      
    </>
  )

}

export default HelpCenter;