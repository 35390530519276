/* eslint-disable no-unused-vars */
// TypeScript issue with sliding transition props
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Grid,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogContent,
    IconButton,
    DialogActions,
    Slide,
    TransitionProps,
    Hidden,
    Box
} from '@material-ui/core';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DSPCreationSteper from '../../../../utils/DSPCreationSteper';
import DSPBreadCrums from './DSPBreadCrums';

const useStyles = makeStyles((theme) => ({
    DSPCard: {
        width: "90%",
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '16px',
        paddingBottom: '16px',
        marginBottom: '3%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
    },
    root: {
        margin: '0 auto',
        display: 'block',
        height: 'unset',
    },
    titleText: {
        color: '#0046AD',
        fontSize: '2rem',
        fontWeight: 'bold',
        fontFamily: 'Arial',
        marginTop: '10px',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.6rem',
        },
    },
    instructions: {
        color: '#000000',
        fontSize: '1.5rem',
        // fontWeight: 'bold',
        fontFamily: 'Arial',
        marginTop: '10px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
            fontSize: '1.1rem',
        },
    },
    instructions1: {
        color: '#000000',
        fontSize: '1.5rem',
        // fontWeight: 'bold',
        fontFamily: 'Arial',
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft:'40px',
        marginRight:'40px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
            fontSize: '1.1rem',
            marginLeft:'10px',
            marginRight:'10px',
        },
    },
    input: {
        width: '100%',
        marginTop: '2%',
        marginBottom: '2%',
        '& .Mui-disabled': {
            backgroundColor: '#F0F0F0',
            color: '#000000',
        },
        '& .MuiFormLabel-root': {
            paddingRight: '14px',
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiSelect-root': {
                fontSize: '14px',
            },
            '& .MuiFormLabel-root': {
                fontSize: '14px',
            },
            '& .MuiInputBase-input': {
                fontSize: '14px',
            },
        },
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column-reverse",
        }
    },
    backButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            justifyContent: 'center',
        },
    },
    saveButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            justifyContent: 'center',
        },
    },
    backButton: {
        borderRadius: '8px',
        borderWidth: '2px',
        width: '160px',
        borderColor: "#0046AD",
        marginBottom: '10px',
        color: "#0046AD",
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '70%',
        },
    },
    saveButton: {
        borderRadius: '8px',
        width: '160px',
        backgroundColor: '#0046AD',
        marginBottom: '10px',
        color: "#FFFFFF",
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '70%',
        },
        '&:disabled': {
            background: 'gray',
            color: '#FFFFFF',
        }
    },
    titleBox: {
        marginTop: '5px',
        marginBottom: '1.5rem',
        width: '100%',
    },
    strategyContainer: {
        display: "flex",
        width: '100%',
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between"
    },
    strategyListButton: {
        alignSelf: 'flex-end',
        color: '#009B74',
        borderColor: '#009B74',
        textTransform: 'none',
        float:'left'
    },
    strategyCard: {
        borderRadius: '10px',
        marginBottom: '2%',
        padding: 'min(4%, 20px)',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    selectedStrategyCard: {
        borderRadius: '10px',
        marginBottom: '2%',
        padding: 'min(4%, 20px)',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        borderColor: '#0046AD',
        borderWidth: '1px',
        borderStyle: 'solid',
        color: '#0046AD',
    },
    suggestedAgeText: {
        margin: 0,
        color: '#727679',
        alignSelf: 'flex-end',
    },
    dialogTitle: {
        paddingTop: '15px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dialogTitleText: {
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '1.5rem',
        color: '#156670',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    },
    summaryText: {
        fontSize: '16px',
        color: '#000000',
        fontWeight: 'normal',
    },
    summaryTextBold: {
        fontSize: '16px',
        color: '#0046AD',
        fontWeight: 'bold'
    },
}));

const dialogStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: '1500px',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            maxWidth: '90vw',
            margin: 0,
            bottom: 0,
            position: 'absolute',
        },
    },
}));

interface StepValue {
    stepNum: string;
    status: string;
    name: string;
}

function DSPCreationStep3(props: any): JSX.Element {
    const classes = useStyles();

    const { setSession } = props;
    const [strategies, setStrategies] = useState<string[]>(['', '', '']);
    const [strategyIds, setStrategyIds] = useState<number[]>([-1, -1, -1]);

    const stepValues: StepValue[] = [
        {
            stepNum: '1',
            status: 'completed',
            name: 'What I can already do'
        },
        {
            stepNum: '2',
            status: 'completed',
            name: 'My next goal'
        },
        {
            stepNum: '3',
            status: 'current',
            name: 'How you can help me reach my goal'
        },
        {
            stepNum: '4',
            status: 'uncompleted',
            name: 'Why this is important for me'
        }
    ]

    useEffect(() => {
        let storedObj: any = sessionStorage.getItem(`${props.domain.value.prefix}step3_value`);
        if (storedObj) {
            storedObj = JSON.parse(storedObj)
            setStrategies(storedObj.level5);
            setStrategyIds(storedObj.level5Ids);
            //console.log('storedObj :>> ', storedObj);
        }
    }, []);

    const handleBack = (event: any) => {
        //if (window.confirm('Are you sure you want to leave? Save your DSP locally to keep your progress.')) {
        setSession('step2');
        //}
    }

    const handleNext = (event: any) => {
        const savedObj = {
            level5Ids: strategyIds,
            level5: strategies,
        }
        sessionStorage.setItem(`${props.domain.value.prefix}step3_value`, JSON.stringify(savedObj));
        setSession('step4');
    }

    return (

        <Grid container item xs={11} className={classes.root}>

            <DSPBreadCrums
                setSession={props.setSession}
                session={props.session}
                setExpandMenu={props.setExpandMenu}
                items={[
                    { name: 'Create New Plan', link: 'main' },
                    { name: 'DSP', link: 'main' },
                    { name: props.domain.value.title, link: props.session },
                ]}
            />
            <Box
                className={classes.titleBox}
                display={{ xs: 'none', md: 'block' }}
            >
                <Typography
                    variant={'h4'}
                    style={{ color: '#0046AD', fontWeight: 'bold' }}
                >
                    {props.name}
                </Typography>
            </Box>

            <DSPCreationSteper steps={stepValues} />


            <StrategyCard
                strategies={strategies}
                setStrategies={setStrategies}
                setStrategyIds={setStrategyIds}
                prefix={props.domain.value.prefix}
                domain={props.domain}
            />

            <Grid container className={classes.buttonContainer}>
                <Grid item xs={12} md={6} className={classes.backButtonGrid}>
                    <Button variant='outlined' className={classes.backButton} onClick={handleBack}>
                        Back
                    </Button>
                </Grid>

                <Grid item xs={12} md={6} className={classes.saveButtonGrid} >
                    <Button variant='contained' className={classes.saveButton} onClick={handleNext} disabled={strategies.includes('')}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function StrategyCard(props: any) {
    const classes = useStyles();
    const dialogClasses = dialogStyles();

    const { strategies, setStrategies, setStrategyIds, prefix } = props;
    //console.log({ strategies })
    const [strategyListModalOpen, setStrategyListModalOpen] = useState(false);
    const [allStrategies, setAllStrategies] = useState<any[]>([]);
    const [selectedStrategies, setSelectedStrategies] = useState<any[]>([]);

    useEffect(() => {
        
        const domainStr = props.domain.key[0].toLowerCase() + props.domain.key.split(' ').join('').slice(1);

        let step2Value: any = sessionStorage.getItem(`${prefix}step2_value`);

        if (step2Value) {
            step2Value = JSON.parse(step2Value);
            const id = step2Value.level4Id;
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}catalog/strategies/${domainStr}/${id}`,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'api_key': process.env.REACT_APP_API_KEY,
                    'access_token': sessionStorage.getItem('token'),
                    crossdomain: true
                }
            }).then(res => {
                setAllStrategies(res.data.children);
            });
        }
        // const data = [
        //     {
        //         "id": 23,
        //         "title": "Strategies",
        //         "details": "Hearing your voice will draw my attention to your face, so talk to me often and let me see your face as much as possible. When you are talking to me, hold me facing you so that we can make a connection. Let me touch your face as you talk to me. Watching you as you talk to me helps me get to know you and understand facial expressions. It makes me feel loved and important when you look into my eyes. \r\n"
        //     },
        //     {
        //         "id": 24,
        //         "title": "Strategies",
        //         "details": "I love to hear your voice.  Hold me in your arms, look at my face and gently sway me while you  sing  lullabies  and  traditional  songs.  Being  close  to  you  makes  me  feel  safe,  and  hearing our language helps me feel a part of the world. \r\n"
        //     },
        //     {
        //         "id": 25,
        //         "title": "Strategies",
        //         "details": "During  bath  time,  or  when  changing  my  diaper,  talk  to  me  about  what  you  are  doing.  Look at my face, use my name and gaze into my eyes. When I can see your face it will help me to stay calm, even if I don’t like bath time. \r\n"
        //     },
        //     {
        //         "id": 26,
        //         "title": "Strategies",
        //         "details": "If possible, have my stroller seat facing you so that when we go for walks I can watch your face.    We  can  talk  about  where  we  are  going  and  what  we  are  seeing.  This  will  help  me  learn about the world through your eyes.   \r\n"
        //     },
        //     {
        //         "id": 27,
        //         "title": "Strategies",
        //         "details": "In  the car or on the bus, sit  next to me, talk to me and make eye contact with me. \r\n"
        //     },
        //     {
        //         "id": 28,
        //         "title": "Strategies",
        //         "details": "During feeding/meals, talk to me and make eye contact with me often. \r\n"
        //     },
        //     {
        //         "id": 29,
        //         "title": "Strategies",
        //         "details": "While telling a story or reading a book, check in and make eye contact with me often. Look at me and ask me questions about the story, and what we can see or imagine. \r\n"
        //     },
        //     {
        //         "id": 30,
        //         "title": "Strategies",
        //         "details": "When you are cooking, cleaning or doing other things, keep me  near  and  make  eye  contact  with  me  often, and speak or sing to me.  Maybe I can “help” you fold laundry or sweep up.  \r\n"
        //     },
        //     {
        //         "id": 31,
        //         "title": "Strategies",
        //         "details": "Place me in your lap, on your tummy or in your arms, make eye contact and sing me a song, moving to the rhythm of the music.  \r\n"
        //     },
        //     {
        //         "id": 32,
        //         "title": "Strategies",
        //         "details": "Use my name when you talk to me.\r\n"
        //     },
        //     {
        //         "id": 33,
        //         "title": "Strategies",
        //         "details": "When we are talking, get down to my level and make sure you have my  attention  by  looking  into  my  eyes.  \r\n"
        //     },
        //     {
        //         "id": 34,
        //         "title": "Strategies",
        //         "details": "Play “peek-a-boo” with me sitting on your lap. Wait for a few seconds after each “boo” to let me look at you and giggle. \r\n"
        //     },
        //     {
        //         "id": 35,
        //         "title": "Strategies",
        //         "details": "Let’s sit together during meals. Make sure that we are at the same level, facing each other and  making  eye  contact  as  we  talk  and  enjoy  what  the  creator  has  provided  –  healthy  food and the chance to know each other better.  \r\n"
        //     },
        //     {
        //         "id": 36,
        //         "title": "Strategies",
        //         "details": "Sit  with  me  at  my  eye  level.  Point  out  the  parts  of  your  face  and  ask  me  to  imitate  you.  Example:  “This  is  my  nose,  where’s  your  nose?”  If  I  do  not  understand,  guide  my  hand  to  touch my nose and say \"nose.\" \r\n"
        //     },
        //     {
        //         "id": 37,
        //         "title": "Strategies",
        //         "details": "When you ask me to do something, like put on my coat or put something away, make sure you  get  down  to  my  level  and  make  eye  contact.  If  I’m  not  looking  at  you,  gently  cradle  my face with your hands so you have my full attention and say my name, “please put your coat on”.\r\n"
        //     },
        //     {
        //         "id": 38,
        //         "title": "Strategies",
        //         "details": "Get down to my level when you are giving me instructions; speak clearly and gently.  Ask me if I understand, and let me tell it back to you to be sure I’ve got it. Remember, I can probably only remember one or two tasks at a time! \r\n"
        //     }
        // ];
        // setAllStrategies(data);
    }, []);

    const handleClickStrategy = (strategy: string) => {
        if (selectedStrategies.includes(strategy)) {
            setSelectedStrategies(selectedStrategies.filter(item => item !== strategy));
        }
        else if (selectedStrategies.length < 3) {
            setSelectedStrategies([...selectedStrategies, strategy]);
        }
    };

    const selectStrategies = () => {
        setStrategies(selectedStrategies.map(strategy => strategy.details));
        setStrategyIds(selectedStrategies.map(strategy => strategy.id));
        setStrategyListModalOpen(false);
    }

    const renderDialogContents = () => {
        return (
            <>
                <div className={classes.dialogTitle}>
                    <IconButton style={{ position: 'absolute', left: 0, top: 0 }} onClick={() => setStrategyListModalOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                    <p className={classes.dialogTitleText}>
                        Strategy List
                    </p>
                    </div>
                    <Typography
                variant="h4" className={classes.instructions1}>
                Please select THREE Strategies from the below list.
            </Typography>
                <DialogActions style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '20px' }}>
                    <Button className={classes.saveButton} style={{ margin: 0, maxWidth: '100px' }} onClick={selectStrategies} disabled={selectedStrategies.length < 3}>
                        Select
                    </Button>
                </DialogActions>
                <DialogContent>
                    {
                        allStrategies.map((item, index) => {
                            const isSelected = selectedStrategies.includes(item);

                            return (
                                <Paper key={index} elevation={2} className={isSelected ? classes.selectedStrategyCard : classes.strategyCard} onClick={() => handleClickStrategy(item)}>
                                    <p>
                                        {item.details}
                                    </p>
                                    <p className={classes.suggestedAgeText}>
                                        Suggested age: {item.suggestedAge}
                                    </p>
                                </Paper>
                            )
                        })}
                </DialogContent>
            </>
        )
    }

    const renderStrategyListDialog = () => {
        return (
            <>
                <Hidden smDown>
                    <Dialog classes={dialogClasses} fullWidth maxWidth={'md'}
                        open={strategyListModalOpen}
                        onClose={() => setStrategyListModalOpen(false)}>
                        {renderDialogContents()}
                    </Dialog>

                </Hidden>

                <Hidden mdUp>
                    <Dialog classes={dialogClasses} fullWidth maxWidth={'md'}
                        open={strategyListModalOpen}
                        onClose={() => setStrategyListModalOpen(false)}
                        TransitionComponent={Transition}>
                        {renderDialogContents()}
                    </Dialog>

                </Hidden>
            </>
        )
    }

    const renderStrategies = () => {

        const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
            const list = [...strategies];
            list[index] = event.target.value;
            setStrategies(list);
        }

        return (
            strategies.map((strategy: string, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <div className={classes.strategyContainer}>
                            <TextField value={strategy} className={classes.input} required variant="outlined"
                                placeholder="Select one strategy from the Strategy List here. You can always edit the context if needed."
                                fullWidth
                                multiline
                                rows={3}
                                onChange={e => handleChange(e, index)} />
                            
                        </div>
                        {renderStrategyListDialog()}
                    </React.Fragment>
             )
            })
        )
    }

    return (
        <>
            <Typography
                variant="h4" className={classes.instructions}>
                In this section, please select THREE strategies that you think would work best for the child and their caregivers.
            </Typography>
            <div style={{float:'left'}}>
            <Button variant="outlined" className={classes.strategyListButton} onClick={() => setStrategyListModalOpen(true)}>
                Strategy List
            </Button>
            </div>
            <p></p>
            {renderStrategies()}

    </>
    )
}


export default DSPCreationStep3;