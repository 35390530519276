/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Grid,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Box
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import DSPCreationSteper from '../../../../utils/DSPCreationSteper';
import DSPBreadCrums from './DSPBreadCrums';

const useStyles = makeStyles((theme) => ({
    DSPCard: {
        width: "90%",
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '16px',
        paddingBottom: '16px',
        marginBottom: '3%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
    },
    root: {
        margin: '0 auto',
        display: 'block',
        height: 'unset',
    },
    titleText: {
        color: '#0046AD',
        fontSize: '2rem',
        fontWeight: 'bold',
        fontFamily: 'Arial',
        marginTop: '10px',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.6rem',
        },
    },
    instructions: {
        color: '#000000',
        fontSize: '1.5rem',
        fontFamily: 'Arial',
        marginTop: '10px',
        marginBottom: '20px',
        alignSelf: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1rem',
        },
    },
    input: {
        width: '100%',
        marginTop: '2%',
        marginBottom: '2%',
        '& .Mui-disabled': {
            backgroundColor: '#F0F0F0',
            color: '#000000',
        },
        '& .MuiFormLabel-root': {
            paddingRight: '14px',
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiSelect-root': {
                fontSize: '14px',
            },
            '& .MuiFormLabel-root': {
                fontSize: '14px',
            },
            '& .MuiInputBase-input': {
                fontSize: '14px',
            },
        },
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column-reverse",
        }
    },
    backButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            justifyContent: 'center',
        },
    },
    saveButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            justifyContent: 'center',
        },
    },
    backButton: {
        borderRadius: '8px',
        borderWidth: '2px',
        width: '160px',
        borderColor: "#0046AD",
        marginBottom: '10px',
        color: "#0046AD",
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '60%',
        },
    },
    saveButton: {
        borderRadius: '8px',
        width: '160px',
        backgroundColor: '#0046AD',
        marginBottom: '10px',
        color: "#FFFFFF",
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '60%',
        },
        '&:disabled': {
            background: 'gray',
            color: '#FFFFFF',
        }
    },
    titleBox: {
        marginTop: '5px',
        marginBottom: '1.5rem',
        width: '100%',
    },
    benefitContainer: {
        display: "flex",
        width: '100%',
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between"
    },
    benefitListButton: {
        color: '#009B74',
        borderColor: '#009B74',
        textTransform: 'none',
        folat:'left'
    },
    benefitCard: {
        borderRadius: '10px',
        marginBottom: '2%',
        padding: 'min(4%, 20px)',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    selectedBenefitCard: {
        borderRadius: '10px',
        marginBottom: '2%',
        padding: 'min(4%, 20px)',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        borderColor: '#0046AD',
        borderWidth: '1px',
        borderStyle: 'solid',
        color: '#0046AD',
    },
    dialogTitle: {
        paddingTop: '15px',
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '1.5rem',
        color: '#156670',
    },
    duplicateText: {
        fontSize: '16px',
        color: '#000000',
        fontWeight: 'normal',
    },
    duplicateTextBold: {
        fontSize: '16px',
        color: '#000000',
        fontWeight: 'bold'
    },
    instructions1: {
        color: '#000000',
        fontSize: '1.5rem',
        // fontWeight: 'bold',
        fontFamily: 'Arial',
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft:'40px',
        marginRight:'40px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
            fontSize: '1.1rem',
            marginLeft:'10px',
            marginRight:'10px',
        },
    },
}));

const dialogStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: '1500px',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            maxWidth: '90vw',
            margin: 0,
            bottom: 0,
            position: 'absolute',
        },
    },
}));

interface StepValue {
    stepNum: string;
    status: string;
    name: string;
}

function DSPCreationStep4(props: any): JSX.Element {
    const classes = useStyles();

    const { setSession } = props;
    const [benefitsText, setBenefitsText] = useState('');

    const stepValues: StepValue[] = [
        {
            stepNum: '1',
            status: 'completed',
            name: 'What I can already do'
        },
        {
            stepNum: '2',
            status: 'completed',
            name: 'My next goal'
        },
        {
            stepNum: '3',
            status: 'completed',
            name: 'How you can help me reach my goal'
        },
        {
            stepNum: '4',
            status: 'current',
            name: 'Why this is important for me'
        }
    ]

    useEffect(() => {
        let storedObj :any = sessionStorage.getItem(`${props.domain.value.prefix}step4_value`);
        if (storedObj) {
            storedObj = JSON.parse(storedObj)
            setBenefitsText(storedObj.level6)
        }
    }, []);

    const handleBack = () => {
        //if (window.confirm('Are you sure you want to leave? Save your DSP locally to keep your progress.')) {
            setSession('step3');
        //}
    }

    const handleNext = () => {
        sessionStorage.setItem(`${props.domain.value.prefix}step4_value`, JSON.stringify({ level6: benefitsText }));
        sessionStorage.setItem(props.domain.key, 'completed');
        sessionStorage.setItem('plan', 'completed');
        setSession('main');
    }

    return (

        <Grid container item md={11} className={classes.root}>
            <DSPBreadCrums
                setSession={props.setSession}
                session={props.session}
                setExpandMenu={props.setExpandMenu}
                items={[
                    { name: 'Create New Plan', link: 'main' },
                    { name: 'DSP', link: 'main' },
                    { name: props.domain.value.title, link: props.session },
                ]}
            />
            <Box
                className={classes.titleBox}
                display={{ xs: 'none', md: 'block' }}
            >
                <Typography
                    variant={'h4'}
                    style={{ color: '#0046AD', fontWeight: 'bold' }}
                >
                    {props.name}
                </Typography>
            </Box>

            <DSPCreationSteper steps={stepValues} />

            <BenefitCard benefitsText={benefitsText} setBenefitsText={setBenefitsText} prefix={props.domain.value.prefix} domain={props.domain}/>

            <Grid container className={classes.buttonContainer}>
                <Grid item xs={12} md={6} className={classes.backButtonGrid}>
                    <Button variant='outlined' className={classes.backButton} onClick={handleBack}>
                        Back
                    </Button>
                </Grid>

                <Grid item xs={12} md={6} className={classes.saveButtonGrid} >
                    <Button variant='contained' className={classes.saveButton} onClick={handleNext} disabled={benefitsText === ''}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

function BenefitCard(props: any) {
    const classes = useStyles();
    const dialogClasses = dialogStyles();

    const { benefitsText, setBenefitsText, prefix } = props;
    const [benefitListDialogOpen, setBenefitListDialogOpen] = useState(false);
    const [confirmDuplicatesDialogOpen, setConfirmDuplicatesDialogOpen] = useState(false);
    const [strategies, setStrategies] = useState<string[]>([]);
    const [benefits, setBenefits] = useState<string[]>(['', '', '']);
    const [allBenefits, setAllBenefits] = useState<any[]>([[], [], []]);
    const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);

    useEffect(() => {
        const domainStr = props.domain.key[0].toLowerCase() + props.domain.key.split(' ').join('').slice(1);
        //console.log({ domainStr });
        let storedObj: any = sessionStorage.getItem(`${prefix}step3_value`);
        if (storedObj) {
            storedObj = JSON.parse(storedObj)
            setStrategies(storedObj.level5);

            const strategyIds = storedObj.level5Ids;
            Promise.all(strategyIds?.map((id: number) => {
                return axios({
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}catalog/strategies/${domainStr}/${id}`,
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json',
                        'api_key': process.env.REACT_APP_API_KEY,
                        'access_token': sessionStorage.getItem('token'),
                        crossdomain: true
                    }
                })
            }))
                .then((values) => {
                    const benefitsList = values.map(value => value.data.children);
                    setAllBenefits(benefitsList);
                })
        }
    }, []);

    useEffect(() => {
        setBenefitsText(benefits.join(' ').trim());
    }, [benefits]);

    const handleClickBenefit = (strategyIndex: number, details: string) => {
        if (selectedBenefits[strategyIndex] === details) {
            const newBenefits = [...selectedBenefits];
            newBenefits[strategyIndex] = '';
            setSelectedBenefits(newBenefits);
        }
        else {
            const newBenefits = [...selectedBenefits];
            newBenefits[strategyIndex] = details;
            setSelectedBenefits(newBenefits);
        }
    };

    const confirmSelectBenefits = () => {
        setBenefitListDialogOpen(false);
        if ((new Set(selectedBenefits)).size !== selectedBenefits.length) {
            // duplicate benefits present
            setConfirmDuplicatesDialogOpen(true);
        }
        else {
            setBenefits(selectedBenefits);
        }
    }

    const renderConfirmDuplicatesDialog = () => {

        const getDuplicatesText = () => {
            let duplicatesText;
            if (selectedBenefits[0] === selectedBenefits[1]) {
                if (selectedBenefits[1] === selectedBenefits[2]) {
                    duplicatesText = <span className={classes.duplicateTextBold}>Strategy 1, Strategy 2<span className={classes.duplicateText}> and </span>Strategy 3</span>
                }
                else {
                    duplicatesText = <span className={classes.duplicateTextBold}>Strategy 1 <span className={classes.duplicateText}> and </span>Strategy 2</span>
                }
            }
            else if (selectedBenefits[1] === selectedBenefits[2]) {
                duplicatesText = <span className={classes.duplicateTextBold}>Strategy 2<span className={classes.duplicateText}> and </span>Strategy 3</span>
            }
            else if (selectedBenefits[0] === selectedBenefits[2]) {
                duplicatesText = <span className={classes.duplicateTextBold}>Strategy 1<span className={classes.duplicateText}> and </span>Strategy 3</span>
            }
            // TODO: turn the above into a loop if we ever allow more than 3 strategies.
            return duplicatesText;
        }

        const handleBack = () => {
            setConfirmDuplicatesDialogOpen(false);
            setBenefitListDialogOpen(true);
        }

        const handleConfirm = () => {
            setBenefits(Array.from(new Set(selectedBenefits)));
            setConfirmDuplicatesDialogOpen(false);
        }

        return (
            <Dialog open={confirmDuplicatesDialogOpen} onClose={() => null} fullWidth maxWidth={'md'}>
                <div className={classes.dialogTitle} style={{ color: '#0046AD' }}>
                    Warning
                </div>
                <DialogContent style={{ height: '40vh', textAlign: 'center', padding: '5%' }}>
                    You have selected the same content of benefits for {getDuplicatesText()}. Please confirm.
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-evenly' }}>
                    <Button variant='outlined' className={classes.backButton} style={{ width: '160px' }} onClick={handleBack}>
                        Back
                    </Button>

                    <Button variant='contained' className={classes.saveButton} style={{ width: '160px' }} onClick={handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderBenefitListDialog = () => {
        return (
            <Dialog classes={dialogClasses} open={benefitListDialogOpen} onClose={() => setBenefitListDialogOpen(false)}>
                <div className={classes.dialogTitle}>
                    Benefit List
                    
                </div>
                <Typography
                variant="h4" className={classes.instructions1}>
                For each strategy, please pick one benefit.
            </Typography>
                <DialogActions style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '20px' }}>
                    <Button className={classes.saveButton} style={{ margin: 0, maxWidth: '100px' }} onClick={confirmSelectBenefits} disabled={selectedBenefits.length < 3}>
                        Select
                    </Button>
                </DialogActions>
                <DialogContent>
                    {
                        strategies.map((strategy, strategyIndex) => {
                            const indexText = <span style={{ fontWeight: 600 }}>Strategy {strategyIndex + 1}:</span>
                            return (
                                <div key={strategyIndex}>
                                    <div style={{ marginBottom: '4px' }}>
                                        {indexText} {strategy}
                                    </div>
                                    {
                                        allBenefits[strategyIndex].map((item: any, index: number) => {
                                            const isSelected = selectedBenefits[strategyIndex] === item.details;
                                            return (
                                                <Paper key={index} elevation={2} className={isSelected ? classes.selectedBenefitCard : classes.benefitCard} onClick={() => handleClickBenefit(strategyIndex, item.details)}>
                                                    <p>
                                                        {item.details}
                                                    </p>
                                                </Paper>
                                            )
                                        })}
                                </div>
                            )
                        })
                    }
                </DialogContent>
            </Dialog>

        )
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBenefitsText(event.target.value);
    }

    return (
        <>
            
                <Typography
                    variant="h4" className={classes.instructions}>
                    In this section, please pick ONE benefit that applies to each strategy.
                </Typography>
                
                <Button variant="outlined" className={classes.benefitListButton} onClick={() => setBenefitListDialogOpen(true)}>
                        Benefit List
                </Button>
                
                <div className={classes.benefitContainer}>
                    <TextField value={benefitsText} className={classes.input} required variant="outlined"
                        placeholder="Please select the benefits from the Benefit List. You can always edit the context if needed."
                        fullWidth
                        multiline
                        rows={5}
                        onChange={e => handleChange(e)} />
                   
                </div>
           

            {renderBenefitListDialog()}
            {renderConfirmDuplicatesDialog()}
        </>
    )
}


export default DSPCreationStep4;