/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
//import icons for plan 3
import NTS_Communication from '../../../img/NTS_Communication.png';
import NTS_SocialEmotional from '../../../img/NTS_SocialEmotional.png';
import NTS_GrossMotor from '../../../img/NTS_GrossMotor.png';
import NTS_FineMotor from '../../../img/NTS_FineMotor.png';
import NTS_ProblemSolving from '../../../img/NTS_ProblemSolving.png';
import { globalStyles } from '../../../GlobalStyles';
import axios from 'axios';
import Level1 from './Level1';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      paddingTop: '90px',
    },
    paddingTop: '64px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleText: {
    color: '#0046AD',
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  listContainer: {
    margin: '0 auto'
  },
  item_wrapper: {
    background: 'white',
    margin: '1.2rem 0',
    boxShadow: '0 3px 6px #00000014',
    borderRadius: '5px',
    border: '1px solid #70707047',
    '&.plan_menu': {
      margin: '0 0 10px',
      transition: '0.3s max-height',
      
    },
    '&.plan_menu.active': {
      visibility: 'visible',
      marginTop: '1.2rem',
      '& $item_title': {
        margin: 0,
        textAlign: 'unset',
        justifyContent: 'unset',
      },
      '&.Communication': {
        border: '3px solid #62A0C9'
      },
      '&.GrossMotor': {
        border: '3px solid #5A595C'
      },
      '&.FineMotor': {
        border: '3px solid #E37225'
      },
      '&.ProblemSolving': {
        border: '3px solid #156671'
      }
      , '&.SocialEmotional': {
        border: '3px solid #df4461'
      }
    },
  },
  item_number: {
    width: '60px',
    height: '60px',
    background: '#64A0C8',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    margin: '20px 0 20px 30px',
    borderRadius: '10px',

    '& img': {
      width: '100%',
      height: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px',
      height: '40px',
      fontSize: '1rem',
      borderRadius: '5px',
      margin: '.5rem',
    }
  },

  item_title: {
    fontSize: 'calc(14px + (30 - 14) * ((100vw - 300px) / (1600 - 300)))',
    margin: '15px 0 4px',
    fontWeight: 'bold',
    color: 'black',
    [theme.breakpoints.down('sm')]: {

    },
    [theme.breakpoints.down('xs')]: {

      margin: '0 0 0 5px'
    },
    [theme.breakpoints.up('xl')]: {

      margin: '0'

    }
  },
  domain_value: {
    fontSize: 'calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)))',
    color: 'black',
    margin: '0 0 20px 0',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  complete_text: {
    color: '#0046AD',
    fontSize: 'calc(14px + (22 - 14) * ((100vw - 300px) / (1600 - 300)))',
    fontFamily: "'Arial'",
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
     
    },
    [theme.breakpoints.up('md')]: {
      
    },
    [theme.breakpoints.up('xl')]: {
    }
  },
}))

interface DSPDomain {
  key: string;
  value: {
    prefix: string,
    imgUrl: string,
    title: string,
    desc: string,
    isInit: boolean,
  }
}

const ExploreStrategies = (props: any): JSX.Element => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const level2Item={children:[{id:''}]};
  const [selectmenu, setSelectMenu] = useState('exploreStrategies')

  const [toggled, setToggled] = useState(false);
  const [level2, setLevel2] = useState(level2Item);
  const [hasLevel2,setHasLevel2]=useState(false);
  

  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };
  const history = useHistory();

  const itemListDomains: DSPDomain[] = [
    {
      key: 'communication',
      value: {
        prefix: 'com_',
        desc: 'How I can understand you and talk to others',
        imgUrl: NTS_Communication,
        title: 'Communication',
        isInit: false
      }
    },
    {
      key: 'fineMotor',
      value: {
        prefix: 'fin_',
        desc: 'How I Use My Hands',
        imgUrl: NTS_FineMotor,
        title: 'Fine Motor',
        isInit: false
      }
    },
    {
      key: 'grossMotor',
      value: {
        prefix: 'gro_',
        desc: 'How I Move My Body',
        imgUrl: NTS_GrossMotor,
        title: 'Gross Motor',
        isInit: false
      }
    },
    {
      key: 'problemSolving',
      value: {
        prefix: 'pro_',
        desc: 'How I Explore and Figure Things Out',
        imgUrl: NTS_ProblemSolving,
        title: 'Problem Solving',
        isInit: false
      }
    },
    {
      key: 'socialEmotional',
      value: {
        prefix: 'soc_',
        desc: 'How I Show My Feelings and Act Around Others ',
        imgUrl: NTS_SocialEmotional,
        title: 'Social Emotional',
        isInit: false
      }
    },
  ]

  const [domainList, setDomainList] = useState<DSPDomain[]>(itemListDomains);
  const [constHeight, setConstHeight] = useState<number>(0);



  const viewHandle=(key:string)=>{
    axios.get(`${process.env.REACT_APP_API_URL}/catalog/strategies/communication/1`,{
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'api_key': process.env.REACT_APP_API_KEY
      }}).then((response:any) => {
        console.log(response.data)
        setHasLevel2(true);
        setLevel2(response.data)
    });
  }
  return (
    <>
    <Grid item xs={12}>
                <Typography variant="h4" className={globalClasses.titleText}>
                Explore Strategies
                </Typography>
            </Grid>
      <Grid container component="main" className={classes.root}>
        
        <Grid item md={9} className={classes.listContainer}>
          {domainList.map(domain => (
            <>
            <Level1 key={domain.key} domain={domain}></Level1>
             
            </>
          ))}
        </Grid>
      </Grid>

    </>
  )
}

export default ExploreStrategies