/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import RelationDialog from './RelationDialog';
import axios from 'axios';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import DSPBreadCrums from './DSPCreation/DSPBreadCrums';

const useStyles = makeStyles((theme) => ({
  DSPCard: {
    marginBottom: '48px',
  },

  DSPItem: {
    width: '100%',
  },

  DSPContent: {
    position: 'relative',
    padding: "0px",
  },

  DSPFooter: {
    backgroundColor: '#009B74',
    width: '100%',
  },

  footerText: {
    color: '#fff',
    verticalAlign: 'middle',
    padding: '10px 90px',
  },

  cardContent: {
  },



  textfield: {
    width: '100%',
    height: '55vh',
    fontFamily: 'Arial',
    lineHeight: '1.5em',
    fontSize: '16px',
    background: 'white',
    [theme.breakpoints.down('md')]: {
      height: '40vh',
    },
    '& textarea': {
      height: '90%',
      lineHeight: '1.5rem',
      paddingRight: '20px'
    },
    '& .MuiInputBase-root': {
      height: '95%',
      paddingTop: 0,
      paddingBottom: 0
    },
    '& fieldset': {
      borderColor: 'white'
    },
    // "& .Mui-focused fieldset": {
    //   borderColor: "#C52328",
    //   borderWidth: "2px"
    // },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: 'white'
      },
      "&.Mui-focused textarea:read-only fieldset": {
        borderColor: 'white'
      },
      "&.Mui-focused fieldset": {
        borderWidth: "3px",
        borderColor: '#64A0C8 !important',
      }
    }

  },

  titleBox: {
    marginTop: '5px',
  },

  templateButton: {
    color: '#E37222',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
  },

  backBtn: {
    color: '#0046AD',
    borderColor: '#0046AD',
    borderRadius: '10px'
  },
  selectBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#0046AD',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    }
  },
  breadcrumb: {
    //tablet
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0 1rem'
    },
  },
  age: {
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    margin: '14px 0 0 14px',

  },
  strategyListButton: {
    borderRadius: '10px',
    color: '#009B74',
    borderColor: '#009B74',
    textTransform: 'none',
    marginDown:'5px',
    fontSize: '1rem',
  },
  editBtn: {
    width: 'fit-content',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    paddingRight: '2rem',
    fontSize: '1.2rem',
    color: '#009B74',
    marginBottom: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginBottom: '0',
    },
    '& .MuiButton-label': {
      flexDirection: 'column-reverse'
    }
  },
  btns_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2em 0',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      margin: '3em auto',
      width: '50%',
      '& $backBtn': {
        marginTop: '2rem',
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      margin: '3em auto',
      width: '80%',
      '& $backBtn': {
        marginTop: '2rem',
      }
    },
  },
  content_grid: {
    [theme.breakpoints.down('md')]: {

      '& + $content_wrapper': {
        height: '50vh',

      }
    },
  },
  content_wrapper: {

  }
}));

function DSPRelationPage(props: any): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const history = useHistory();
  const classes = useStyles();
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(0);
  const [title, setTitle] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('In this section you will need to read through the paragraphs provided for each age and choose one that you think best describes the child and the family you are creating the plan for. Remember that once you choose the paragraph and add it to your plan you can and should edit the paragraph so it truly reflects the child and family you are working with.');
  const [oldTextAreaValue, setOldTextAreaValue] = useState('')
  const [relationList, setRelationList] = useState<any[]>([]);

  const handleClick = (index: number): void => {
    if (relationList[index].content) {
      const trimmedContent = relationList[index].content.replace(/\s+/g, ' ').trim()
      setTextAreaValue(trimmedContent)
      setOldTextAreaValue(trimmedContent)
      setTitle(relationList[index].title);
      setIsEditting(0);
    }
    setIsTemplateDialogOpen(0);
  }

  const handleChange = (e: any): void => {
    setTextAreaValue(e.target.value);
  }

  useEffect(()=>{
    if(sessionStorage.getItem('relationship') && sessionStorage.getItem('relationship') === 'completed'){
      setOldTextAreaValue(sessionStorage.getItem('relationship_matters') as string);
      setTextAreaValue(sessionStorage.getItem('relationship_matters') as string);
      setIsEditting(0);
      
    }
  },[]);


  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}catalog/relationship`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
    }).then(res => {
      setRelationList(res.data.data);
      // console.log(res.data.data);
    }).catch(error => {
      console.log('exception');
      console.log(error.response.data);
    });
  }, []);


  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };


  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isEditting, setIsEditting] = useState(-1);

  const editBtnHandler = () => {
    setIsReadOnly(false);
    setIsEditting(1)
    if (inputRef.current !== undefined)
      inputRef.current!.focus();
  }

  const defaultBtns = (): JSX.Element => {
    return (
      <Box className={classes.btns_wrapper}>
        <Button variant="outlined" className={classes.backBtn}
        onClick={()=>props.setSession('main')}
        >
          Back
        </Button>
        <Button variant="outlined"
          className={classes.strategyListButton}
          onClick={() => setIsTemplateDialogOpen(1)}>
          Social-Emotional Milestones List
        </Button>
      </Box>
    )
  }

  const submitHandler = () => {
    sessionStorage.setItem('relationship', 'completed');
    sessionStorage.setItem('relationship_matters', textAreaValue);
    props.setSession('main');

  }
  const selectSubmitBtns = (): JSX.Element => {
    return (
      <Box className={classes.btns_wrapper}>
        <Button
          variant="outlined"
          className={classes.backBtn}
          onClick={() => setIsTemplateDialogOpen(1)}
        >
          Reselect
        </Button>
        <Button
          variant="contained"
          className={classes.selectBtn}
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Box>
    )
  }

  const cancelHandler = () => {
    setTextAreaValue(oldTextAreaValue);
    setIsEditting(0)
  }
  const saveHandler = () => {
    setOldTextAreaValue(textAreaValue);
    setIsEditting(0)
  }
  const cancelSaveBtns = (): JSX.Element => {
    return (
      <Box className={classes.btns_wrapper}>
        <Button
          variant="outlined"
          className={classes.backBtn}
          onClick={cancelHandler}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.selectBtn}
          onClick={saveHandler}
        >
          Save
        </Button>
      </Box>
    )
  }

  const renderBtns = (): JSX.Element => {
    if (isEditting === -1) {
      return defaultBtns();
    }
    else if (isEditting === 1) {
      return cancelSaveBtns();
    }
    else {

      return selectSubmitBtns()
    }
  }

  const defaultShowing = (): JSX.Element => {
    return (
      <>
        <div className={classes.content_wrapper} style={{ background: 'white' }}>
          <Box className={classNames(classes.DSPItem, classes.DSPContent)}>
            {title !== '' ?
              <>
                <Typography
                  variant="h6"
                  className={classes.age}
                >
                  {/*Suggested Age: {title}*/}
                </Typography>
              </> : ''}
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={10}
              variant="outlined"
              className={classes.textfield}
              value={textAreaValue}
              onChange={handleChange}
              //autoFocus={false}
              InputProps={{
                readOnly: isReadOnly,
                // ref: inputRef
              }}
              inputRef={inputRef}
            />
            {/* <TextareaAutosize className={classes.textArea} value={textAreaValue} onChange={handleChange} /> */}
            {!isEditting ?
              <Button
                className={classes.editBtn}
                onClick={editBtnHandler}
              >
                <>
                  Edit
                </>
                <FontAwesomeIcon icon={faEdit} />
              </Button> : ''}
          </Box>
        </div>
        {renderBtns()}
      </>
    )
  }
  return (
    <>

      <Grid
        container
        item
        xs={11}
        style={{
          margin: '0 auto',
          display: 'block',
          height: 'unset',
        }}
      >

        <DSPBreadCrums
          setSession={props.setSession}
          session={props.session}
          setExpandMenu={props.setExpandMenu}
          items={[
            { name: 'Create New Plan', link: 'main' },
            { name: 'Why Our Relationship Matters', link: 'relationship' }
          ]}
        />
        <Box
          className={classNames(classes.DSPItem, classes.titleBox)}
          display={{ xs: 'none', md: 'block' }}
        >
          <Typography
            variant={'h4'}
            style={{ color: '#0046AD', fontWeight: 'bold' }}
          >
            Why Our Relationships Matters
          </Typography>
        </Box>
        <Grid container item md={12} style={{ display: 'flex', flexDirection: 'column' }} className={classes.content_grid}>
          {isTemplateDialogOpen ?
            <>
              <Box>
                <Typography paragraph style={{ color: 'black', fontWeight: 'bold', margin: '0' }}>In this section you will need to read through the paragraphs provided for each age and choose one that you think best describes the child and the family you are creating the plan for. Remember that once you choose the paragraph and add it to your plan you can and should edit the paragraph so it truly reflects the child and family you are working with.</Typography>
              </Box>
              <RelationDialog
                isOpen={isTemplateDialogOpen}
                onClose={() => setIsTemplateDialogOpen(0)} handleClick={handleClick}
                data={relationList}
              />
            </>
            :
            defaultShowing()}
        </Grid>

      </Grid>



    </>
  )
}

export default DSPRelationPage;