/* eslint-disable */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { Grid } from 'semantic-ui-react'
import Link from '@material-ui/core/Link';
import { AdminContext, useAdminContext } from './AdminProvider'

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    //tablet
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0 1rem'
    },
  },
}))

interface Props {
  items: { name: string, link: string }[]
}
const AdminBreadCrums = ({ items }: Props) => {
  const classes = useStyles();
  
  const {setSection} = useAdminContext();

  const handleClick = (item: { name: string, link: string }) => {
   (item.link === 'main')
      setSection('admin')
    
  }
  return (
    <Grid container item xs={10}
      style={{
        margin: '0 auto',
        display: 'block',
        height: 'unset', marginLeft:'10px'
      }}>
      <Breadcrumbs
        separator={<FontAwesomeIcon icon={faAngleRight as IconProp} />}
        aria-label="breadcrumb"
        className={classes.breadcrumb}
      >
        {items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              <Typography color="textPrimary">{item.name}</Typography>
            )
          } else {
            return (
              <Link style={{color:'blue',textDecorationLine:'underline'}} onClick={() => handleClick(item)}>
                {item.name}
              </Link>
            )
          }
        })}
       
      </Breadcrumbs>
    </Grid>
  )
}

export { AdminBreadCrums}

