/* eslint-disable no-unused-vars */
import {
  AppBar,
  Toolbar,
  makeStyles,
  Box,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import ntsLogo from "../../img/ntslogo.png";

const useStyles = makeStyles((theme) => ({
  header: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: "#FFFFFF",
      boxShadow: '0px 2px 5px -1px rgb(100 160 200 / 20%)',
    },
    backgroundColor: "#FAFAFA",
    color: '#0046AD',

  },

  logo: {
    float: 'left',
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& img':{
      height: '50px'
    }
  },


  navbar: {
    // display: "flex",
    // justifyContent: "space-between",
    // margin: '0 auto',
    // width: '80%',
    // "@media (max-width: 1300px)": {
    //   width: '100%',
    // }

  },
  btnToggle: {
    cursor: 'pointer',
    color: '#0046AD',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      position: 'absolute',
      left: '40px',
    },
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      fontWeight: '600',
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontWeight: '600',
      fontSize: '1rem',
    },
  },
  search_text: {
    color: '#222',
    position: 'relative',
    zIndex: 5,
    transition: 'z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s',
    height: '45px',
    width: 0,
    margin: 0,
    padding: '5px 0 5px 40px',
    fontSize: '1rem',
    cursor: 'pointer',
    borderRadius: '30px',
    border: '1px solid transparent',
    background: 'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MDMuODY2LDQ3Ny45NzRMMzYwLjk1OCwzMzUuMDUyYzI4LjcyNS0zNC41NDQsNDYuMDE3LTc4LjkxMiw0Ni4wMTctMTI3LjMzNiAgYzAtMTEwLjA4NC04OS4yMjctMTk5LjMxMi0xOTkuMzEyLTE5OS4zMTJDOTcuNTk5LDguNDAzLDguMzUxLDk3LjYzMSw4LjM1MSwyMDcuNzE1YzAsMTEwLjA2NCw4OS4yNDgsMTk5LjMxMiwxOTkuMzEyLDE5OS4zMTIgIGM0OC40MzUsMCw5Mi43OTItMTcuMjkyLDEyNy4zMzYtNDYuMDE3bDE0Mi45MDgsMTQyLjkyMkw1MDMuODY2LDQ3Ny45NzR6IE0yOS4zMzEsMjA3LjcxNWMwLTk4LjMzNCw3OS45ODctMTc4LjMzMiwxNzguMzMyLTE3OC4zMzIgIGM5OC4zMjUsMCwxNzguMzMyLDc5Ljk5OCwxNzguMzMyLDE3OC4zMzJzLTgwLjAwNywxNzguMzMyLTE3OC4zMzIsMTc4LjMzMkMxMDkuMzE4LDM4Ni4wNDcsMjkuMzMxLDMwNi4wNSwyOS4zMzEsMjA3LjcxNXoiIGZpbGw9IiMzNzQwNEQiLz48L3N2Zz4=) no-repeat left 9px center transparent',
    backgroundSize: '24px',

    '&:focus': {
      zIndex: 3,

      [theme.breakpoints.down('sm')]: {
        width: '100px',
      },
      [theme.breakpoints.only('sm')]: {
        width: '270px',
      },
      border: '1px solid #666',
      backgroundColor: 'white',
      outline: 'none',
      cursor: 'auto',
      paddingRight: '10px',
    }
  },

  search_submit: {
    position: 'relative',
    zIndex: 4,
    margin: 0,
    padding: 0,
    border: 0,
    outline: 0,
    borderRadius: '30px',
    cursor: 'pointer',
    background: 'none',
  },

  showMenu_wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      margin: 0,
      marginLeft: '24px',
      fontSize: '20px',
      color: '#0046AD',
      fontWeight: 500,
    }
  },
  menu_icon: {
    fontSize: '48px'
  },
  appBar_item: {
    display: 'flex',
    alignItems: 'center'
  },
  searchBtn: {
    color: '#156570',
    border: '1px solid #156570',
    marginRight: '24px',
    height: '40px',
  },
  searchTextField: {
    height: '40px',
    background: 'white',
    marginRight: '8px',
    '&.MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0
    },
    '& input': {
      boxSizing: 'unset',
      height: '40px',
      padding: '0 0 0 14px'
    }
  }

  // input.search-text::-webkit-search-cancel-button {
  //   cursor:pointer;
  // }
}));

export default function DSPHeader(props: any) {
  const { header, logo, navbar } = useStyles();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  let pageName: string;
  if (props.pageName === 'dsp')
    pageName = 'Create DSP';
  else if (props.pageName == 'contactUs')
    pageName = 'Contact Us';
  else if (props.pageName == 'profile')
    pageName = 'profile'
  else
    pageName = '';

  // useEffect(() => {
  //   const setResponsiveness = () => {
  //     return window.innerWidth < 1024
  //       ? setState((prevState) => ({ ...prevState, mobileView: true }))
  //       : setState((prevState) => ({ ...prevState, mobileView: true }));
  //   };

  //   setResponsiveness();

  //   window.addEventListener("resize", () => setResponsiveness());
  // }, []);

  const headerLogo = (
    <a href="/" className={logo}>
      <img src={ntsLogo} />
    </a>
  );

  const handleSearch = (e:any) => {
    props.handleSearch(e.target.value)
    setSearchTerm(e.target.value)
  }
  return (
    <AppBar className={header} elevation={0}>
      <Toolbar
        style={{
          height: '80px',
          background: '#64a0c814'
        }}>
        <Grid container>
          <Grid item xs={2} sm={2} className={classes.appBar_item}>
            <div className={classes.showMenu_wrapper} onClick={() => props.handleToggleSidebar(!props.toggled)}>
              <span className={`material-symbols-rounded ${classes.menu_icon}`}>
                menu
              </span>
              <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>{props.toggled?<p>Show Menu</p>:<p>Hide Menu</p>}</Box>

              <Box sx={{ display: { xs:'none', sm: 'block', lg: 'none' } }}><p>Menu</p></Box>
            </div>
          </Grid>


          <Grid item xs={10} sm={6} className={classes.appBar_item} style={{ justifyContent: 'center' }}>
            <Box className={navbar}>
              {headerLogo}
            </Box>
          </Grid>
{/* search bar
          <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'contents' } }}>
            <Grid item xs={false} sm={4} className={classes.appBar_item}
              style={{ justifyContent: 'end' }}>
              <TextField
                type="search"
                variant="outlined"
                margin="normal"
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <SearchIcon />
                //     </InputAdornment>
                //   )
                // }}
                placeholder="Search"
                className={classes.searchTextField}
                value={searchTerm}
                onChange={(e) => handleSearch(e)}
              />
              <Button variant="outlined" className={classes.searchBtn}>Search</Button>
            </Grid>
          </Box>
      */}
        </Grid>


      </Toolbar>
    </AppBar >
  );



}
