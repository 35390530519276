//preview
/* eslint-disable */
import {
  makeStyles,
  Grid,
  Box,
  IconButton,
  DialogContent,
  Dialog,
  Toolbar,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import ntsLogo from "../../../../img/ntslogo.png";
import { Close } from '@material-ui/icons';

//import icons for plan 3
import NTS_Communication from '../../../../img/NTS_Communication.png';
import NTS_SocialEmotional from '../../../../img/NTS_SocialEmotional.png';
import NTS_GrossMotor from '../../../../img/NTS_GrossMotor.png';
import NTS_FineMotor from '../../../../img/NTS_FineMotor.png';
import NTS_ProblemSolving from '../../../../img/NTS_ProblemSolving.png';

// color leaves
import SmallBlueLeaf from '../../../../img/small_blue_leaf.png';
import SmallGrayLeaf from '../../../../img/small_gray_leaf.png';
import SmallGreenLeaf from '../../../../img/small_green_leaf.png';
import SmallOrangeLeaf from '../../../../img/small_orange_leaf.png';
import SmallRedLeaf from '../../../../img/small_red_leaf.png';
import BigGreenLeaf from '../../../../img/big_green_leaf.png';
import SickKidsLogo from "../../../../img/Outlook-Infant Men.png";
import React from 'react';

const useStyles = makeStyles((theme) => ({

  dialogTitle: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& svg': {
      fill: '#0046AD'
    }
  },
  dialogTitleText: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '32px',
    color: '#0046AD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  saveButton: {
    borderRadius: '8px',
    width: '160px',
    backgroundColor: '#0046AD',
    marginBottom: '10px',
    color: "#FFFFFF",
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textTransform: 'none',
    border: '1px solid gray',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      width: '70%',
    },
    '&:disabled': {
      background: 'gray',
      color: '#FFFFFF',
      border: '1px solid gray',

    },
    '&:hover': {
      color: '#0046AD',
      border: '1px solid #0046AD',
      backgroundColor: 'white',
    }
  },
  strategyCard: {
    borderRadius: '10px',
    marginTop: '2%',
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  selectedStrategyCard: {
    borderRadius: '10px',
    marginBottom: '2%',
    marginTop: '2%',
    padding: '0 8px',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderColor: '#0046AD',
    borderWidth: '2px',
    borderStyle: 'solid',
    color: '#0046AD',
  },
  dialog: {
    //'& .MuiDialog-scrollPaper': {
    //justifyContent: ''
    //}
    '& .MuiDialog-scrollPaper': {
      justifyContent: 'end',
      backdropFilter: 'blur(6px)',
      [theme.breakpoints.down('sm')]: {
        marginTop: '70px'
      }

    }
  },
  dialogContent: {
    padding: '0 5%',
    // height: '85%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    // justifyContent: 'space-between',
  },
  content_grid: {
    [theme.breakpoints.down('md')]: {

      '& + $content_wrapper': {
        height: '50vh',

      }
    },
  },
  content_wrapper: {
    // background: 'white',
    // height: '100%',
    // '& .MuiToolbar-root':{
    //   display: 'flex',
    //   justifyContent: 'space-between',
    //   alignItems: 'end'
    // },
    // '& .MuiGrid-container':{
    //   height: '90%',
    //   '& .MuiGrid-item':{
    //     height: '100%'
    //   }
    // }
  },
  navbar: {
    width: '50%'
  },
  logo: {
    float: 'left',
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    marginLeft: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profile_preview_title: {
    color: '#0641a8',
    fontSize: '22px',
    margin: 0,
    textAlign: 'center'
  },
  profile_preview_content: {
    fontSize: '12px',
    color: '#707070'
  },
  communication_preview_title: {
    color: 'black',
    fontSize: '18px',
    margin: '10px',
    textAlign: 'center',

  },
  profile_preview_subtitle: {
    color: '#0641a8',
    fontSize: '12px',
    margin: '8px auto 1rem',
    textAlign: 'center',
    fontWeight: 500,
  },
  PFP: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderWidth: '5px',
    zIndex: 2,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  PFPBox: {
    position: 'relative',
    borderRadius: '100%',
    borderWidth: '0px',
    height: '100px',
    width: '100px',
    margin: "16px auto",
  },
  profile_preview_table: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    },
    '& .MuiTableCell-alignLeft': {
      paddingBottom: 0,
      borderBottom: '1px solid',

    },
    '& th, td': {
      fontSize: '18px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    '& th': {
      width: '50%',
      paddingBottom: 0,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0
      }
    },
    '& .addionalNoteTitle': {
      fontSize: '18px'
    },
    '& td': {
      paddingTop: '20px'
    }
  },
  item_number: {
    width: '60px',
    height: '60px',
    background: '#64A0C8',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '2rem',
    // margin: '1.5rem',
    borderRadius: '10px',

    '& img': {
      width: '100%',
      height: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px',
      height: '40px',
      fontSize: '1rem',
      borderRadius: '5px',
      margin: '.5rem',
    }
  },
  item_wrapper: {
    background: 'white',
    margin: '1.2rem 0',
    boxShadow: '0 0 5px #0000002e',
    borderRadius: '10px',
    padding: '1% 5%',
    [theme.breakpoints.down('xs')]: {
      margin: '0.5rem 0',
      '& ol': {
        paddingLeft: '10px'
      }
    },
    '&.plan_menu': {
      margin: 0,
      transition: '0.3s max-height',
      visibility: 'hidden'
    },
    '& h4': {
      fontSize: '14px'
    },
    '& p': {
      fontSize: '12px'
    },
    '& li': {
      fontSize: '12px'
    },
    '&.Communication': {
      border: '3px solid #62A0C9'
    },
    '&.GrossMotor': {
      border: '3px solid #5A595C'
    },
    '&.FineMotor': {
      border: '3px solid #E37225'
    },
    '&.ProblemSolving': {
      border: '3px solid #156671'
    }
    , '&.SocialEmotional': {
      border: '3px solid #df4461'
    },
  },

  domain_preview_title: {
    margin: 0,
    '&.Communication': {
      color: '#0046AD'
    }
  },
  dialogWrapper: {
    // margin: '30px 100px',

    border: '1px solid #707070',
    boxShadow: '0px 3px 6px #888888',
    [theme.breakpoints.down('sm')]: {
      margin: '30px 50px',
    },
    '&.Communication': {
      background: '#F9FBFD'
    },
    '&.FineMotor': {
      background: '#FEF9F6'
    },
    '&.GrossMotor': {
      background: '#F4F4F4'
    },
    '&.ProblemSolving': {
      background: '#F5F9F9'
    },
    '&.SocialEmotional': {
      background: '#FEF7F9'
    },
    [theme.breakpoints.down('xs')]: {
      margin: '30px 10px',
    }
  },


  textfield: {
    height: '60vh',
    fontFamily: 'Arial',
    lineHeight: '1.5em',
    fontSize: '16px',
    background: 'white',
    border: '3px solid #009B74',
    padding: '20px',
    borderRadius: '10px',
    position: 'relative',
    margin: '2rem 0 3rem',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    }
  },
  relation_textfield: {
    height: '65%',
    overflow: 'auto',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      height: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '60%',
    }
  },
  leaf_wrapper: {
    position: 'absolute',
    bottom: '-10px',
    right: 0,
    width: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  footer_wrapper: {
    // width: '100%',
    color: 'black',
    display: 'flex',
    '&.Communication': {
      background: '#0046AD',
      border: '1px solid blue',
    },
    '&.GrossMotor': {
      background: '#5A595C',
      border: '1px solid #707070',
    },
    '&.FineMotor': {
      background: '#E37222',
      border: '1px solid #E37222',
    },
    '&.ProblemSolving': {
      background: '#156670',
      border: '1px solid #156670',
    },
    '&.SocialEmotional': {
      background: '#DF4461',
      border: '1px solid #DF4461',
    },
    '&.RelationshipMatter': {
      background: '#009B74',
      border: '1px solid #009B74',
    }
  },
  footer_content_wrapper: {
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    padding: '1rem',
    width: '85%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
      width: '95%',
    },
    '& p': {
      margin: '0px',
      color: 'black',
      fontSize: '0.8rem',
      fontWeight: 500,
      paddingBottom: '1px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '8px',

      },

    },
    '&.Communication': {
      border: '1px solid blue',
      borderTop: '1px solid blue',
      borderBottom: '1px solid blue',
      background: '#EEF4F9',
    },
    '&.GrossMotor': {
      border: '1px solid #707070',
      borderTop: '1px solid #707070',
      borderBottom: '1px solid #707070',
      background: '#EDEDED',
    },
    '&.FineMotor': {
      border: '1px solid #E37222',
      borderTop: '1px solid #E37222',
      borderBottom: '1px solid #E37222',
      background: '#FDF9F5',
    },
    '&.ProblemSolving': {
      border: '1px solid #156670',
      borderTop: '1px solid #156670',
      borderBottom: '1px solid #156670',
      background: '#E3EEEF',
    },
    '&.SocialEmotional': {
      border: '1px solid #DF4461',
      borderTop: '1px solid #DF4461',
      borderBottom: '1px solid #DF4461',
      background: '#FDEFF3',
    },
    '&.RelationshipMatter': {
      border: '1px solid #009B74',
      borderTop: '1px solid #009B74',
      borderBottom: '1px solid #009B74',
      background: '#EBF7F3',
    },
  },
  fpReview_inputField: {
    fontSize: '14px'
  },
  page_number: {
    color: 'white',
    width: '10%',
    fontSize: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontStyle: 'italic'
  },
  // fpReview_header: {
  //   width: '100%',
  //   textAlign: 'left',
  //   position: 'relative',

  //   '&:before': {
  //     content: "''",
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: '50%',
  //     height: '3px',
  //     background: '#62A0C9',
  //     zIndex: 0,
  //   }
  // },
  // fpReview_header_content: {
  //   display: 'flex',
  //   background: 'white',
  //   zIndex: 1,
  //   justifyContent: 'start',
  //   alignItems: 'center',
  //   '& h1': {
  //     position: 'relative',
  //     padding: '10px',
  //     color: '#62A0C9',
  //     background: 'white',
  //     display: 'inline-block',
  //     zIndex: 1,
  //     fontSize: '1.25rem',

  //   },
  // },
  // fpReview_header_img_wrapper: {
  //   borderRadius: '50%',
  //   background: 'white',
  //   zIndex: 1
  // },
  fpReview_container: {
    margin: '2rem',
    padding: '0',
    // height: '100%'
  },
  fpReview_logo: {
    position: 'absolute',
    top: '-18px',
    left: '-15px',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    zIndex: 1,
    paddingRight: '10px',
    '& h3': {
      fontSize: '14px',
      fontWeight: 500,
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      }
    },
    '&.Communication h3': {
      color: '#0046AD',
    },
    '&.FineMotor h3': {
      color: '#E37222'
    },
    '&.GrossMotor h3': {
      color: '#5A595C'
    },
    '&.ProblemSolving h3': {
      color: '#156670'
    },
    '&.SocialEmotional h3': {
      color: '#DF4461'
    },
    '& img': {
      width: '100%',
      height: 'auto',
      // marginRight: '3%'
    }
  },
  fpReview_logo_wrapper: {
    width: '35px',
    height: '35px',
    marginRight: '5px'
  },

  fpReview_wrapper: {
    textAlign: 'center',
    marginTop: '2em',
    width: '80%',
    padding: '1px 2em',
    margin: 'auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '1px 10px',
      width: '90%',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '3px',
      height: 'calc(100% - 20px)',
    },
    '&:after': {
      position: 'absolute',
      height: '3px',
      width: 'calc(100% - 100px)',
      right: 0,
      top: 0,
      content: '""',
      zIndex: 0
    },
    '&.Communication': {
      borderRight: '3px solid #62a0c970',
      borderBottom: '3px solid #62a0c970',
      '&:before': {
        background: 'linear-gradient(#62A0C9, white)'
      },
      '&:after': {
        backgroundColor: '#62a0c970',
      },
    },
    '&.FineMotor': {
      borderRight: '3px solid #e3722247',
      borderBottom: '3px solid #e3722247',
      '&:before': {
        background: 'linear-gradient(#E37222, white)'
      },
      '&:after': {
        backgroundColor: '#e3722247',
      },
    },
    '&.GrossMotor': {
      borderRight: '3px solid #5a595c47',
      borderBottom: '3px solid #5a595c47',
      '&:before': {
        background: 'linear-gradient(#5A595C, white)'
      },
      '&:after': {
        backgroundColor: '#5a595c47',
      },
    },
    '&.ProblemSolving': {
      borderRight: '3px solid #15667040',
      borderBottom: '3px solid #15667040',
      '&:before': {
        background: 'linear-gradient(#156670, white)'
      },
      '&:after': {
        backgroundColor: '#15667040',
      },
    },
    '&.SocialEmotional': {
      borderRight: '3px solid #df446142',
      borderBottom: '3px solid #df446142',
      '&:before': {
        background: 'linear-gradient(#DF4461, white)'
      },
      '&:after': {
        backgroundColor: '#df446142',
      },
    },
  },
  fpReview_content: {
    textAlign: 'left',
    fontSize: '12px'
  },
  fpReview_info_container: {
    '& input': {
      width: '100%',
      border: 0,
      outline: 0,
      background: 'transparent',
      borderBottom: '1px solid black',
      fontSize: '14px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      }
    }
  },
  fpReview_footer: {
    '& $footer_wrapper': {
      background: '#0046AD',
      border: 'none',
      color: 'white',
      padding: '8px 34px',
      justifyContent: 'center',
      '& p': {
        fontSize: '12px'
      }
    }
  },
  fpReview_inputField_label: {
    margin: 0,
    fontSize: '14px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    }
  },
  ntsLogo: {
    [theme.breakpoints.down('md')]: {
      width: '50%'
    }
  },
  SickKidsLogo: {
    width: '80%',
    height: 'auto',
    marginLeft: 'auto',
    float: 'right',
    [theme.breakpoints.down('md')]: {

    }
  },
  additionalNoteInfo: {
    height: '55px',
    overflow: 'auto'
  }
}));

const dialogStyles = makeStyles((theme) => ({
  paper: {
    height: "100vh",
    marginTop: 0,
    marginBottom: 0,
    maxHeight: 'unset',
    maxWidth: '60vw',
    minHeight: '100%',
    width: '60vw',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw',
      width: '90vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      maxWidth: '100vw',
      margin: 0,
      bottom: 0,
      position: 'absolute',
    },
  },

}));

const MainModal = (props: any): JSX.Element => {
  const classes = useStyles();
  let domainPageNumber:number=2;

  const displayDesktop = () => {
    return (
      <Toolbar
      >
        <Box className={classes.navbar}>
          <img className={classes.ntsLogo} src={ntsLogo} />
        </Box>
        <Box className={classes.navbar}>
          <img className={classes.SickKidsLogo} src={SickKidsLogo} />

        </Box>


      </Toolbar>
    );
  };

  const dialogClasses = dialogStyles();

  const dialogFooter = (domainName: string, imgSrc?: string, pageNum?: string) => {
    return (
      <>
        <div style={{ margin: domainName === 'RelationshipMatter' ? '2rem 0 0' : '0' }}>
          {imgSrc !== '' ?
            <div style={{ textAlign: 'right' }}>
              <img src={imgSrc} />
            </div> : ''}


          <div className={`${classes.footer_wrapper} ${domainName}`}>
            <div className={`${classes.footer_content_wrapper} ${domainName}`}>
              <p style={{ marginTop: 0 }}>Note: this Developmental Support Plan is not intended to replace any necessary intensive services. <br />
                Infant and Early Mental Health Promotion (IEMHP), the Hospital for Sick Children, Toronto, 2021 </p>
            </div>
            <div className={classes.page_number}>{pageNum}</div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Dialog
        classes={dialogClasses}
        open={props.isPreviewModalOpen}
        onClose={() => props.setIsPreviewModalOpen(false)}
        className={classes.dialog}
      >
        <div className={classes.dialogTitle}>
          <h1 className={classes.dialogTitleText}>Preview</h1>
          <Box position="absolute" top={10} right={15}>
            <IconButton onClick={() => props.setIsPreviewModalOpen(false)}>
              <Close />
            </IconButton>
          </Box>
        </div>
        <div className={classes.content_wrapper}>
          {sessionStorage.getItem('coverPage') === 'completed' && <CoverPagePreview />}

          {sessionStorage.getItem('relationship') === 'completed' && <RelationshipMattersPreview />}

          {sessionStorage.getItem('communication') === 'completed' &&
            <DomainPreview domainName='Communication' domainPrefix='com' imgSrc={NTS_Communication} footerImg={SmallBlueLeaf} pageNum={domainPageNumber++} />}

          {sessionStorage.getItem('grossMotor') === 'completed' &&
            <DomainPreview domainName='GrossMotor' domainPrefix='gro' imgSrc={NTS_GrossMotor} footerImg={SmallGrayLeaf} pageNum={domainPageNumber++} />}


          {sessionStorage.getItem('fineMotor') === 'completed' &&
            <DomainPreview domainName='FineMotor' domainPrefix='fin' imgSrc={NTS_FineMotor} footerImg={SmallOrangeLeaf} pageNum={domainPageNumber++} />}

          {sessionStorage.getItem('problemSolving') === 'completed' &&
            <DomainPreview domainName='ProblemSolving' domainPrefix='pro' imgSrc={NTS_ProblemSolving} footerImg={SmallGreenLeaf} pageNum={domainPageNumber++} />}


          {sessionStorage.getItem('socialEmotional') === 'completed' &&
            <DomainPreview domainName='SocialEmotional' domainPrefix='soc' imgSrc={NTS_SocialEmotional} footerImg={SmallRedLeaf} pageNum={domainPageNumber++} />}

          {sessionStorage.getItem('fridge') === 'completed' && <FridgePagePreview />}

        </div>
      </Dialog>
    </>
  )
}
const formatDate = () => {
  let cDate=sessionStorage.getItem('creationDate')!.substring(4).split(" ");
  switch(cDate[0].toLowerCase()){
    case 'jan':cDate[0]="January";break;
    case 'feb':cDate[0]="February";break;
    case 'mar':cDate[0]="March";break;
    case 'apr':cDate[0]="April";break;
    case 'may':cDate[0]="May";break;
    case 'jun':cDate[0]="June";break;
    case 'jul':cDate[0]="July";break;
    case 'aug':cDate[0]="August";break;
    case 'sep':cDate[0]="September";break;
    case 'oct':cDate[0]="October";break;
    case 'nov':cDate[0]="November";break;
    case 'dec':cDate[0]="December";break;
  }
  return `${cDate[0]} ${cDate[1]}, ${cDate[2]}`;
}

export const CoverPagePreview = React.forwardRef((props, ref): JSX.Element => {
  const classes = useStyles();
  // const {overrideClasses} = props;

  const displayDesktop = () => {
    return (
      <Toolbar
      >
        <Box className={classes.navbar}>
          <img className={classes.ntsLogo} src={ntsLogo} />
        </Box>
        <Box className={classes.navbar}>
          <img className={classes.SickKidsLogo} src={SickKidsLogo} />

        </Box>


      </Toolbar>
    );
  };

  const calcMonthYear = () => {
    return sessionStorage.getItem('age');
  }


  return (
    <Box className={`${classes.dialogWrapper} info`} >
      {displayDesktop()}
      <Grid container>
        <Grid item xs={12}>
          <DialogContent className={classes.dialogContent}>
            <div>
              <h5 className={classes.profile_preview_title}>Developmental Support Plan</h5>
              <h6 className={classes.profile_preview_subtitle}>Infants and young children require nourishment from their families, communities, and culture</h6>
              <p className={classes.profile_preview_content}>This Developmental Support Plan provides some everyday strategies to help your little one grow to be happy and healthy in Heart-Social Emotional, Mind-Problem Sovling, Body-Fine Motor, Body-Gross Motor and Spirit- Communication</p>
            </div>
            <div>
              <div className={classes.PFPBox}>
                <img
                  width="100%"
                  className={classes.PFP}
                  src={sessionStorage.getItem('childProfilePicture')!}
                />
              </div>
              <TableContainer>
                <Table className={`info_table ${classes.profile_preview_table}`}>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Child's Name:
                      </TableCell>
                      <TableCell align="left">
                        {sessionStorage.getItem('childName')!}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Age:
                      </TableCell>
                      <TableCell align="left">
                        {calcMonthYear()}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Plan Creator:
                      </TableCell>
                      <TableCell align="left">
                        {sessionStorage.getItem('creatorList')!}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row">
                        Creation Date:
                      </TableCell>
                      <TableCell align="left">
                        {formatDate()}

                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" className="addionalNoteTitle">
                        Child's Additional Information:
                      </TableCell>
                      <TableCell align="left">
                        <div className={classes.additionalNoteInfo}>
                          {sessionStorage.getItem('childAdditionalInformation')!}
                        </div>

                      </TableCell>
                    </TableRow>



                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
          <div>
            <div style={{ textAlign: 'right' }}>
              <img src={SmallBlueLeaf} />
            </div>
            <div
              className={`${classes.footer_wrapper}`}
              style={{
                background: '#EEF4F9',
                border: 'none',
                paddingLeft: '20px'
              }}
            >
              <p style={{ marginTop: 0, fontSize: '12px' }}>Note: this Developmental Support Plan is not intended to replace any necessary intensive services. <br />
                Infant and Early Mental Health Promotion (IEMHP), the Hospital for Sick Children, Toronto, 2021 </p>

            </div>
          </div>
        </Grid>
      </Grid>
    </Box>

  )
})

export const DomainPreview = (props: any): JSX.Element => {
  const { domainName, domainPrefix, imgSrc, footerImg, pageNum } = props;
  const classes = useStyles();

  const displayDesktop = () => {
    return (
      <Toolbar
      >
        <Box className={classes.navbar}>
          <img className={classes.ntsLogo} src={ntsLogo} />
        </Box>
        <Box className={classes.navbar}>
          <img className={classes.SickKidsLogo} src={SickKidsLogo} />

        </Box>


      </Toolbar>
    );
  };

  const dialogFooter = (domainName: string, imgSrc?: string, pageNum?: string) => {
    return (
      <>
        <div style={{ margin: domainName === 'RelationshipMatter' ? '2rem 0 0' : '0' }}>
          {imgSrc !== '' ?
            <div style={{ textAlign: 'right' }}>
              <img src={imgSrc} />
            </div> : ''}


          <div className={`${classes.footer_wrapper} ${domainName}`}>
            <div className={`${classes.footer_content_wrapper} ${domainName}`}>
              <p style={{ marginTop: 0 }}>Note: this Developmental Support Plan is not intended to replace any necessary intensive services. <br />
                Infant and Early Mental Health Promotion (IEMHP), the Hospital for Sick Children, Toronto, 2021 </p>
            </div>
            <div className={classes.page_number}>{pageNum}</div>
          </div>
        </div>
      </>
    )
  }

  //console.log({ domainPrefix });
  let step3_storedObj: any = sessionStorage.getItem(`${domainPrefix}_step3_value`);
  // console.log({step3_storedObj});
  let step3_list: string[] = JSON.parse(step3_storedObj).level5 as string[]

  let step2_storedObj = sessionStorage.getItem(`${domainPrefix}_step2_value`)!;
  let step2_value: string = JSON.parse(step2_storedObj).level4 as string

  let step4_storedObj = sessionStorage.getItem(`${domainPrefix}_step4_value`)!;
  let step4_value: string = JSON.parse(step4_storedObj).level6 as string
  return (
    <>
      <div
        className={`${classes.dialogWrapper} ${domainName} info`}
      >
        {displayDesktop()}
        <Grid container>
          <Grid item xs={12}>
            <DialogContent className={classes.dialogContent}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <div
                  className={classes.item_number}
                  style={{ borderRadius: '50%' }}
                >
                  <img src={imgSrc} />
                </div>
                {domainName==='Communication'&&<h5 className={classes.communication_preview_title}>How I Can Understand You and Talk to Others</h5>}
                {domainName==='FineMotor'&&<h5 className={classes.communication_preview_title}>How I Use My hands</h5>}
                {domainName==='GrossMotor'&&<h5 className={classes.communication_preview_title}>How I Move My Body</h5>}
                {domainName==='ProblemSolving'&&<h5 className={classes.communication_preview_title}>How I Explore and Figure Things Out</h5>}
                {domainName==='SocialEmotional'&&<h5 className={classes.communication_preview_title}>How I Show My Feelings and Act Around Others</h5>}
                
                 </div>

              <Grid
                item
                md={12}
                xs={12}
                className={`${classes.item_wrapper} ${domainName}`}
              >
                <h4 className={`${classes.domain_preview_title} ${domainName}`} style={{ margin: 0 }}>
                  What I can already do
                </h4>

                <p style={{ margin: '5px 0' }}>{sessionStorage.getItem(`${domainPrefix}_step1_value`)}</p>
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                className={`${classes.item_wrapper} ${domainName}`}
              >
                <h4 className={`${classes.domain_preview_title} ${domainName}`}
                >
                  My next goal
                </h4>

                <p style={{ margin: '5px 0' }}
                >
                  {step2_value}
                </p>
              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                className={`${classes.item_wrapper} ${domainName}`}
              >
                <h4 className={`${classes.domain_preview_title} ${domainName}`}
                >
                  How you can help me reach my goal
                </h4>

                <ol>
                  {step3_list?.map(item => (
                    <li>{item}</li>
                  ))}
                </ol>

              </Grid>

              <Grid
                item
                md={12}
                xs={12}
                className={`${classes.item_wrapper} ${domainName}`}
              >
                <h4 className={`${classes.domain_preview_title} ${domainName}`} style={{ margin: 0 }}>
                  Why this is important to me
                </h4>

                <p style={{ margin: '5px 0' }}>
                  {step4_value}
                </p>
              </Grid>
            </DialogContent>
            {dialogFooter(domainName, footerImg, pageNum)}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export const RelationshipMattersPreview = (props: any): JSX.Element => {
  const classes = useStyles();

  const displayDesktop = () => {
    return (
      <Toolbar
      >
        <Box className={classes.navbar}>
          <img className={classes.ntsLogo} src={ntsLogo} />
        </Box>
        <Box className={classes.navbar}>
          <img className={classes.SickKidsLogo} src={SickKidsLogo} />

        </Box>


      </Toolbar>
    );
  };

  const dialogClasses = dialogStyles();

  const dialogFooter = (domainName: string, imgSrc?: string, pageNum?: string) => {
    return (
      <>
        <div style={{ margin: domainName === 'RelationshipMatter' ? '2rem 0 0' : '0' }}>
          {imgSrc !== '' ?
            <div style={{ textAlign: 'right' }}>
              <img src={imgSrc} />
            </div> : ''}


          <div className={`${classes.footer_wrapper} ${domainName}`}>
            <div className={`${classes.footer_content_wrapper} ${domainName}`}>
              <p style={{ marginTop: 0 }}>Note: this Developmental Support Plan is not intended to replace any necessary intensive services. <br />
                Infant and Early Mental Health Promotion (IEMHP), the Hospital for Sick Children, Toronto, 2021 </p>
            </div>
            <div className={classes.page_number}>{pageNum}</div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={`${classes.dialogWrapper} info`}
        style={{ background: '#f5fbf9' }}>
        {displayDesktop()}
        <Grid container>
          <Grid item xs={12}>
            <DialogContent className={classes.dialogContent}>
              <h5 className={classes.communication_preview_title}>Why Our Relationship Matters</h5>
              <div
                id="outlined-multiline-static"
                className={classes.textfield}
              >
                <div className={classes.relation_textfield}>
                  {sessionStorage.getItem('relationship_matters')}
                </div>
                <div className={`${classes.leaf_wrapper} big_green_leaf`}>
                  <img src={BigGreenLeaf} />
                </div>
              </div>
              {/* {dialogFooter()} */}
            </DialogContent>
            {dialogFooter("RelationshipMatter", '', '1')}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export const FridgePagePreview = (props: any): JSX.Element => {
  const classes = useStyles();

  const displayDesktop = () => {
    return (
      <Toolbar
      >
        <Box className={`${classes.navbar}`}>
          <img className={classes.ntsLogo} src={ntsLogo} />
        </Box>
        <Box className={classes.navbar}>
          <img className={classes.SickKidsLogo} src={SickKidsLogo} />

        </Box>


      </Toolbar>
    );
  };

  let fridgeValue = JSON.parse(sessionStorage.getItem(`fridge_value`)!)

  let comGoal = sessionStorage.getItem(`com_step2_value`) ? JSON.parse(sessionStorage.getItem(`com_step2_value`)!).level4 : ''
  let comStrategy = fridgeValue.communication

  let groGoal = sessionStorage.getItem(`gro_step2_value`) ? JSON.parse(sessionStorage.getItem(`gro_step2_value`)!).level4 : ''
  let groStrategy = fridgeValue.grossMotor

  let finGoal = sessionStorage.getItem(`fin_step2_value`) ? JSON.parse(sessionStorage.getItem(`fin_step2_value`)!).level4 : ''
  let finStrategy = fridgeValue.fineMotor

  let proGoal = sessionStorage.getItem(`pro_step2_value`) ? JSON.parse(sessionStorage.getItem(`pro_step2_value`)!).level4 : ''
  let proStrategy = fridgeValue.problemSolving

  let socGoal = sessionStorage.getItem(`soc_step2_value`) ? JSON.parse(sessionStorage.getItem(`soc_step2_value`)!).level4 : ''
  let socStrategy = fridgeValue.socialEmotional

  return (
    <>
      <div className={`${classes.dialogWrapper} info`}>
        {displayDesktop()}
        <div style={{ width: '80%', margin: '1rem auto' }}>
          <Grid container className={classes.fpReview_info_container}>
            <Grid
              item
              md={6}
              xs={12}
              style={{ display: 'flex' }}
            >
              <Grid
                item
                xs={5}
                style={{ display: 'flex' }}
              >
                <h3 className={classes.fpReview_inputField_label}>Child's Name:</h3>
              </Grid>
              <Grid
                item
                xs={5}
                style={{ display: 'flex' }}
              >
                <input
                  className={classes.fpReview_inputField}
                  value={sessionStorage.getItem('childName')!}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.fpReview_info_container}
          >
            <Grid
              item
              md={6}
              xs={12}
              style={{ display: 'flex' }}
            >
              <Grid
                item
                xs={5}
                style={{ display: 'flex' }}
              >
                <h3 className={classes.fpReview_inputField_label}>Plan creator:</h3>
              </Grid>

              <Grid
                item
                xs={5}
                style={{ display: 'flex' }}
              >
                <input className={classes.fpReview_inputField} value={sessionStorage.getItem('creatorList')!} readOnly />
              </Grid>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              style={{ display: 'flex' }}
            >
              <Grid
                item
                xs={5}
                style={{ display: 'flex' }}
              >
                <h3 className={classes.fpReview_inputField_label}>Creation Date:</h3>

              </Grid>
              <Grid
                item
                xs={5}
                style={{ display: 'flex' }}
              >
                <input className={classes.fpReview_inputField} value={formatDate()} readOnly />

              </Grid>

            </Grid>

          </Grid>
        </div>
        <div className={classes.fpReview_container}>
          {comGoal !== '' &&
            <div className={`${classes.fpReview_wrapper} Communication`}>
              <div className={`${classes.fpReview_logo} Communication`}>
                <div className={`${classes.fpReview_logo_wrapper}`}>
                  <img src={NTS_Communication} />
                </div>

                <h3>How I can understand you and talk to others</h3>
              </div>

              <p className={classes.fpReview_content}>
                <b>Goal</b>: {comGoal}
                <br />
                <b>Strategy</b>: {comStrategy}
              </p>
            </div>}

          {finGoal !== '' &&
            <div className={`${classes.fpReview_wrapper} FineMotor`}>
              <div className={`${classes.fpReview_logo} FineMotor`}>
                <div className={`${classes.fpReview_logo_wrapper}`}>
                  <img src={NTS_FineMotor} />
                </div>
                <h3>How I move my body</h3>
              </div>

              <p className={classes.fpReview_content}>
                <b>Goal</b>: {finGoal}
                <br />
                <b>Strategy</b>: {finStrategy}
              </p>
            </div>}

          {groGoal !== '' &&
            <div className={`${classes.fpReview_wrapper} GrossMotor`}>
              <div className={`${classes.fpReview_logo} GrossMotor`}>
                <div className={`${classes.fpReview_logo_wrapper}`}>
                  <img src={NTS_GrossMotor} />

                </div>
                <h3>How I use my hand</h3>
              </div>

              <p className={classes.fpReview_content}>
                <b>Goal</b>: {groGoal}
                <br />
                <b>Strategy</b>: {groStrategy}
              </p>
            </div>}

          {proGoal !== '' &&
            <div className={`${classes.fpReview_wrapper} ProblemSolving`}>
              <div className={`${classes.fpReview_logo} ProblemSolving`}>
                <div className={`${classes.fpReview_logo_wrapper}`}>
                  <img src={NTS_ProblemSolving} />

                </div>

                <h3>How I explore and figure things out</h3>
              </div>

              <p className={classes.fpReview_content}>
                <b>Goal</b>: {proGoal}
                <br />
                <b>Strategy</b>: {proStrategy}
              </p>
            </div>}

          {socGoal !== '' &&
            <div className={`${classes.fpReview_wrapper} SocialEmotional`}>
              <div className={`${classes.fpReview_logo} SocialEmotional`}>
                <div className={`${classes.fpReview_logo_wrapper}`}>
                  <img src={NTS_SocialEmotional} />
                </div>

                <h3>How I show my feelings and act around others</h3>
              </div>

              <p className={classes.fpReview_content}>
                <b>Goal</b>: {socGoal}
                <br />
                <b>Strategy</b>: {socStrategy}
              </p>
            </div>}

        </div>
        <div className={classes.fpReview_footer}>
          <div style={{ textAlign: 'right' }}>
            <img src={SmallBlueLeaf} />
          </div>
          <div className={`${classes.footer_wrapper}`}>
            <p style={{ margin: 0 }}>Note: this Developmental Support Plan is not intended to replace any necessary intensive services. <br />
              Infant and Early Mental Health Promotion (IEMHP), the Hospital for Sick Children, Toronto, 2021 </p>

          </div>
        </div>
      </div>
    </>
  )
}

export default MainModal
