/* eslint-disable */
import React, {useState} from 'react'
import { makeStyles,
    Grid,
    Box,
} from '@material-ui/core';
import axios from 'axios';
import IMPKLogo from '../img/IEMHP_SK.png';
import NANLogo from '../img/NANLogo.png';
import KBHNetwork from '../img/KBHNetwork.png';
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    footer: {
        width:'100%',
        borderTop : '1px solid #0046AD',
        margin: '0 auto'
    },

    footerContent: {
        width: '100%',
        margin: '0 auto',
    },

    link: {
        color: '#0046AD',
        display: 'block',
        textDecoration: 'none',
    },

    text: {
        color: '#0046AD',
        fontSize: '14px',
        lineHeight: '24px',
    },

    img: {
        width: '100%',
        '@media (max-width: 1280px)': {
            display: 'flex',
            justifyContent: 'center',
        }
    },

}));

export default function Footer():JSX.Element {
    const classes = useStyles();
    const [email, setEmail] = useState('');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const onSubscribeEmail = () => {
        if(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}user/optin`,          
                headers:{'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
                'api_key':process.env.REACT_APP_API_KEY,
                crossdomain:true
                },
                data: {
                email: email,
                }, 
                
            }).then(res=>{
                alert('Thank you for subscribing to our newsletter!');
            }).catch(error=>{
                alert('Something went wrong, please try again later.');
                console.log(error.response.data);
            });
        } else {
            alert('Please enter a valid email address.');
        }
    }
   

    return (
         
        <Grid container spacing={4} xs={12}  justifyContent="center" alignItems="center" direction="column">
            <div><img className={classes.img} style={{padding: '35px 0px'}} src={IMPKLogo} alt="IMPK Logo"/></div>           
            <div><img className={classes.img} style={{padding: '20px 0px'}} src={KBHNetwork} alt="KBHNetwork Logo"/></div>
            <div><img className={classes.img} style={{padding: '20px 0px'}} src={NANLogo} alt="NAN Logo"/></div>
            
            <Grid item  xs={12} className={classes.text}>
            <Box style={{padding: '15px 0px',}}>
                <Link to="" className={classes.link}>
                    About
                </Link>
                <Link to="/contact" className={classes.link}>
                    Contact
                </Link>
                <Link to="TermsAndCondition" target="_blank" className={classes.link}>
                    Terms & Conditions
                </Link>
            </Box>
            </Grid>

            {/*<Grid item lg={3} sm={4} xs={12}>
                <p className={classes.text}>
                    Subscribe to IEMHP newsletter
                </p>
                <TextField
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{alignItems:'right'}}>
                              
                                <Button size="large" style={{backgroundColor:'#0046AD',color:'white'}} onClick={onSubscribeEmail}>
  OK
</Button>
                            </InputAdornment>                                    
                        )
                    }}
                ></TextField>
                </Grid>*/}

            <Grid item xs={12}>
                <p className={classes.text} style={{marginLeft: '10px'}}>
                    Infant and Early Mental Health Promotion <br />
                    The Hospital for Sick Children <br />
                    555 University Ave, Toronto, ON M5G 1X8 <br />
                    iemhp.mail@sickkids.ca
                </p>
            </Grid>
            <Grid item xs={12}>
                <p className={classes.text} style={{marginLeft: '10px'}}>
                Copyright&copy; 2024 Infant and Early Mental Health Promotion
                </p>
            </Grid>
        </Grid>
        
    );
}