/* eslint-disable no-unused-vars */
import { saveAs } from 'file-saver';
import * as Crypto  from 'crypto-js';

const SaveFile=():any=>{
    //console.log(localStorage.getItem('email'))
    //var ciphertext = Crypto.AES.encrypt(GenerateJsonFile(), sessionStorage.getItem('email') as string).toString();
    alert("Success\n\nYou have saved your plan to your local computer successfully!"
    +"There are three ways to locate the file you have just saved:\n\n"
    +"1.You will locate the plan in the download folder.\n"
    +"2. You can search for the file using ‘dsp_child’s first name’\n"
    +"3. You can search by file type using ‘.iemhp’");

    const ciphertext = Crypto.AES.encrypt(GenerateJsonFile(),sessionStorage.getItem('email')as string).toString();
    const blob = new Blob([ciphertext], {type: "text/plain;charset=utf-8"});
    saveAs(blob, `dsp_${sessionStorage.getItem('childName')}_${new Date().getTime()}.iemhp`);
}

const OpenFile=(ciphertext:string):any=>{
    try{
        const bytes = Crypto.AES.decrypt(ciphertext, sessionStorage.getItem('email') as string);
        const decryptedData = bytes.toString(Crypto.enc.Utf8);
        return decryptedData;
    }
    catch(ex){
        return null;
    }
}

const GenerateJsonFile=():string=>{
    const test=new DSP(sessionStorage.getItem('childName') as string,sessionStorage.getItem('childProfilePicture') as string,sessionStorage.getItem('childDOB') as string,sessionStorage.getItem('childAdditionalInformation') as string,sessionStorage.getItem('creationDate') as string,sessionStorage.getItem('creatorList') as string
                ,sessionStorage.getItem('relationship_matters') as string,sessionStorage.getItem(`com_step1_value`) as string,sessionStorage.getItem(`fin_step1_value`) as string,sessionStorage.getItem(`gro_step1_value`) as string,sessionStorage.getItem(`pro_step1_value`) as string,sessionStorage.getItem(`soc_step1_value`) as string
                ,sessionStorage.getItem(`com_step2_value`) as string,sessionStorage.getItem(`fin_step2_value`) as string,sessionStorage.getItem(`gro_step2_value`) as string,sessionStorage.getItem(`pro_step2_value`) as string,sessionStorage.getItem(`soc_step2_value`) as string
                ,sessionStorage.getItem(`com_step3_value`) as string,sessionStorage.getItem(`fin_step3_value`) as string,sessionStorage.getItem(`gro_step3_value`) as string,sessionStorage.getItem(`pro_step3_value`) as string,sessionStorage.getItem(`soc_step3_value`) as string
                ,sessionStorage.getItem(`com_step4_value`) as string,sessionStorage.getItem(`fin_step4_value`) as string,sessionStorage.getItem(`gro_step4_value`) as string,sessionStorage.getItem(`pro_step4_value`) as string,sessionStorage.getItem(`soc_step4_value`) as string
                ,sessionStorage.getItem(`fridge_value`) as string,sessionStorage.getItem(`coverPage`) as string,sessionStorage.getItem(`relationship`) as string,sessionStorage.getItem(`communication`) as string,sessionStorage.getItem(`fineMotor`) as string
                ,sessionStorage.getItem(`grossMotor`) as string,sessionStorage.getItem(`problemSolving`) as string,sessionStorage.getItem(`socialEmotional`) as string,sessionStorage.getItem(`fridge`) as string);
    return JSON.stringify(test);
}

class DSP {
    //cover page
    public childName:string;
    public childProfilePicture:string;    
    public childDOB:string;
    public childAdditionalInformation:string;
    public creationDate:string;
    public creatorList:string;
    public coverPage:string;
    //releationship matter
    public relationship_matters:string;
    public relationship:string;
    //strategy step1
    public com_step1_value:string;
    public fin_step1_value:string;
    public gro_step1_value:string;
    public pro_step1_value:string;
    public soc_step1_value:string;

    //strategy step2
    public com_step2_value:string;
    public fin_step2_value:string;
    public gro_step2_value:string;
    public pro_step2_value:string;
    public soc_step2_value:string;
    
    //strategy step3
    public com_step3_value:string;
    public fin_step3_value:string;
    public gro_step3_value:string;
    public pro_step3_value:string;
    public soc_step3_value:string;
   
    //strategy step4
    public com_step4_value:string;
    public fin_step4_value:string;
    public gro_step4_value:string;
    public pro_step4_value:string;
    public soc_step4_value:string;
    
    public communication:string;
    public fineMotor:string;
    public grossMotor:string;
    public socialEmotional:string;
    public problemSolving:string;
  
    //fridge
    public fridge_value:string;
    public fridge:string;
  


    constructor(childName:string,childProfilePicture:string,childDOB:string,childAdditionalInformation:string,creationDate:string,creatorList:string
        ,relationship_matters:string,com_step1_value:string,fin_step1_value:string,gro_step1_value:string,pro_step1_value:string,soc_step1_value:string
        ,com_step2_value:string,fin_step2_value:string,gro_step2_value:string,pro_step2_value:string,soc_step2_value:string
        ,com_step3_value:string,fin_step3_value:string,gro_step3_value:string,pro_step3_value:string,soc_step3_value:string
        ,com_step4_value:string,fin_step4_value:string,gro_step4_value:string,pro_step4_value:string,soc_step4_value:string
        ,fridge_value:string,coverPage:string,relationship:string,communication:string,fineMotor:string,grossMotor:string,problemSolving:string,socialEmotional:string,fridge:string){
        this.childName=childName??''; 
        this.childProfilePicture=childProfilePicture??'';
        this.childDOB=childDOB??'';
        this.childAdditionalInformation=childAdditionalInformation??'';
        this.creationDate=creationDate??'';
        this.creatorList=creatorList??'';
        this.relationship_matters=relationship_matters??'';
        this.com_step1_value=com_step1_value??'';
        this.fin_step1_value=fin_step1_value??'';
        this.gro_step1_value=gro_step1_value??'';
        this.pro_step1_value=pro_step1_value??'';
        this.soc_step1_value=soc_step1_value??'';
        this.com_step2_value=com_step2_value??'';
        this.fin_step2_value=fin_step2_value??'';
        this.gro_step2_value=gro_step2_value??'';
        this.pro_step2_value=pro_step2_value??'';
        this.soc_step2_value=soc_step2_value??'';
        this.com_step3_value=com_step3_value??'';
        this.fin_step3_value=fin_step3_value??'';
        this.gro_step3_value=gro_step3_value??'';
        this.pro_step3_value=pro_step3_value??'';
        this.soc_step3_value=soc_step3_value??'';
        this.com_step4_value=com_step4_value??'';
        this.fin_step4_value=fin_step4_value??'';
        this.gro_step4_value=gro_step4_value??'';
        this.pro_step4_value=pro_step4_value??'';
        this.soc_step4_value=soc_step4_value??'';
        this.fridge_value=fridge_value??'';
        this.coverPage=coverPage??'';
        this.relationship=relationship??'';
        this.communication=communication??'';
        this.fineMotor=fineMotor??'';
        this.grossMotor=grossMotor??'';
        this.problemSolving=problemSolving??'';
        this.socialEmotional=socialEmotional??'';
        this.fridge=fridge??'';
    }
}

export  {SaveFile,OpenFile}