/* eslint-disable */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Grid } from 'semantic-ui-react'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    //tablet
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer'
    },
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0 1rem'
    },
  },
}))

interface Props {

  setHelp:(item:string)=>void;
  items: { name: string, link: string }[]
}
const HelpCenterBreadCrums = ({ setHelp, items }: Props) => {
  const classes = useStyles();

  const handleClick = (item: { name: string, link: string }) => {
    console.log(item.link)
    if (item.link === 'main')
      setHelp("help")
    
  }
  return (
    <Grid container item xs={10}
      style={{
        margin: '0 auto',
        display: 'block',
        height: 'unset', marginLeft:'10px'
      }}>
      <Breadcrumbs
        separator={<FontAwesomeIcon icon={faAngleRight as IconProp} />}
        aria-label="breadcrumb"
        className={classes.breadcrumb}
      >
        {items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              <Typography color="textPrimary">{item.name}</Typography>
            )
          } else {
            return (
              <Link style={{color:'blue',textDecorationLine:'underline'}} onClick={() => handleClick(item)}>
                {item.name}
              </Link>
            )
          }
        })}
        {/* <p
                    key="1"
                    color="inherit"
                    onClick={() => setSession('main')}
                >
                    Create New Plan
                </p>,
                <p
                    key="2"
                    color="inherit"
                >
                    {session === 'coverPage' && 'Cover Page'}
                    {session === 'relationship' && 'Relationship Matters'}
                </p>,
                <p
                    key="3"
                    color="inherit"
                >
                    {session === 'coverPage' && 'Cover Page'}
                    {session === 'relationship' && ''}
                    {session === 'relationship' && 'Relationship Matters'}
                </p>, */}
      </Breadcrumbs>
    </Grid>
  )
}

export default HelpCenterBreadCrums