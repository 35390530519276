/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import mockup from '../img/mockup.png';
import signin768 from "../img/signin768.png";
import signin992 from "../img/signin992.png";
import signin1200 from "../img/signin1200.png";
import { Box, Container, Typography, Grid, Link, Button, TextField } from '@material-ui/core';
import Footer from './Footer';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Redirect, useHistory, useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${signin768})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${signin992})`,
    },

    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${signin1200})`,
    },
  },
  
  signinTitle: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
    margin: '20px auto',
    fontWeight: 600,
    color: '#0046AD'
  },

  loginBox: {
    [theme.breakpoints.between('md', 'md')]: {
      width: '550px',
      padding: '50px 100px'
    },

    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: 'auto'
    },
    padding: '35px 50px',
    textAlign: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, .7)',
    height: 'auto',
    width: '460px',
    justifyContent: 'center'
  },

  inner: {
    padding: '7% 0',
  },

  inputField: {
    margin: '10px auto',
    backgroundColor: 'white',
    borderRadius: '8px',
    'input': {
      borderRadius: '8px',
      border: 'none',
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      boxShadow: '0 3px 3px #e5e5e5',
      borderColor: 'rgb(255 255 255)'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046AD'
    }
  },

  submitBtn: {
    margin: theme.spacing(1, 0, 2),
    background: "#D71F85",
    color: 'white',
    fontSize: '20px',
    borderRadius: '10px',
    '&.MuiButton-root:hover': {
      backgroundColor: '#D71F85'
    }
  },

  dividerContainer: {
    display: "flex",
    alignItems: "center"
  },

  dividerBorder: {
    borderBottom: "2px solid #727679",
    width: "100%"
  },

  dividerContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: "#727679"
  },

  mockup: {
    [theme.breakpoints.between('md', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      order: '2',
    },
  },

  mockupInner: {
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      marginTop: '30px',
    },
  },

  outerLogin: {
    justifyContent: 'flex-end',
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      order: '3',
    },
  },

  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },

  forgetPW: {
    color: '#707070',
    textDecoration: 'underline',
    float: 'right',
    marginTop: '18px',
    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
      float: 'none'
    },
  },

  rememberMe: {
    float: 'left',
    marginTop: '10px',
  },
  asterisk_input: {
    position: 'relative',
    fontSize: '1.5em'

  },
}));


export default function ResetPassword(props: any): JSX.Element {
  const location = useLocation();

  // console.log("ResetPassword props: ", location.state[0].token)
  const classes = useStyles();
  // password valid: true or false
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  //confirm password
  const [isCPValid, setIsCPValid] = useState(true);
  // password value
  const [pwValue, setPwValue] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [isEnableButton, setIsEnableButton] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(location.state===undefined)
      history.push("/");

}, []);

  const onPasswordChangeHandler = (e: any) => {
    const regEx: RegExp = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}/;
    setPwValue(e.target.value);
    if(e.target.value !== '' && e.target.value !== null && regEx.test(e.target.value)){
      setIsPasswordValid(true);
     
    }
    else{
      setIsCPValid(false)
      setIsPasswordValid(false);
    }
   


  };


  const onConPasswordChangeHandler = (e: any) => {

    
    if(e.target.value !== undefined && e.target.value === pwValue){
      setIsCPValid(true);
     
      setIsEnableButton(true&&isPasswordValid);
    }
    else{
      setIsCPValid(false)
      setIsEnableButton(false);
    }
   


  };
  const onResetPasswordHandler = (e: any) => {
    e.preventDefault();

    if (isPasswordValid) {
      axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}user/forgotPassword`,       
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        },
        data: {
          newPassword: pwValue,
          forgotPasswordCode: location.state[0].token
        },
      }).then(res => {
        console.log('res: ', res);
        setIsLinkSent(true);
        // getACode(res.data.invitationCode);
        // setIsAlertOpen(false);

      }).catch(error => {
        console.log('exception: ', error);
        //console.log(error.response.data);
        // setAlertMsg(error.response.data.error);
        // setIsAlertOpen(true);
        // getACode("");
      });
    }
  }
  const onPasswordToggle = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <>

      <Header />
      <Container  maxWidth={false}  disableGutters={true} className={classes.root}>
        <Box className={classes.inner}>
          <Grid container justifyContent="center" alignItems="center" className={classes.gridContainer}>
            <Grid item md={6} sm={12} xs={12} className={classes.outerLogin}>
              <Box
                className={classes.loginBox}
                style={{
                  display: isLinkSent ? 'block' : 'none'
                }}>
                <Typography
                  variant="h4"
                  className={classes.signinTitle}>
                  Password Changed
                </Typography>
                <p style={{ textAlign: 'left' }}>
                  Success! You've created a new password for your account.
                </p>
                <Button
                  fullWidth
                  className={classes.submitBtn}
                  href="/login"
                >              
                  Sign In
                </Button>
              </Box>
              <Box
                className={classes.loginBox}
                style={{
                  display: isLinkSent ? 'none' : 'block'
                }}>
                <Typography variant="h4" className={classes.signinTitle}>Reset Your Password</Typography>
                <form noValidate onSubmit={onResetPasswordHandler}>
                  <TextField
                    variant="outlined"
                    error={!isPasswordValid}
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    
                    type={passwordShown ? "text" : "password"}
                    className={classes.inputField}
                    onChange={onPasswordChangeHandler}
                    helperText={!isPasswordValid ? "* Your password must contain at least 1 upper case,1  numeric, 1 special character and be a minimum of 8 characters" : ""}
                    InputProps={{
                      endAdornment: (
                        <>
                          <FontAwesomeIcon icon={passwordShown ? faEye as IconProp : faEyeSlash as IconProp} onClick={onPasswordToggle} style={{ cursor: 'pointer' }} />
                          <span className={classes.asterisk_input} style={{ marginLeft: '8px' }}> * </span>
                        </>
                      )
                    }}
                  />
                  <TextField
                    error={!isCPValid}
                    variant="outlined"
                    required
                    fullWidth
                    name="conPassword"
                    label="Confirm Password"
                    id="conPassword"
                    type={passwordShown ? "text" : "password"}
                    onChange={onConPasswordChangeHandler}
                    helperText={!isCPValid ? 'Password does not match.' : ''}
                    className={classes.inputField}
                    InputProps={{
                      endAdornment: (
                        <>
                          <FontAwesomeIcon icon={passwordShown ? faEye as IconProp : faEyeSlash as IconProp} onClick={onPasswordToggle} style={{ cursor: 'pointer' }} />
                          <span className={classes.asterisk_input} style={{ marginLeft: '8px' }}> * </span>
                        </>
                      )
                    }}
                    InputLabelProps={{ required: false }}

                  />

                  <Button
                    fullWidth
                    disabled={!isEnableButton}
                    
                    className={classes.submitBtn}
                    type="submit"
                    style={{background:!isEnableButton?"grey": "#D71F85",color:'white'}}
                          
                  >
                    Reset Password
                  </Button>
                </form>

              </Box>
            </Grid>
            <Grid item md={6} xs={12} className={classes.mockup}>
              <img src={mockup} className={classes.mockupInner} alt="mockup" />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
}




