/* eslint-disable */
import React, { useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import axios from 'axios';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ContactBackground from '../img/contact_background-1200.png';
import ContactBackgroundTablet from '../img/contact_background-992.png';
import ContactBackgroundMobile from '../img/contact_background-768.png';
import locationIcon from '../img/location_icon.svg';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundImage: 'url(' + ContactBackground + ')',
        backgroundPosition: '0% 35%',
        "@media (max-width: 992px)": {
            backgroundImage: 'url(' + ContactBackgroundTablet + ')',
            backgroundPosition: '0% 15%',
        },
        "@media (max-width: 768px)": {
            backgroundImage: 'url(' + ContactBackgroundMobile + ')',
            backgroundPosition: 'center',
        },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vw',
        paddingTop: '15vh',
        '& .MuiPopover-paper': {
            marginLeft: '1%',
        }
    },
    formContainer: {
        width: '50vw',
        marginLeft: '15%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left', 
        "@media (max-width: 1279px)": {
            alignItems: 'center',
            marginLeft: 0,
            width: '100vw',
            textAlign: 'center'
        },
    },
    titleText: {
        color: '#0046AD',
        fontSize: '3rem',
        fontWeight: 'bold',
        fontFamily: 'Arial',
    },
    subtitleText: {
        color: '#0046AD',
        fontSize: '1.5rem',
        fontFamily: 'Arial',
        marginBottom: '20px',
        "@media (max-width: 1279px)": {
            fontSize: '1.2rem',
            textAlign: 'center'
        },
    },
    textField: {
        width: '65%',
        display: 'block',
        marginBottom: '20px',
        borderRadius: '5px',
        "&  .MuiInputBase-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: '5px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    subjectForm: {
        width: '65%',
        height: '60px',
        marginBottom: '20px',
        borderRadius: '5px',
        '& .MuiPaper-root': {
            marginLeft: '1%',
        }
    },
    subjectField: {
        marginBottom: '20px',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        textTransform: 'none',
        color: '#727679',
        fontFamily: 'Arial',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        fontSize: '1rem',
        justifyContent: 'space-between',
        '& .MuiSelect-root': {
            display: 'flex',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
    errorSubjectField: {
        width: '65%',
        height: '60px',
        marginBottom: '0px',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        textTransform: 'none',
        color: '#F44336',
        borderColor: '#F44336',
        fontFamily: 'Arial',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        fontSize: '1rem',
        justifyContent: 'space-between',
    },
    sendButton: {
        borderRadius: '25px',
        width: '160px',
        background: 'linear-gradient(0deg, #002357, #0044A9)',
        marginTop: '10px',
        marginBottom: '5%',
        color: "#FFFFFF",
        fontSize: '20px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    resultMessage: {
        color: '#0046AD',
        fontSize: '1rem',
        fontFamily: 'Arial',
        "@media (max-width: 1279px)": {
            fontSize: '0.8rem',
            textAlign: 'center'
        },
    },
    addressCard: {
        width: '50%',
        marginLeft: '15%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        paddingBottom: '20px',
        borderRadius: '10px',
        marginTop: '200px',
        marginBottom: '100px',
    },
    addressIcon: {
        height: '80%'
    },
    addressTextContainer: {
        color: '#0046AD',
        fontSize: '1rem',
        fontFamily: 'Helvetica',
        marginLeft: '40px',
    },
    addressText: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    subjectSelector: {
        [theme.breakpoints.down('md')]: {
            width: '63%',
        },
        padding: '1%',
        borderRadius: '10px',
    },
    subjectRow: {
        width: '100%',
        color: '#727679',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    }
}));

export default function ContactPage(): JSX.Element {
    const styles = useStyles();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [subjectErrorMessage, setSubjectErrorMessage] = useState('');
    const [messageErrorMessage, setMessageErrorMessage] = useState('');
    const [resultMessage, setResultMessage] = useState('');

    const subjects = [
        "General inquiries",
        "Technical issues",
        "Learn more about NTS training",
        "Look for project collaboration",
        "Suggestion/Feedback for the app",
        "Other"
    ]

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameErrorMessage('');
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailErrorMessage('');
    }

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
        setMessageErrorMessage('');
    }

    const handleSend = () => {
        name === '' ? setNameErrorMessage('Please enter a name.') : setNameErrorMessage('');
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ? setEmailErrorMessage('Please enter a valid email.') : setEmailErrorMessage('');
        subject === '' ? setSubjectErrorMessage('Please select a subject.') : setSubjectErrorMessage('');
        message === '' ? setMessageErrorMessage('Please enter a message.') : setMessageErrorMessage('');

        let shouldSend = true;
        if (name === '' || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) || subject === 'Subject *' || message === '') {
            shouldSend = false;
        }

        if (!shouldSend) {
            return;
        }

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/sendEmail`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
                'api_key': process.env.REACT_APP_API_KEY,
                crossdomain: true
              },
            data: {
                senderName: name,
                senderEmail: email,
                subject: subject,
                msgBody: message,
            },

        }).then(res => {
            console.log(res);
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            setResultMessage('Your message has been sent.');
        }).catch(error => {
            setResultMessage('There was an error sending your message. Please try again later.');
        });
    }

    const renderSubjectSelect = () => {
        const handleChange = (event: any) => {
            setSubject(event.target.value);
            setSubjectErrorMessage('');
        }
        
        return (
            <FormControl variant="outlined" className={styles.subjectForm} error={subjectErrorMessage != ""}
                style={subjectErrorMessage != "" ? { marginBottom: 0 } : {}}
            >
                <InputLabel>Subject *</InputLabel>
                <Select
                    value={subject}
                    onChange={handleChange}
                    label="Subject *"
                    IconComponent={isMobile ? KeyboardArrowDownIcon : ChevronRightIcon}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: isMobile ? "bottom" : "top",
                            horizontal: isMobile ? "left" : "right",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        style: {
                            marginLeft: isMobile ? '0' : '8px',
                            marginTop: isMobile ? '4px' : '0',
                        },
                        className: styles.subjectSelector
                    }}
                    className={styles.subjectField}
                    style={subjectErrorMessage != "" ? {marginBottom: 0} : {}}
                >
                    {subjects.map((subjectText) => {
                        return (
                            <MenuItem key={subjectText} value={subjectText} className={styles.subjectRow} divider>{subjectText}</MenuItem>
                        )
                    }
                    )}
                </Select>
            </FormControl>
        )
    }

    const renderContent = () => {

        return (
            <div className={styles.root} >
                <div className={styles.formContainer}>
                    <Typography className={styles.titleText} component="h1" variant="h4">
                        Contact Us
                    </Typography>

                    <Typography className={styles.subtitleText} component="h1" variant="h6">
                        Got any questions? We'd like to help!
                    </Typography>

                    <TextField className={styles.textField} label="Name" required fullWidth variant="outlined"
                        onChange={handleNameChange}
                        error={nameErrorMessage != ''}
                        helperText={nameErrorMessage}
                        value={name}
                    />
                    <TextField className={styles.textField} label="Email Address" required fullWidth variant="outlined"
                        onChange={handleEmailChange}
                        error={emailErrorMessage != ''}
                        helperText={emailErrorMessage}
                        value={email}
                    />

                    {renderSubjectSelect()}
                    {subjectErrorMessage != '' && <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"
                        style={{ 'marginBottom': '20px', 'width': '65%', 'paddingLeft': '25px' }}>{subjectErrorMessage}</p>}

                    <TextField className={styles.textField}
                        label={"Message"}
                        required multiline rows={5} fullWidth variant="outlined"
                        onChange={handleMessageChange}
                        error={messageErrorMessage != ''}
                        value={message}
                        helperText={messageErrorMessage}
                    />

                    <Button variant="contained" className={styles.sendButton} onClick={handleSend}>
                        Send
                    </Button>

                    <Typography className={styles.resultMessage} component="h1" variant="h6">
                        {resultMessage}
                    </Typography>

                </div>

                <Hidden mdDown>
                    <Paper className={styles.addressCard} elevation={3}>
                        <img src={locationIcon} className={styles.addressIcon} />
                        <div className={styles.addressTextContainer}>
                            <p className={styles.addressText}>
                                Infant and Early Mental Health Promotion
                            </p>
                            <p className={styles.addressText}>
                                The Hospital for Sick Children
                            </p>
                            <p className={styles.addressText}>
                                555 University Ave, Toronto, ON M5G 1X8
                            </p>
                        </div>
                    </Paper>
                </Hidden>
                
            </div>
        )
    };

    return (
        <>
            <Header />
            {renderContent()}
            <Footer />
        </>
    )
}