import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';

import React from 'react'

const useStyles = makeStyles((theme) => ({
    stepContent_number_filled: {
        background: '#009B74',
        borderRadius: '50%',
        height: '30px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'white',
        fontWeight: 700,
        fontSize: '1.125rem',
        alignItems: 'center',
        marginBottom: '9px'
    },
    stepContent_text: {
        margin: 0,
        fontSize: '1.3rem',
        color: '#009B74',
        marginLeft: '12px',
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem'
        }
    },
    stepContent_number_unfilled: {
        background: 'white',
        border: '1px solid #009B74',
        borderRadius: '50%',
        height: '30px',
        width: '30px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#009B74',
        fontWeight: 700,
        fontSize: '1.125rem',
        alignItems: 'center',
    },
    steper_wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1.5px solid #009B74',
        marginBottom: '2rem',

    }
}));

interface StepValue {
    stepNum: string;
    status: string;
    name: string;
}

const DSPCreationSteper = (props: { steps: StepValue[] }): JSX.Element => {
    const classes = useStyles();
    const { steps } = props
    const renderCompletedSteps = (completedSteps: StepValue[]): JSX.Element[] => {
        return completedSteps.map(() => (
            <span
                className={classes.stepContent_number_filled}
                style={{ marginRight: '8px' }}
            >
                <FontAwesomeIcon icon={faCheck} />
            </span>
        )
        )
    }

    const renderCurrentStep = (currStep: StepValue[]): JSX.Element[] => {
        return currStep.map(step => (
            <>
                <span className={classes.stepContent_number_filled}>
                    {step.stepNum}
                </span>
                <p className={classes.stepContent_text}>
                    {step.name}
                </p>
            </>
        )
        )
    }


    const renderUncompletedSteps = (unComSteps: StepValue[]): JSX.Element[] => {
        return unComSteps.map(step => (
            <>
                <span className={classes.stepContent_number_unfilled}
                    style={{ marginLeft: '8px' }}
                >
                    {step.stepNum}
                </span>
            </>
        ))
    }


    return (
        <div className={classes.steper_wrapper}>
            <div style={{ display: 'flex' }}>
                {renderCompletedSteps(steps.filter(step => {
                    return step.status === 'completed'
                }))}


                <div style={{ display: 'flex' }}>
                    {renderCurrentStep(steps.filter(step => {
                        return step.status === 'current'
                    }))}
                </div>
            </div>


            <div style={{ display: 'flex' }}>
                {renderUncompletedSteps(steps.filter(step => {
                    return step.status === 'uncompleted'
                }))}

            </div>
        </div>
    )
}

export default DSPCreationSteper