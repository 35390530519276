import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { makeStyles,Grid, Button } from '@material-ui/core';
import Content from './Content';

const useStyles = makeStyles((theme) => ({
    root: {
      background: '#FAFAFA',
      [theme.breakpoints.up('md')]: {
        paddingTop: '90px',
      },
      paddingTop: '64px',
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    titleText: {
      color: '#0046AD',
      fontSize: '32px',
      fontWeight: 'bold',
      fontFamily: 'Arial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
    listContainer: {
      margin: '0 auto'
    },
    item_wrapper: {
      background: 'white',
      margin: '1.2rem 0',
      boxShadow: '0 3px 6px #00000014',
      borderRadius: '5px',
      border: '1px solid #70707047',
      '&.plan_menu': {
        margin: '0 0 10px',
        transition: '0.3s max-height',
        
      },
      '&.plan_menu.active': {
        visibility: 'visible',
        marginTop: '1.2rem',
        '& $item_title': {
          margin: 0,
          textAlign: 'unset',
          justifyContent: 'unset',
        },
        '&.Communication': {
          border: '3px solid #62A0C9'
        },
        '&.GrossMotor': {
          border: '3px solid #5A595C'
        },
        '&.FineMotor': {
          border: '3px solid #E37225'
        },
        '&.ProblemSolving': {
          border: '3px solid #156671'
        }
        , '&.SocialEmotional': {
          border: '3px solid #df4461'
        }
      },
    },
    item_number: {
      width: '60px',
      height: '60px',
      background: '#64A0C8',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      margin: '20px 0 20px 30px',
      borderRadius: '10px',
  
      '& img': {
        width: '100%',
        height: 'auto'
      },
      [theme.breakpoints.down('xs')]: {
        width: '40px',
        height: '40px',
        fontSize: '1rem',
        borderRadius: '5px',
        margin: '.5rem',
      }
    },
  
    item_title: {
      fontSize: 'calc(14px + (30 - 14) * ((100vw - 300px) / (1600 - 300)))',
      margin: '15px 0 4px',
      fontWeight: 'bold',
      color: 'black',
      [theme.breakpoints.down('sm')]: {
  
      },
      [theme.breakpoints.down('xs')]: {
  
        margin: '0 0 0 5px'
      },
      [theme.breakpoints.up('xl')]: {
  
        margin: '0'
  
      }
    },
    domain_value: {
      fontSize: 'calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)))',
      color: 'black',
      margin: '0 0 20px 0',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0
      }
    },
    complete_text: {
      color: '#0046AD',
      fontSize: 'calc(14px + (22 - 14) * ((100vw - 300px) / (1600 - 300)))',
      fontFamily: "'Arial'",
      [theme.breakpoints.down('sm')]: {
       
      },
      [theme.breakpoints.up('md')]: {
        
      },
      [theme.breakpoints.up('xl')]: {
      }
    },
  }))

const Level1 = (props:any) => {

  const level2Item={children:[{id:''}]};
    const classes = useStyles();
    const content = useRef<HTMLDivElement>(null);
    const [hasLevel2,setHasLevel2]=useState(false);
  const [constHeight, setConstHeight] = useState<number>(0);
  const [level2, setLevel2] = useState(level2Item);

  useEffect(() => {
    setConstHeight(content.current!.scrollHeight);
  }, []);

  const viewHandle=(key:string)=>{
    
    axios.get(`${process.env.REACT_APP_API_URL}/catalog/strategies/${key}/1`,{
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'api_key': process.env.REACT_APP_API_KEY
      }}).then((response:any) => {
        console.log(response.data)
        setHasLevel2(true);
        setLevel2(response.data)
    });
  }

  const hideViewHandle=()=>{
    setHasLevel2(false);
  }

  const domain=props.domain;
    return (
        <>
        <Grid
        key={domain.key}
        container
        item
        md={12}
        xs={12}
        className={`${classes.item_wrapper} ${domain.key.split(' ').join('')} plan_menu`}
        ref={content}
        style={{ maxHeight: `${constHeight}px` }}
      // onClick={() => { setSession('step1'); setCurrDomain(domain) }}
      >
        <Grid item md={2} sm={2} xs={3}>
          <div
            className={classes.item_number}
            style={{ borderRadius: '50%' }}
          >
            <img src={domain.value.imgUrl} />
          </div>
        </Grid>
        <Grid
          item
          md={7}
          xs={8}
          style={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            textAlign: 'center',
            flexDirection: 'column'
          }}
        >
          <p
            className={classes.item_title}
          // onClick={() => { setSession('step1'); setCurrDomain(domain) }}
          >
            {domain.value.title}
          </p>
          <p
            className={classes.domain_value}
          >
            {domain.value.desc}
          </p>
        </Grid>
        <Grid
          item
          md={2}
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {hasLevel2? 
            <Button className={classes.complete_text} onClick={()=>hideViewHandle()}>Hide</Button>
          :
            <Button className={classes.complete_text} onClick={()=>viewHandle(domain.key)}>View</Button>
          }

        </Grid>
       
      </Grid>
      <Grid >
        {hasLevel2? level2.children.map((c)=><div key={c.id}><Content domain={domain.key} content={c}></Content></div>):null}
    </Grid>
</>
    )
}

export default Level1;