/* eslint-disable no-unused-vars */
import React, { } from 'react'

//material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
({
  paper2: {
    height: '100%',
    width: '85%',
    margin: 'auto'
  },

  terms_title: {
    color: '#0046AD',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    textAlign: 'center',
  },
  terms_content_wrapper: {
    overflowY: 'hidden',
    position: 'relative',
    height: '65%',
    border: '3px solid #707070',
    borderRadius: '10px'
  },
  terms_content: {
    overflowY: 'scroll',
    height: '75%',
    margin: '48px',
    marginBottom: '0',
    //tablet
    [theme.breakpoints.down('md')]: {
      margin: '24px',
    },
    //mobile
    [theme.breakpoints.down('sm')]: {
      margin: '8px',
      height: '100%',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#707071'
    },

    '&::-webkit-scrollbar-track':
    {
      borderRadius: '10px',
      backgroundColor: '#F5F5F5'
    },

    '&::-webkit-scrollbar':
    {
      width: '8px',
      backgroundColor: '#F5F5F5'
    }
  },
  terms_showing: {
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    background: 'white',
    height: '95%',
    width: '95%',
    borderRadius: '15px',
  },
  terms_decline_btn: {
    background: '#707070',
    color: 'white',
    fontWeight: 700,
    borderRadius: '10px',
  },
  terms_agree_btn: {
    background: 'linear-gradient(45deg,#0046AD,black)',
    color: 'white',
    fontWeight: 700,
    borderRadius: '10px',
  }
}),
);

//new
export  function TermsDialogView(props: any): JSX.Element {
  const classes = useStyles();

  const { isTuDialogOpen, closeModalFunc, agreeTerms } = props;

  const handleClose = () => {
    props.closeModalFunc(false);
  };

  return (
    <>
      <div style={{ display: isTuDialogOpen ? 'block' : 'none' }} className={classes.terms_showing}>
        <>
          <div className={classes.paper2}>
            <>
              <h2 className={classes.terms_title} id="transition-modal-title">Terms of Use</h2>
              <Box position="absolute" top={10} right={15}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </>

            <div id="transition-modal-description"
              className={classes.terms_content_wrapper}>
              <div className={classes.terms_content} id="terms_content">
                <Typography paragraph>
                  Before using the Nurturing the Seed application (the
                  &ldquo;Application&rdquo;), you must agree to these terms and
                  conditions:&nbsp;
                </Typography>
                <Typography paragraph>
                  These terms and conditions are a legal agreement between you and
                  Infant Mental Health Promotion (&ldquo;IMHP&rdquo;), which is
                  housed in and legally represented by The Hospital for Sick
                  Children (&ldquo;SickKids&rdquo;). By downloading and/or using the
                  Application, you confirm your acceptance of, and agree to be bound
                  by, these terms and conditions (&ldquo;Terms of Use&rdquo;).&nbsp;
                </Typography>
                <Typography paragraph>
                  <strong>Scope of License: </strong>SickKids reserves all rights
                  not expressly granted to you under the terms of this license.
                  SickKids grants you a non-transferrable, non-exclusive,
                  non-sublicensable license to use the Application on your devices,
                  in accordance with applicable laws. You may not commercialize the
                  Application or its use without prior written permission from
                  SickKids. You may not copy (except as expressly permitted),
                  decompile, reverse engineer, disassemble, attempt to derive the
                  source code of, modify, or create derivative works of the
                  Application, any updates, or any part thereof (except as and only
                  to the extent any foregoing restriction is prohibited by
                  applicable law or to the extent as may be permitted by the
                  licensing terms governing use of any open sourced components
                  included with the Application).
                </Typography>
                <Typography paragraph>
                  <strong>Purpose of the Application:</strong> The Application is
                  intended as an informative and educational tool. It is not
                  intended for diagnosis or treatment of any condition. The
                  information entered into the Application is not accessed or read
                  by medical staff and does not form part of any medical record. All
                  information entered into or obtained from the Application should
                  be discussed with a healthcare professional for proper diagnosis
                  and treatment.
                </Typography>
                <Typography paragraph>
                  <strong>Data Management:</strong> You agree that SickKids may
                  collect and use your personally identifying information required
                  for the administration of your Application account and technical
                  data and related information, including but not limited to
                  technical information about your device, system and application
                  software, and peripherals, that is gathered periodically to
                  facilitate the provision of software updates, product support and
                  other services to you (if any) related to the Application.
                  SickKids may use this information only for internal Application
                  account administration and/or in a form that does not personally
                  identify you, to improve its products or to provide services or
                  technologies to you.
                </Typography>
                <Typography paragraph>
                  The information that you enter into the Application to use the
                  Application is saved only on your personal device and is not
                  stored, used or disclosed by SickKids.
                </Typography>
                <Typography paragraph>
                  <strong>No Warranty:</strong> THE APPLICATION IS PROVIDED &quot;AS
                  IS&quot; WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                  EXPRESS, STATUTORY OR IMPLIED, AS TO THE OPERATION OF THE
                  APPLICATION, OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS
                  INCLUDED ON THE APPLICATION. TO THE FULLEST EXTENT PERMISSIBLE BY
                  APPLICABLE LAW, SICKKIDS AND ITS OFFICERS, DIRECTORS, EMPLOYEES,
                  PHYSICIANS AND CONTRACTORS DISCLAIM ALL WARRANTIES, EXPRESS,
                  STATUTORY, OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT.&nbsp;
                </Typography>
                <Typography paragraph>
                  Sickkids does not warrant that all aspects of the Application will
                  be available at any time or from any particular location, will be
                  secure or error-free, that defects will be corrected, or that the
                  application is free of viruses or other potentially harmful
                  components. Sickkids makes no commitment to provide any updates,
                  new versions or otherwise support the application or make the
                  Application available. SickKids will not be responsible or liable
                  in any way for the accuracy, relevancy or copyright compliance of
                  the material contained in the Application.&nbsp;
                </Typography>
                <Typography paragraph>
                  <strong>Waiver:</strong> BY USING THIS APPLICATION, YOU HEREBY
                  WAIVE ANY CLAIMS, CAUSES OF ACTION AND DEMANDS, WHETHER IN TORT OR
                  CONTRACT, AGAINST SICKKIDS (INCLUDING ITS AFFILIATES, EMPLOYEES,
                  PHYSICIANS, DIRECTORS AND AGENTS) IN ANY WAY RELATED TO USE OF THE
                  APPLICATION AND THE INFORMATION DERIVED FROM IT.&nbsp;
                </Typography>
                <Typography paragraph>
                  SickKids assumes no liability for any inaccurate or incomplete
                  information contained in the Application, nor any actions taken in
                  reliance thereon. You assume full responsibility for the use of
                  any information provided.
                </Typography>
                <Typography paragraph>
                  <strong>Governing Law</strong>: These Terms of Use are governed by
                  the laws of the Province of Ontario, and the federal laws
                  applicable therein, without regard to the conflict of laws
                  provisions.&nbsp;
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                height: '20%'
              }}
            >
              <Button
                variant="contained"
                className={classes.terms_decline_btn}
                onClick={() => {
                  props.agreeTerms(false);
                  handleClose();
                }}
              >
                Decline
              </Button>
              <Button
                variant="contained"
                className={classes.terms_agree_btn}
                onClick={() => {
                  props.agreeTerms(true);
                  handleClose();
                }}
              >
                I Agree
              </Button>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export function TermsDialogViewWithoutButton(props: any): JSX.Element {
  const classes = useStyles();

  const { isTuDialogOpen} = props;

  const handleClose = () => {
    props.closeModalFunc(false);
  };

  return (
    <>
      <div style={{ display: isTuDialogOpen ? 'block' : 'none' }} className={classes.terms_showing}>
        <>
          <div className={classes.paper2}>
            <>
              <h2 className={classes.terms_title} id="transition-modal-title">Terms of Use</h2>
              <Box position="absolute" top={10} right={15}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </>

            <div id="transition-modal-description"
              className={classes.terms_content_wrapper}>
              <div className={classes.terms_content} id="terms_content">
                <Typography paragraph>
                  Before using the Nurturing the Seed application (the
                  &ldquo;Application&rdquo;), you must agree to these terms and
                  conditions:&nbsp;
                </Typography>
                <Typography paragraph>
                  These terms and conditions are a legal agreement between you and
                  Infant Mental Health Promotion (&ldquo;IMHP&rdquo;), which is
                  housed in and legally represented by The Hospital for Sick
                  Children (&ldquo;SickKids&rdquo;). By downloading and/or using the
                  Application, you confirm your acceptance of, and agree to be bound
                  by, these terms and conditions (&ldquo;Terms of Use&rdquo;).&nbsp;
                </Typography>
                <Typography paragraph>
                  <strong>Scope of License: </strong>SickKids reserves all rights
                  not expressly granted to you under the terms of this license.
                  SickKids grants you a non-transferrable, non-exclusive,
                  non-sublicensable license to use the Application on your devices,
                  in accordance with applicable laws. You may not commercialize the
                  Application or its use without prior written permission from
                  SickKids. You may not copy (except as expressly permitted),
                  decompile, reverse engineer, disassemble, attempt to derive the
                  source code of, modify, or create derivative works of the
                  Application, any updates, or any part thereof (except as and only
                  to the extent any foregoing restriction is prohibited by
                  applicable law or to the extent as may be permitted by the
                  licensing terms governing use of any open sourced components
                  included with the Application).
                </Typography>
                <Typography paragraph>
                  <strong>Purpose of the Application:</strong> The Application is
                  intended as an informative and educational tool. It is not
                  intended for diagnosis or treatment of any condition. The
                  information entered into the Application is not accessed or read
                  by medical staff and does not form part of any medical record. All
                  information entered into or obtained from the Application should
                  be discussed with a healthcare professional for proper diagnosis
                  and treatment.
                </Typography>
                <Typography paragraph>
                  <strong>Data Management:</strong> You agree that SickKids may
                  collect and use your personally identifying information required
                  for the administration of your Application account and technical
                  data and related information, including but not limited to
                  technical information about your device, system and application
                  software, and peripherals, that is gathered periodically to
                  facilitate the provision of software updates, product support and
                  other services to you (if any) related to the Application.
                  SickKids may use this information only for internal Application
                  account administration and/or in a form that does not personally
                  identify you, to improve its products or to provide services or
                  technologies to you.
                </Typography>
                <Typography paragraph>
                  The information that you enter into the Application to use the
                  Application is saved only on your personal device and is not
                  stored, used or disclosed by SickKids.
                </Typography>
                <Typography paragraph>
                  <strong>No Warranty:</strong> THE APPLICATION IS PROVIDED &quot;AS
                  IS&quot; WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                  EXPRESS, STATUTORY OR IMPLIED, AS TO THE OPERATION OF THE
                  APPLICATION, OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS
                  INCLUDED ON THE APPLICATION. TO THE FULLEST EXTENT PERMISSIBLE BY
                  APPLICABLE LAW, SICKKIDS AND ITS OFFICERS, DIRECTORS, EMPLOYEES,
                  PHYSICIANS AND CONTRACTORS DISCLAIM ALL WARRANTIES, EXPRESS,
                  STATUTORY, OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT.&nbsp;
                </Typography>
                <Typography paragraph>
                  Sickkids does not warrant that all aspects of the Application will
                  be available at any time or from any particular location, will be
                  secure or error-free, that defects will be corrected, or that the
                  application is free of viruses or other potentially harmful
                  components. Sickkids makes no commitment to provide any updates,
                  new versions or otherwise support the application or make the
                  Application available. SickKids will not be responsible or liable
                  in any way for the accuracy, relevancy or copyright compliance of
                  the material contained in the Application.&nbsp;
                </Typography>
                <Typography paragraph>
                  <strong>Waiver:</strong> BY USING THIS APPLICATION, YOU HEREBY
                  WAIVE ANY CLAIMS, CAUSES OF ACTION AND DEMANDS, WHETHER IN TORT OR
                  CONTRACT, AGAINST SICKKIDS (INCLUDING ITS AFFILIATES, EMPLOYEES,
                  PHYSICIANS, DIRECTORS AND AGENTS) IN ANY WAY RELATED TO USE OF THE
                  APPLICATION AND THE INFORMATION DERIVED FROM IT.&nbsp;
                </Typography>
                <Typography paragraph>
                  SickKids assumes no liability for any inaccurate or incomplete
                  information contained in the Application, nor any actions taken in
                  reliance thereon. You assume full responsibility for the use of
                  any information provided.
                </Typography>
                <Typography paragraph>
                  <strong>Governing Law</strong>: These Terms of Use are governed by
                  the laws of the Province of Ontario, and the federal laws
                  applicable therein, without regard to the conflict of laws
                  provisions.&nbsp;
                </Typography>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export function TermsDialogViewNewWindow(): JSX.Element {
  const classes = useStyles();

  

  return (
    <>
      <div style={{ display:  'block'}} className={classes.terms_showing}>
        <>
          <div className={classes.paper2}>
            <>
              <h2 className={classes.terms_title} id="transition-modal-title">Terms of Use</h2>
             
            </>

            <div id="transition-modal-description"
              >
              <div style={{padding: '30px 100px 100px 100px'}} id="terms_content">
                <Typography paragraph>
                  Before using the Nurturing the Seed application (the
                  &ldquo;Application&rdquo;), you must agree to these terms and
                  conditions:&nbsp;
                </Typography>
                <Typography paragraph>
                  These terms and conditions are a legal agreement between you and
                  Infant Mental Health Promotion (&ldquo;IMHP&rdquo;), which is
                  housed in and legally represented by The Hospital for Sick
                  Children (&ldquo;SickKids&rdquo;). By downloading and/or using the
                  Application, you confirm your acceptance of, and agree to be bound
                  by, these terms and conditions (&ldquo;Terms of Use&rdquo;).&nbsp;
                </Typography>
                <Typography paragraph>
                  <strong>Scope of License: </strong>SickKids reserves all rights
                  not expressly granted to you under the terms of this license.
                  SickKids grants you a non-transferrable, non-exclusive,
                  non-sublicensable license to use the Application on your devices,
                  in accordance with applicable laws. You may not commercialize the
                  Application or its use without prior written permission from
                  SickKids. You may not copy (except as expressly permitted),
                  decompile, reverse engineer, disassemble, attempt to derive the
                  source code of, modify, or create derivative works of the
                  Application, any updates, or any part thereof (except as and only
                  to the extent any foregoing restriction is prohibited by
                  applicable law or to the extent as may be permitted by the
                  licensing terms governing use of any open sourced components
                  included with the Application).
                </Typography>
                <Typography paragraph>
                  <strong>Purpose of the Application:</strong> The Application is
                  intended as an informative and educational tool. It is not
                  intended for diagnosis or treatment of any condition. The
                  information entered into the Application is not accessed or read
                  by medical staff and does not form part of any medical record. All
                  information entered into or obtained from the Application should
                  be discussed with a healthcare professional for proper diagnosis
                  and treatment.
                </Typography>
                <Typography paragraph>
                  <strong>Data Management:</strong> You agree that SickKids may
                  collect and use your personally identifying information required
                  for the administration of your Application account and technical
                  data and related information, including but not limited to
                  technical information about your device, system and application
                  software, and peripherals, that is gathered periodically to
                  facilitate the provision of software updates, product support and
                  other services to you (if any) related to the Application.
                  SickKids may use this information only for internal Application
                  account administration and/or in a form that does not personally
                  identify you, to improve its products or to provide services or
                  technologies to you.
                </Typography>
                <Typography paragraph>
                  The information that you enter into the Application to use the
                  Application is saved only on your personal device and is not
                  stored, used or disclosed by SickKids.
                </Typography>
                <Typography paragraph>
                  <strong>No Warranty:</strong> THE APPLICATION IS PROVIDED &quot;AS
                  IS&quot; WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                  EXPRESS, STATUTORY OR IMPLIED, AS TO THE OPERATION OF THE
                  APPLICATION, OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS
                  INCLUDED ON THE APPLICATION. TO THE FULLEST EXTENT PERMISSIBLE BY
                  APPLICABLE LAW, SICKKIDS AND ITS OFFICERS, DIRECTORS, EMPLOYEES,
                  PHYSICIANS AND CONTRACTORS DISCLAIM ALL WARRANTIES, EXPRESS,
                  STATUTORY, OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
                  WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
                  NON-INFRINGEMENT.&nbsp;
                </Typography>
                <Typography paragraph>
                  Sickkids does not warrant that all aspects of the Application will
                  be available at any time or from any particular location, will be
                  secure or error-free, that defects will be corrected, or that the
                  application is free of viruses or other potentially harmful
                  components. Sickkids makes no commitment to provide any updates,
                  new versions or otherwise support the application or make the
                  Application available. SickKids will not be responsible or liable
                  in any way for the accuracy, relevancy or copyright compliance of
                  the material contained in the Application.&nbsp;
                </Typography>
                <Typography paragraph>
                  <strong>Waiver:</strong> BY USING THIS APPLICATION, YOU HEREBY
                  WAIVE ANY CLAIMS, CAUSES OF ACTION AND DEMANDS, WHETHER IN TORT OR
                  CONTRACT, AGAINST SICKKIDS (INCLUDING ITS AFFILIATES, EMPLOYEES,
                  PHYSICIANS, DIRECTORS AND AGENTS) IN ANY WAY RELATED TO USE OF THE
                  APPLICATION AND THE INFORMATION DERIVED FROM IT.&nbsp;
                </Typography>
                <Typography paragraph>
                  SickKids assumes no liability for any inaccurate or incomplete
                  information contained in the Application, nor any actions taken in
                  reliance thereon. You assume full responsibility for the use of
                  any information provided.
                </Typography>
                <Typography paragraph>
                  <strong>Governing Law</strong>: These Terms of Use are governed by
                  the laws of the Province of Ontario, and the federal laws
                  applicable therein, without regard to the conflict of laws
                  provisions.&nbsp;
                </Typography>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}