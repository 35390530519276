import React, { createContext, useState } from "react";

type InitialStateType = {
  section: string;
  setSection: React.Dispatch<React.SetStateAction<string>>;
};

const AdminContext = createContext<InitialStateType | undefined>(undefined);

const AdminProvider = ({ children }: any) => {
  const [section, setSection] = useState<string>("admin");

  return (
    <AdminContext.Provider value={{ section, setSection }}>
      {children}
    </AdminContext.Provider>
  );
};

const useAdminContext = () => {
  const adminContext = React.useContext(AdminContext);
  console.log(adminContext)
  if (adminContext === undefined) {
    throw new Error("useOnboardingContext must be inside a OnboardingProvider");
  }
  
  return adminContext;
};

export { AdminContext, AdminProvider, useAdminContext };
