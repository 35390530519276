/* eslint-disable no-unused-vars */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Footer from './Footer';


const useStyles = makeStyles((theme) => ({
  section: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      backgroundSize: 'cover',
    },
    textAlign:'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    margin:'auto'
  },
  btn: {
    color: 'white',
    borderRadius: '5px',
    backgroundColor: '#0046AD',
    width: '15%',
    padding: '5px',
    border: '2px solid transparent',
    marginRight: '3rem',
    '&:hover': {
      color: 'white',
      borderRadius: '5px',
      backgroundColor: '#0046AD',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',

    }
  },
  heading:{
    //fontSize:'32px',
    color:'#0046AD',
    //[theme.breakpoints.down('sm')]:{
      //fontSize:'22px'
    //}
  },
  subtitle:{
    fontSize:'24px',
    color:'#0046AD',
    fontWeight:'bold',
    [theme.breakpoints.down('sm')]:{
      fontSize:'18px'
    }
  },
  content:{
    fontSize:'20px',
    textAlign:'left',
    lineHeight:'40px',
    [theme.breakpoints.down('sm')]:{
      fontSize:'16px',
      lineHeight:'30px',
    }
  }

}));

export default function AboutPage(): JSX.Element {
  const styles = useStyles();
  const history =useHistory();
  return (
    <>
      <Header />
      <Container maxWidth={false}>
      <p>&nbsp; </p>
      <Grid item xs={1} md={2}> </Grid>
            
          <Grid  item className={styles.section} style={{
            // columnGap: '4%',
            paddingTop: '10%',
            paddingBottom: '5%',color:'#333333'
          }} xs={10} md={8}>
             <h1 className={styles.heading}>About</h1>
            <Grid item  style={{textAlign:'left'}}>
             <p className={styles.subtitle}>What is Nurturing the Seed?</p>
            
             <p className={styles.content}>
             Nurturing the Seed (NtS) is a community-based and culturally informed program that supports the mental health and wellbeing of Indigenous children prenatal to age 6. Nurturing the Seed initially began as a resource that was created by Infant and Mental Health Promotion (IEMHP),  in partnership with an Elders Council to specifically support Indigenous children. It has now grown into a Canada-wide research project to determine its efficacy amongst Indigenous children. In 2021-2022, over 150 practitioners from 12 Indigenous communities participated in training and Coaching Connect sessions and enrolled 63 new families in the NtS program. The staff involved with the program influenced agency policies, developed and implemented peer mentoring programs and saw themselves as leaders in infant and early mental health in their communities. In a survey completed by parents and caregivers participating in the program, we learned that the program helped them feel less stressed (64%), feel better equipped to meet their child’s needs (100%) and increased their confidence in supporting their child’s development (93%).
             </p> 

             </Grid>
             <Grid item  style={{textAlign:'left',marginTop:'55px'}}>
             <p className={styles.subtitle}>What is Nurturing the Seed App?</p>
            
             <p className={styles.content}>
             The Nurturing the Seed App is an online developmental support planning tool that was funded by Nishnawbe Aski Nation (NAN) and developed by IEMHP, to help frontline practitioners create developmental support plans (DSPs) for Indigenous children based on the child’s strength and developmental needs. This app provides built-in step by step instructions and prompts that practitioners can easily follow to create a DSP. The responsive design will allow practitioners to create DSPs on their computer or mobile device, during home visit or in the community with families. The App is only available to trained frontline practitioners involved in the NtS Study.   </p>  
             </Grid>

             <Grid item  style={{textAlign:'left',marginTop:'55px'}}>
             <p className={styles.subtitle}>What is a Developmental Support Plan?</p>
            
             <p className={styles.content}>
             A Developmental Support Plan is created based on the child’s strengths, and supports the child from a developmental perspective, while keeping in mind the unique needs of the family. The DSPs is comprised of five developmental domains including communication, fine motor, gross motor, problem-solving and social emotional.    </p>  
             </Grid>

            <Grid style={{textAlign:'left',marginTop:'55px'}}>
                <p style={{fontSize:'28px',color:'#0046AD'}}>Are you interested in joining NtS Study? </p>
                <Button variant="contained" className={styles.btn} onClick={() => history.push('/contact')} >
                    Contact Us
                </Button>
            </Grid>
             
        </Grid>

        <Grid item xs={1} md={2}> </Grid>
      </Container>
      <Footer />
    </>
  );
}