/* eslint-disable no-unused-vars */
import { Typography, makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { globalStyles } from "../../../GlobalStyles";
import HelpCenterBreadCrums from "./HelpCenterBreadCrums";
import arrowRightImg from '../../../img/arrow_right.png';
import arrowDownImg from '../../../img/arrow_down.png';

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
    },
    border: {
        borderColor: '#DFDFDF',
        backgroundColor: '#F7F7F7',
        borderStyle: 'solid',
        padding: '10px 0',

    },
    question: {
        fontSize: 'x-large',
        color: '#0046AD',
        fontWeight: 'bold',
        textAlign: 'left',
        '&:hover': {
            cursor: "pointer",
        },
    },
    menu: {
        fontSize: 'large',
        color: '#0046AD',
        '&:hover': {
            cursor: "pointer",
        },
    },
    img: {
        marginRight: '5px'
    },
    answer: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        marginTop: '10px',
        padding: '10px'
    },
    answerP: {
        margin: '0'
    }

}));

const FAQs = (props:any): JSX.Element => {
    const classes = useStyles();
    const globalClasses = globalStyles();
    const [isQ1Expand, setQ1Expand] = useState(false);
    const [isQ2Expand, setQ2Expand] = useState(false);
    const [isQ3Expand, setQ3Expand] = useState(false);
    const [isQ4Expand, setQ4Expand] = useState(false);
    const [isQ5Expand, setQ5Expand] = useState(false);
    const [isQ6Expand, setQ6Expand] = useState(false);
    const [isQ7Expand, setQ7Expand] = useState(false);
    const [isQ8Expand, setQ8Expand] = useState(false);

    const expandAll = () => {
        setQ1Expand(true);
        setQ2Expand(true);
        setQ3Expand(true);
        setQ4Expand(true);
        setQ5Expand(true);
        setQ6Expand(true);
        setQ7Expand(true);
        setQ8Expand(true);
    }
    const collapseAll = () => {
        setQ1Expand(false);
        setQ2Expand(false);
        setQ3Expand(false);
        setQ4Expand(false);
        setQ5Expand(false);
        setQ6Expand(false);
        setQ7Expand(false);
        setQ8Expand(false);
    }

    return (
        <>
            <Grid container item xs={12} direction="row">
                <Grid item xs={12}>
                    <HelpCenterBreadCrums
                        setHelp={props.setHelp}
                        items={[
                            { name: 'Help Centre', link: 'main' },
                            { name: 'Frequently Asked Questions', link: 'coverPage' },
                        ]}
                    
                    />
                    <Typography variant="h4" className={globalClasses.titleText}>
                        Frequently Asked Questions
                    </Typography>

                    <Grid container item xs={12} direction="row">

                        <Grid item xs={1}></Grid>
                        <Grid item xs={11} >
                            
                            <Grid direction="row" style={{ margin: "20px 0" }}>
                                <span className={classes.menu} onClick={() => expandAll()}>Expand All</span><span className={classes.menu} style={{ marginLeft: "20px" }} onClick={() => collapseAll()}>Collapse All</span>
                            </Grid>



                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ1Expand(!isQ1Expand)}>
                                    <img src={isQ1Expand ? arrowDownImg : arrowRightImg} className={classes.img} />How can I change an image from .jpg to .png on my computer?
                                </div>
                                {isQ1Expand &&
                                    <div className={classes.answer}>
                                        <p className={classes.answerP}>If you are a Mac user, you can use the built-in Preview App. Here are the steps you will need to take:</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>1. Open the .jpg image in Preview. You can do this by right clicking the image and selecting “Open With,” then “Preview”.</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>2. Once the image is open, click on “File” in the top menu, and then select “Export”.</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>3. A new window will open. Here, click on the “Format” dropdown menu and select “.png”.</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>4. Click “Save”. You image is now saved as .png.</p><br />

                                        <p className={classes.answerP}>If you are a Windows user, you can use the built-in Paint App. Here are the steps you will need to take:</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>1. Right-click the .jpg image and select “Open With,” then click on “Paint”.</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>2. Once the image is open, click on “File” in the top menu, then select “Save As”, and choose “.png” from the list of formats.</p>
                                        <p className={classes.answerP} style={{ paddingLeft: '20px' }}>3. Click “Save.” Your image is now saved as a .png.</p>
                                    </div>
                                }
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ2Expand(!isQ2Expand)}>
                                    <img src={isQ2Expand ? arrowDownImg : arrowRightImg} className={classes.img} />Where can I locate my saved plan after clicking on "Save Plan"?
                                </div>
                                {isQ2Expand &&
                                    <div className={classes.answer}>
                                        After you click on “Save Plan”, your DSP plan will be automatically downloaded and stored in your
                                        “Downloads” folder on your device. The file will appear as a “.iemhp” type file. It’s important to note
                                        that this file is encrypted and cannot be opened with any other software. If you need to make changes
                                        to this file, you will first need to log into the NTS app, click “Edit Plan” to upload this .iemhp file and then
                                        edit the plan in the app.
                                    </div>}
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ3Expand(!isQ3Expand)}>
                                    <img src={isQ3Expand ? arrowDownImg : arrowRightImg} className={classes.img} />Where can I find my PDF document after I click on "Export Plan"?
                                </div>
                                {isQ3Expand &&
                                    <div className={classes.answer}>
                                        Once you click on “Export Plan”, your document will be automatically downloaded into your
                                        “Downloads” folder. By default, the file will be named “file.pdf”. To help keep your files organized, we
                                        highly recommend renaming this file to something more descriptive and meaningful to you.
                                    </div>}
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ4Expand(!isQ4Expand)}>
                                    <img src={isQ4Expand ? arrowDownImg : arrowRightImg} className={classes.img} />What's the difference between "Save Plan" and "Export Plan"?
                                </div>
                                {isQ4Expand &&
                                    <div className={classes.answer}>
                                        <p className={classes.answerP}>The “Save Plan” and “Export Plan” options serve different purposes.</p>
                                        <br />
                                        <p className={classes.answerP}>“Save Plan” is what you would click on when you have not completed the plan yet and want to continue
                                            working on it in the future, giving you the option to come back and edit the plan later. When you click
                                            on “Save Plan”, the app saves your plan as a “.iemhp” file to your device (this is crucial as our app doesnot store or collect any plan data). When you are ready to return and finish the plan, you would open
                                            the NTS app, click on &quot;Edit Plan&quot;, find and select the file that you had saved on your device, and continue
                                            working on it in the app. See Question 2 above.</p>
                                        <br />
                                        <p className={classes.answerP}>“Export Plan” is what you would click on when you are finished creating the plan – your plan is complete
                                            and it requires no more edits. When you click on “Export Plan”, the plan you had created will be saved
                                            on your device as a PDF file making it easy to print and share with family members or caregivers. This
                                            .pdf document is a final version of your plan and cannot be edited in our app like the .iemhp file created
                                            when a plan is saved. See Question 3 above.</p>
                                    </div>}
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ5Expand(!isQ5Expand)}>
                                    <img src={isQ5Expand ? arrowDownImg : arrowRightImg} className={classes.img} />Can someone else edit my plan?
                                </div>
                                {isQ5Expand &&
                                    <div className={classes.answer}>
                                        No. Each .iemhp file is encrypted and tied to your email ID. This means that only you can edit the plan
                                        when you log in using your unique email and password. Your plan is secure and cannot be modified by
                                        anyone else without access to your login credentials.
                                    </div>}
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ6Expand(!isQ6Expand)}>
                                    <img src={isQ6Expand ? arrowDownImg : arrowRightImg} className={classes.img} />How can I share my plan with the families I work with or other co-workers?
                                </div>
                                {isQ6Expand &&
                                    <div className={classes.answer}>
                                        If you wish to share your DSP with the families you work with, you can do so by using the “Export Plan”
                                        function. This will create a PDF version of your plan which can be opened on any device that supports
                                        PDF files. You can then print or share this PDF file via email or cloud storage. See Question 3 above.
                                    </div>}
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ7Expand(!isQ7Expand)}>
                                    <img src={isQ7Expand ? arrowDownImg : arrowRightImg} className={classes.img} />Why can't I change my personal information under "My Profile"?
                                </div>
                                {isQ7Expand &&
                                    <div className={classes.answer}>
                                        For security and data accuracy, changes to your personal information such as your name or email
                                        address are handled directly by our tech support team. If you need to make changes, please send your
                                        request to <a href="mailto:iemhp.mail@sickkids.ca">iemhp.mail@sickkids.ca</a>. To process these changes, we also require approval from your supervisor.
                                    </div>}
                            </Grid>

                            <Grid direction="row" className={classes.border} >
                                <div className={classes.question} onClick={() => setQ8Expand(!isQ8Expand)}>
                                    <img src={isQ8Expand ? arrowDownImg : arrowRightImg} className={classes.img} />What support is available if I need extra help in creating DSP plans?
                                </div>
                                {isQ8Expand &&
                                    <div className={classes.answer}>
                                        If you need additional support in creating DSP’s beyond simply navigating the app itself, our IEMHP
                                        Training Team provides ‘Coaching Connect’ sessions. These sessions are available to service providers
                                        who have successfully completed the DSP training and are currently involved in the NTS program. Our
                                        coaching sessions offer guidance to help enhance your DSP skills. For more information, please contact
                                        our Training Team at <a href="mailto:iemhp.learning@sickkids.ca">iemhp.learning@sickkids.ca</a>.
                                    </div>}
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>


        </>);
}

export default FAQs;

