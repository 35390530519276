/* eslint-disable no-unused-vars */


import React from 'react';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import CreateNewDSP from './view/Dashboard/DSPPages/CreateNewDSP';
import DSPRelationPage from './view/Dashboard/DSPPages/DSPRelationPage';
import ContactPage from './view/ContactPage';
import CustomerDashBoard from './view/CustomerDashBoard';
import MyTeam from './view/Dashboard/MyTeam';
import ForgotPassword from './view/ForgotPassword';
import ForgotPasswordValidate from './view/ForgotPasswordValidate';
import HomePage from './view/HomePage';
import LoginPage from './view/LoginPage';
import ResetPassword from './view/ResetPassword';
import SignUpPage from './view/SignUpPage';
import ProfilePage from './view/Dashboard/Profile';
import StaffEdit from './view/Dashboard/MyTeam/StaffEdit';
import AboutPage from './view/AboutPage';
import './App.css';
import { TermsDialogViewNewWindow } from './view/component/TermsDialogNew';


const CheckVersion: React.FC<{}> = () => {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

    if (!isLatestVersion) {
        console.log("update version")
        emptyCacheStorage();
    } else { console.log("this is the latest version") }
    return (
        <div hidden>
            checking version
        </div>
    );


};


const App = () => {

    const IsLogin = (): boolean => sessionStorage.getItem('token') !== undefined && sessionStorage.getItem('token') !== '' && sessionStorage.getItem('token') !== null;

    return (
        <>
            <ClearCacheProvider>
                <CheckVersion />
            </ClearCacheProvider>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/customerDashBoard" render={() => IsLogin() ? <CustomerDashBoard /> : <Redirect to='/' />}/>
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/contact" component={ContactPage} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/signUp" component={SignUpPage} />
                    <Route exact path="/forgotPassword" component={ForgotPassword} />
                    <Route path="/forgotPasswordValidate/:token" component={ForgotPasswordValidate} />
                    <Route exact path="/resetPassword" component={ResetPassword} />
                    <Route exact path="/DSP/createNewDSP" render={() => IsLogin() ? <CreateNewDSP /> : <Redirect to='/' />} />
                    <Route exact path="/DSPRelationPage" component={DSPRelationPage} />
                    <Route exact path="/myTeam" component={MyTeam} />
                    <Route path="/staffEdit/:userId" component={StaffEdit} />
                    <Route path="/About"component={AboutPage}/>  
                    <Route path="/TermsAndCondition" component={TermsDialogViewNewWindow}/>
                    {/* <Route path="/cam" component={cam}/>          
   <Route path="/strategy/:domainStr" component={StrategyDashboard} />*/}
                </Switch>

            </BrowserRouter>
        </>

    );

}
export default App;