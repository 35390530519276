

/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Collapse,
  Grid,
  Button,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import {globalStyles} from "../../GlobalStyles";


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      paddingTop: '90px',
    },
    paddingTop: '64px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  listContainer: {
    margin: '0 auto'
  },
  titleText: {
    color: '#0046AD',
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  greetingText: {
    fontWeight: 600,
    marginBottom: '20px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '60%',
    marginLeft: '30px',
    "@media (max-width: 992px)": {
      marginLeft: '0px',
      width: '100%',
    },
    padding: '20px 40px',
    color: '#0046AD',
  },
  cardText: {
    marginBottom: '8px',
  },
  cardSubtext: {
    fontSize: '12px',
  },
  card: {
    borderRadius: '10px',
    "@media (max-width: 992px)": {
      width: '100%',
    },
    marginBottom: '15px'
  },
  menu: {
    "& .MuiPaper-root": {
      marginTop: '8px',
      borderColor: '#0046AD',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '10px',
      maxHeight: '20%',
      overflowY: 'scroll',
    },
    "& .MuiListItem-root": {
      "@media (max-width: 600px)": {
        fontSize: '12px',
      },
    },
  },
  input: {
    width: '65%',
    marginBottom: '5%',
    '@media (max-width: 600px)': {
      width: '80%',
      '& .MuiSelect-root': {
        fontSize: '14px',
      },
      '& .MuiFormLabel-root': {
        fontSize: '14px',
      },
      '& .MuiInputBase-input': {
        fontSize: '14px',
      },
    },
  },
  saveButton: {
    borderRadius: '8px',
    width: '20%',
    backgroundColor: '#0046AD',
    color: "#FFFFFF",
    fontSize: '18px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textTransform: 'none',
    "@media (max-width: 600px)": {
      fontSize: '12px',
    },
    '&:disabled': {
      background: 'gray',
      color: '#FFFFFF',
    }
  },
  cancelButton: {
    fontSize: '18px',
    fontFamily: 'Arial',
    textTransform: "none",
    textDecorationLine: "underline"
  },
  resultMessage: {
    color: '#000000',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  errorMessage: {
    color: '#CC0000',
    fontSize: '14px',
    fontFamily: 'Arial',
  },
  
}));


export interface UserInfo {
  name: string,
  email: string,
  country: string,
  province: string,
  city: string,
  profession: string,
}

function ProfilePage(props: any) {
  const classes = useStyles();
  const globalClasses=globalStyles();

  const [toggled, setToggled] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: "",
    email: "",
    country: "",
    province: "",
    city: "",
    profession: ""
  });
  const [locationExpanded, setLocationExpanded] = useState(false);
  const [professionExpanded, setProfessionExpanded] = useState(false);
  const [passwordExpanded, setPasswordExpanded] = useState(false);

  useEffect(() => {

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}user/profile`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      }
    }).then(res => {
      setUserInfo(res.data);
    }).catch(error => {
      // user does not have profile (should not happen)
      console.log('User does not have profile.');
    });

  }, []);


  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };
  const [selectmenu, setSelectMenu] = useState('myProfile')
  return (
    <>
     
       
      <Grid item md={12}>  
           <Typography
              variant='h4'
              className={globalClasses.titleText}
            >
              My Profile
            </Typography>
        </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={1}></Grid>
            <Grid item xs={10} justifyContent="center" alignItems="center">
             
              <Grid item xs={12} style={{ marginTop: '30px' }}>
                <PersonalInformationCard userInfo={userInfo} setSelectMenu={props.setSelectMenu} />
                <LocationCard expanded={locationExpanded} setExpanded={setLocationExpanded} userInfo={userInfo} setUserInfo={setUserInfo} />
                <ProfessionCard expanded={professionExpanded} setExpanded={setProfessionExpanded} userInfo={userInfo} setUserInfo={setUserInfo} />
                <PasswordCard expanded={passwordExpanded} setExpanded={setPasswordExpanded} userInfo={userInfo} />
              </Grid>

            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        
     

    </>
  )
}

function PersonalInformationCard(props: any) {
  const classes = useStyles();
  const { userInfo } = props;

  const contactUs = <a href='#' onClick={() => props.setSelectMenu('contactUs')}>contact us</a>;

  return (
    <Card className={classes.card}>
      <CardHeader title={<CardTitle title="Personal Information" />} disableTypography style={{ paddingBottom: '0px' }} />
      <CardContent>
        <Typography className={classes.cardText}>
          Name: {userInfo.name}
        </Typography>
        <Typography className={classes.cardText}>
          Email: {userInfo.email}
        </Typography>
        <br />
        <Typography className={classes.cardSubtext}>
          To change your email, please {contactUs}.
        </Typography>
      </CardContent>
    </Card>
  )
}

function LocationCard(props: any) {
  const classes = useStyles();
  const { expanded, setExpanded, userInfo, setUserInfo } = props;
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');

  useEffect(() => {
    setCountry(userInfo.country);
    setProvince(userInfo.province);
    setCity(userInfo.city);
  }, [userInfo, expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  const renderCountryMenu = () => {
    const menuOptions = [
      "Canada",
      "Other"
    ];

    const handleChange = (event: any) => {
      setCountry(event.target.value);
      setProvince('');
    };

    return (
      <FormControl className={classes.input} >
        <InputLabel id="country-select-label" style={{ marginLeft: "15px" }}>Country *</InputLabel>
        <Select
          labelId="country-select-label"
          id="country-select"
          value={country}
          onChange={handleChange}
          variant="outlined"
          label="Country/Region *"
          required
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            className: classes.menu,
          }}
        >

          {menuOptions.map((option: string, index: number) =>
            <MenuItem key={index} value={option}>{option}</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  }

  const renderProvinceMenu = () => {
    const menuOptionsAll = [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
      "Other"
    ];

    const menuOptionsOther = [
      "Other"
    ];

    const handleChange = (event: any) => {
      setProvince(event.target.value);
    };

    return (
      <FormControl className={classes.input} >
        <InputLabel id="province-select-label" style={{ marginLeft: "15px" }}>Province *</InputLabel>
        <Select
          labelId="province-select-label"
          id="province-select"
          value={province}
          onChange={handleChange}
          variant="outlined"
          label="Province *"
          required
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            className: classes.menu,
          }}
        >

          {country == "Other" ?
            menuOptionsOther.map((option: string, index: number) =>
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ) :
            menuOptionsAll.map((option: string, index: number) => <MenuItem key={index} value={option}>{option}</MenuItem>)
          }
        </Select>
      </FormControl>
    )
  }

  const handleSubmit = () => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}user/profile/location`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
      data: {
        country: country,
        province: province,
        city: city,
      }
    }).then(res => {
      toggleExpanded();
      setUserInfo({ ...userInfo, country: country, province: province, city: city });
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <Card className={classes.card}>
      <CardHeader title={<CardTitle title="Location" button={!expanded} onClick={toggleExpanded} />} disableTypography style={{ paddingBottom: '0px' }} />
      {!expanded &&
        <CardContent>
          <Typography className={classes.cardText}>
            {userInfo.city}, {userInfo.province}, {userInfo.country}
          </Typography>
        </CardContent>}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {renderCountryMenu()}
          {renderProvinceMenu()}
          <TextField className={classes.input} required label="City" variant="outlined" defaultValue={city} onChange={(event) => setCity(event.target.value)} />
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={toggleExpanded} size="small" variant="text" className={classes.cancelButton}>
            Cancel
          </Button>
          <Button variant="contained" size="small" className={classes.saveButton}
            onClick={handleSubmit}
            disabled={!country || !province || !city}>
            Save
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  )
}

function ProfessionCard(props: any) {
  const classes = useStyles();
  const { expanded, setExpanded, userInfo, setUserInfo } = props;
  const [profession, setProfession] = useState('');
  const [otherProfession, setOtherProfession] = useState('');

  useEffect(() => {
    if (!menuOptions.includes(userInfo.profession)) {
      setProfession("Other, please specify");
      setOtherProfession(userInfo.profession);
    }
    else {
      setProfession(userInfo.profession);
      setOtherProfession('');
    }
  }, [userInfo, expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const menuOptions = [
    "Children's Mental Health",
    "Early Learning and Early Care",
    "Child Welfare / Child Protection",
    "Nurses",
    "Social Workers",
    "Physicians",
    "Other, please specify"
  ];

  const newProfession = otherProfession ? otherProfession : profession;

  const handleSubmit = () => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}user/profile/profession`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
      data: {
        profession: newProfession
      }
    }).then(res => {
      toggleExpanded();
      setUserInfo({ ...userInfo, profession: newProfession });
    }).catch(err => {
      console.log(err);
    });
  }

  const renderProfessionMenu = () => {

    const handleChange = (event: any) => {
      setProfession(event.target.value);
      setOtherProfession('');
    };

    return (
      <FormControl className={classes.input} >
        <InputLabel id="profession-select-label" style={{ marginLeft: '15px' }}>Profession/Sector *</InputLabel>
        <Select
          labelId="profession-select-label"
          id="profession-select"
          value={profession}
          onChange={handleChange}
          variant="outlined"
          label="Profession/Sector *"
          required
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
            className: classes.menu,
          }}
        >

          {menuOptions.map((option: string, index: number) =>
            <MenuItem key={index} value={option}>{option}</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  }

  return (
    <Card className={classes.card}>
      <CardHeader title={<CardTitle title="Profession/Sector" button={!expanded} onClick={toggleExpanded} />} disableTypography style={{ paddingBottom: '0px' }} />
      {!expanded &&
        <CardContent>
          <Typography className={classes.cardText}>
            {menuOptions.includes(userInfo.profession) ? userInfo.profession : `Other - ${userInfo.profession}`}
          </Typography>
        </CardContent>}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {renderProfessionMenu()}
          {profession == "Other, please specify" && <TextField className={classes.input} label="Profession / Sector - Other" variant="outlined" defaultValue={otherProfession}
            onChange={(event) => setOtherProfession(event.target.value)} />
          }
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={toggleExpanded} size="small" variant="text" className={classes.cancelButton}>
            Cancel
          </Button>
          <Button variant="contained" size="small" className={classes.saveButton}
            onClick={handleSubmit}
            disabled={!profession || (profession == "Other, please specify" && !otherProfession)}>
            Save
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  )
}

function PasswordCard(props: any) {
  const classes = useStyles();
  const { expanded, setExpanded, userInfo } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    setError('');
    if (expanded) {
      setResult('');
    }
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = () => {
    setResult('');
    setError('');

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match.");
      return;
    }

    const regEx: RegExp = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}/;
    const isPasswordValid = newPassword !== '' && newPassword !== null && regEx.test(newPassword);

    if (!isPasswordValid) {
      setError("Password must be at least 8 characters long, contain at least one uppercase letter, one number and one special character.");
      return;
    }

    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}user/profile/password`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
      data: {
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    }).then(res => {
      console.log('res :>> ', res);

      // need to get a new access token
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}user/login`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        },
        data: {
          email: userInfo.email,
          password: newPassword,
        },
      }).then(res => {
        sessionStorage.setItem('token', res.data.accessToken);
        toggleExpanded();
        setResult("Password updated successfully.");
      }).catch(err => {
        setError("Your old password is incorrect.");
        console.log(err);
      });

    }).catch(err => {
      setError("Your old password is incorrect.");
      console.log(err);
    });
  }

  return (
    <Card className={classes.card}>
      <CardHeader title={<CardTitle title="Password" button={!expanded} onClick={toggleExpanded} />} disableTypography style={{ paddingBottom: '0px' }} />
      {!expanded &&
        <CardContent>
          <Typography className={classes.cardText}>
            **********
          </Typography>
          <Typography className={classes.resultMessage} component="h1" variant="h6">
            {result}
          </Typography>
        </CardContent>
      }

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <TextField className={classes.input} label="Current Password" variant="outlined"
            onChange={(event) => setOldPassword(event.target.value)}
            type={showOldPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <FontAwesomeIcon icon={showOldPassword ? faEye as IconProp : faEyeSlash as IconProp} onClick={toggleShowOldPassword} style={{ cursor: 'pointer' }} />
              )
            }}
          />

          <TextField className={classes.input} label="New Password" variant="outlined"
            onChange={(event) => setNewPassword(event.target.value)}
            type={showNewPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <FontAwesomeIcon icon={showNewPassword ? faEye as IconProp : faEyeSlash as IconProp} onClick={toggleShowNewPassword} style={{ cursor: 'pointer' }} />
              )
            }}
          />

          <TextField className={classes.input} label="Confirm Password" variant="outlined"
            onChange={(event) => setConfirmPassword(event.target.value)}
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <FontAwesomeIcon icon={showConfirmPassword ? faEye as IconProp : faEyeSlash as IconProp} onClick={toggleShowConfirmPassword} style={{ cursor: 'pointer' }} />
              )
            }}
          />

          <Typography className={classes.resultMessage} component="h1" variant="h6">
            {result}
          </Typography>
          <Typography className={classes.errorMessage} component="h1" variant="h6">
            {error}
          </Typography>

        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={toggleExpanded} size="small" variant="text" className={classes.cancelButton}>
            Cancel
          </Button>
          <Button variant="contained" size="small" className={classes.saveButton}
            onClick={handleSubmit}
            disabled={!oldPassword || !newPassword || !confirmPassword}>
            Save
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  )
}

function CardTitle(props: any) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Typography style={{ fontWeight: "bold", color: '#0046AD', fontSize: 24 }}>
        {props.title}
      </Typography>
      {props.button &&
        <a href="#" onClick={props.onClick} style={{ textTransform: "none", textDecorationLine: "underline", color: "#000" }}> Edit </a>
      }
    </div>
  )
}

export default ProfilePage;