import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Goals from './Goal';
import { Button } from '@material-ui/core';

const Content = (props:any) => {

  
    const [showGoal, setShowGoal] = useState(false);

    const [displayShowGoal, setDisplayShowGoal] = useState("Show Goal")
    const goalItem=[{id:'',title:'',details:'',suggestedAge:''}];
    const [goalDetails, setGoalDetails] = useState(goalItem);
    const [goalDetails1, setGoalDetails1] = useState(goalItem);
   


    const handleOnShowGoal = (id:any) => {
        setShowGoal(!showGoal)
        if (!showGoal) {
            setDisplayShowGoal("Hide Goal")
            axios.get(`${process.env.REACT_APP_API_URL}/catalog/strategies/${props.domain}/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'api_key': process.env.REACT_APP_API_KEY
                }
            }).then((response) => {
                setGoalDetails1(response.data.children)
                console.log(response.data)
            });
        }
        else if (showGoal) {
            setDisplayShowGoal("Show Goal")
        }
        console.log(goalDetails);
            
    }

    useEffect(() => {
        console.log(props.content.id)
        axios.get(`${process.env.REACT_APP_API_URL}/catalog/strategies/${props.domain}/${props.content.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'api_key': process.env.REACT_APP_API_KEY
            }
        }).then((response) => {
            setGoalDetails(response.data.children)
            console.log(response.data)
        });
    }, [])


    return (
        
        <div>
            <div className='content-border'>
                <div className='page-title'>{props.content.details}</div>
                <div className='title-bold'>Overall Outcome:</div>
                <div className='content-grid'>
                    <span className='overall-outcome'>{goalDetails && goalDetails[0] && goalDetails[0].details}</span>
                    <span className='show-goal align-right' ><Button style={{color:'#0046AD'}} onClick={() => handleOnShowGoal(goalDetails[0].id)}>{displayShowGoal}</Button></span>
                </div>
            </div>
            {
            <div style={{marginLeft:'20px'}}>
                {showGoal && goalDetails1[0].id ? goalDetails1.map((gd) => {
                    return (<Goals key={gd.id} goalTitle={gd.title} goalContent={gd.details} id={gd.id} suggestedAge={gd.suggestedAge} domain={props.domain}/>)
                }) : null}
            </div>
            }
        </div>

    )
}

export default Content