/* eslint-disable no-unused-vars */
// TypeScript issue with sliding transition props
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Grid,
    Paper,
    Button,
    Typography,
    Box
} from '@material-ui/core';
import DSPBreadCrums from './DSPCreation/DSPBreadCrums';
import NTS_Communication from '../../../img/NTS_Communication.png';
import NTS_SocialEmotional from '../../../img/NTS_SocialEmotional.png';
import NTS_GrossMotor from '../../../img/NTS_GrossMotor.png';
import NTS_FineMotor from '../../../img/NTS_FineMotor.png';
import NTS_ProblemSolving from '../../../img/NTS_ProblemSolving.png';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    DSPCard: {
        width: "90%",
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '16px',
        paddingBottom: '16px',
        marginBottom: '3%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
    },
    root: {
        margin: '0 auto',
        display: 'block',
        height: 'unset',
    },
    instructions: {
        color: '#000000',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        alignSelf: 'flex-start',
        fontFamily: 'Arial',
        marginTop: '10px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'flex-start',
            fontSize: '1.1rem',
        },
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column-reverse",
        }
    },
    backButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            justifyContent: 'center',
        },
    },
    saveButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            justifyContent: 'center',
        },
    },
    backButton: {
        borderRadius: '8px',
        borderWidth: '2px',
        width: '160px',
        borderColor: "#0046AD",
        marginBottom: '10px',
        color: "#0046AD",
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '70%',
        },
    },
    saveButton: {
        borderRadius: '8px',
        width: '160px',
        backgroundColor: '#0046AD',
        marginBottom: '10px',
        color: "#FFFFFF",
        fontSize: '18px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            width: '70%',
        },
        '&:disabled': {
            background: 'gray',
            color: '#FFFFFF',
        }
    },
    titleBox: {
        marginTop: '5px',
        marginBottom: '1.5rem',
        width: '100%',
    },
    strategyContainer: {
        display: "flex",
        width: '100%',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    domainTitleBox: {
        borderRadius: '10px',
        marginBottom: '2%',
        paddingLeft: 'min(4%, 20px)',
        paddingRight: 'min(4%, 20px)',
        paddingTop: 'min(4%, 10px)',
        paddingBottom: 'min(4%, 10px)',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        border: '3px solid',
        justifyContent: 'flex-start',
    },
    strategyCard: {
        borderRadius: '10px',
        marginBottom: 'min(3%, 10px)',
        marginTop: 'min(3%, 10px)',
        padding: 'min(6%, 20px)',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
    },
    selectedStrategyCard: {
        borderRadius: '10px',
        outlineColor: '#0046AD',
        outlineWidth: '1px',
        outlineStyle: 'solid',
        color: '#0046AD',
    },
}));

interface DomainItem {
    title: string;
    imgUrl: string,
    description: string,
    color: string,
}

interface SummaryItem {
    goal: string,
    strategies: string[],
    selectedStrategyIndex: number | null,
}

const domains: { [domain: string]: DomainItem } = {
    'communication': {
        title: 'Communication',
        imgUrl: NTS_Communication,
        description: 'How can I understand you and talk to others?',
        color: '#62A0C9',
        prefix: 'com'
    },
    'grossMotor': {
        title: 'Gross Motor',
        imgUrl: NTS_GrossMotor,
        description: 'How can I move my body?',
        color: '#5A595C',
        prefix: 'gro',
    },
    'fineMotor': {
        title: 'Fine Motor',
        imgUrl: NTS_FineMotor,
        description: 'How I use my hands?',
        color: '#E37225',
        prefix: 'fin',
    },
    'problemSolving': {
        title: 'Problem Solving',
        imgUrl: NTS_ProblemSolving,
        description: 'How can I explore and figure things out?',
        color: '#156671',
        prefix: 'pro',
    },
    'socialEmotional': {
        title: 'Social Emotional',
        imgUrl: NTS_SocialEmotional,
        description: 'How can I show my feelings and act around othes?',
        color: '#Df4461',
        prefix: 'soc',
    }
}

function DSPFridgePage(props: any): JSX.Element {
    const classes = useStyles();

    const { setSession } = props;
    const [summaryData, setSummaryData] = useState<{[domain: string]: SummaryItem}>({});
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        const data: { [domain: string]: SummaryItem } = {};

        Object.keys(domains).forEach(domain => {
            if (sessionStorage.getItem(domain) === 'completed') {
                const prefix = domains[domain].prefix;
                let step2 = sessionStorage.getItem(`${prefix}_step2_value`);
                step2 = JSON.parse(step2);

                let step3 = sessionStorage.getItem(`${prefix}_step3_value`);
                step3 = JSON.parse(step3);

                data[domain] = {
                    goal: step2.level4,
                    strategies: step3.level5,
                    selectedStrategyIndex: null,
                }
            }
        });

        setSummaryData(data);
    }, []);

    useEffect(() => {
        let disabled = false;
        Object.keys(summaryData).forEach(domain => {
            if (summaryData[domain].selectedStrategyIndex == null) {
                disabled = true;
            }
        })
        setIsDisabled(disabled);
    }, [summaryData])

    const handleBack = (event: any) => {
        //if (window.confirm('Are you sure you want to leave? Save your DSP locally to keep your progress.')) {
            setSession('main');
        //}
    }

    const handleNext = (event: any) => {
        const savedObj = {};
        Object.keys(summaryData).forEach((domain) => {
            const data = summaryData[domain];
            savedObj[domain] = data.strategies[data.selectedStrategyIndex];
        });
        sessionStorage.setItem(`fridge_value`,JSON.stringify(savedObj));
        sessionStorage.setItem('fridge', 'completed');
        setSession('main');
    }

    return (

        <Grid container item xs={11} className={classes.root}>

            <DSPBreadCrums
                setSession={props.setSession}
                session={props.session}
                setExpandMenu={props.setExpandMenu}
                items={[
                    { name: 'Create New Plan', link: 'main' },
                    { name: 'Fridge Page', link: 'fridge' }
                ]}
            />
            <Box
                className={classes.titleBox}
                display={{ xs: 'none', md: 'block' }}
            >
                <Typography
                    variant={'h4'}
                    style={{ color: '#0046AD', fontWeight: 'bold' }}
                >
                    Fridge Page
                </Typography>
            </Box>


            <SummaryCard summaryData={summaryData} setSummaryData={setSummaryData}/>

            <Grid container className={classes.buttonContainer}>
                <Grid item xs={12} md={6} className={classes.backButtonGrid}>
                    <Button variant='outlined' className={classes.backButton} onClick={handleBack}>
                        Back
                    </Button>
                </Grid>

                <Grid item xs={12} md={6} className={classes.saveButtonGrid} >
                    <Button variant='contained' className={classes.saveButton} onClick={handleNext} disabled={isDisabled}>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}


function SummaryCard(props: any) {
    const classes = useStyles();

    const { summaryData, setSummaryData } = props;

    const handleClickStrategy = (domain: string, index: number) => {
        const newSummaryData = { ...summaryData };
        newSummaryData[domain].selectedStrategyIndex = index;
        setSummaryData(newSummaryData);
    }
    
    const renderStrategies = (domain: string, data: SummaryItem) => {

        const { strategies, selectedStrategyIndex } = data;

        return (
            <div>
                {strategies.map((strategy, index) => {
                    const isSelected = selectedStrategyIndex === index;
                    return (
                        <Paper key={strategy} className={isSelected? classNames(classes.strategyCard, classes.selectedStrategyCard) : classes.strategyCard} onClick={() => handleClickStrategy(domain, index)}>
                            {strategy}
                        </Paper>
                    )
                })}
            </div>
        )
    }

    const renderContent = () => {

        return (
            <div className={classes.strategyContainer}>
                {
                        Object.keys(summaryData).map((domain) => {
                            return (
                                <div key={domain} >
                                    <Paper className={classes.domainTitleBox} style={{ borderColor: domains[domain].color }} elevation={0}>
                                        <img src={domains[domain].imgUrl} style={{ width: '76px', height: '76px', alignSelf: 'center' }} />
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 10px', justifyContent: 'space-evenly' }}>
                                            <div style={{ fontWeight: 700 }}>
                                                {domains[domain].title}
                                            </div>
                                            {domains[domain].description}
                                        </div>
                                    </Paper>

                                    <div style={{margin: '8px 0px'}}>
                                        Goal: {summaryData[domain].goal}
                                    </div>

                                    {renderStrategies(domain, summaryData[domain])}
                                </div>
                            )
                        })}
            </div>
        )
    }

    return (
        <Paper elevation={0} className={classes.DSPCard}>
            <Typography
                variant="h4" className={classes.instructions}>
                Pick one strategy from each domain.
            </Typography>

            {renderContent()}
        </Paper>
    )
}


export default DSPFridgePage;