/* eslint-disable no-unused-vars */
import {
	Button,
	List,
	ListItem,
	makeStyles,
	Typography
} from '@material-ui/core';
import React, {
	useEffect,
	useState
} from 'react'


const useStyles = makeStyles((theme) => ({
	dialog: {
		width: '100%',
	},
	ageItem: {
		background: 'white',
		color: 'black',
		marginBottom: '20px',
		padding: '10px',
		cursor: 'unset',
		'& p': {
			fontSize: '17px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
			}
		},
	},
	age: {
		color: '#727679',
		fontSize: '1.2rem',
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px',
		}
	},
	selectBtn: {
		color: '#009B74',
		textDecoration: 'underline',
		fontSize: '1rem',
		fontWeight: 'bold'
	},
	backBtn: {
		color: '#0046AD',
		borderColor: '#0046AD',
		borderRadius: '10px'
	},
}));

interface Props {
	title: string,
	content: string,
	icon: any,
}

export default function RelationDialog(props: any): JSX.Element {
	const classes = useStyles();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	useEffect(() => {
		setIsDialogOpen(props.isOpen > 0);
	}, [props.isOpen]);


	return (
		<>
			<List style={{ maxHeight: '100%', overflow: 'auto', margin: '0 auto' }}>
				{/* TODO: pass data to parent */}
				{props.data.map(({ title, content, icon }: Props, index: number) => {
					return (
						<ListItem
							className={classes.ageItem}
							button
							style={{
								display: 'flex',
								width: '100%',
								flexDirection: 'column'
							}}
						>
							{/* <Grid container sm={12}>
								<Grid item sm={2} style={{ position: "relative" }}>
									<img src={icon} style={{ fontSize: '50px', color: '#707070', top: '40%', }} className={classes.age} />
									<Typography style={{ top: '60%', }} variant="h6" className={classes.age}>{title}</Typography>
								</Grid>
								<Grid item sm={10}>
									<Typography>{content}</Typography>
								</Grid>
							</Grid> */}
							<Typography>{content}</Typography>
							<div
								style={{
									display: 'flex',
									width: '100%',
									justifyContent: 'space-between',
									margin: '1rem 0 0.5rem'
								}}
							>
								<Typography
									variant="h6"
									className={classes.age}
								>
									Suggested Age: {title}
							</Typography>
								<Button
									variant="text"
									onClick={() => props.handleClick(index)}
									className={classes.selectBtn}
								>
									Select
								</Button>
							</div>
						</ListItem>
					)
				})}
			</List>
			<div style={{ marginBottom: '2em' }}>

				<Button
					variant="outlined"
					className={classes.backBtn}
					onClick={() => { props.onClose() }}
				>
					Back
				</Button>
			</div>
		</>
	);
}