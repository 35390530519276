import React from "react";
import { AdminProvider } from "./AdminProvider";
import AdminCenter from "./AdminCenter";


export const AdminBoard=()=>{
    
    return (
        <AdminProvider>
            <AdminCenter/>
        </AdminProvider>
    );
}