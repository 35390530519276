/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import ImportantContent from './ImportantContent';
import { Button } from '@material-ui/core';

const Goals = (props:any) => {
    const history=useHistory();
    const benefitItem={children:[{id:'',title:'',details:'',suggestedAge:''}]};
   
    const strategyItem=[{id:'',title:'',details:''}];
    const [strategies, setStrategies] = useState(strategyItem)
    const [showStrategies, setShowStrategies] = useState(false);
    const [importantLists, setImportantLists] = useState(benefitItem);
    const [displayButton,setDisplayButton]=useState("View Strategies");

   
    useEffect(() => {
        //console.log(`${process.env.REACT_APP_API_URL}/catalog/strategies/${props.domain}/${props.id}`)
        axios.get(`${process.env.REACT_APP_API_URL}/catalog/strategies/${props.domain}/${props.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'api_key':  process.env.REACT_APP_API_KEY
            }
        }).then((response) => {
            setStrategies(response.data.children)
            //console.log(response.data)
        });
    }, [])

  
    const handleOnClick = (id:any,title:any) => {
        //console.log(strategies);
        setShowStrategies(!showStrategies)
        if (!showStrategies) {
            setDisplayButton("Hide Strategies")
        }
        else {
            setDisplayButton("View Strategies")
        }
       

    }


                return (
                    <div>
                        <div className='content-border' key={props.id}>
                            <div className='title-bold'>{props.goalTitle}</div>
                            <div className='content-grid'><span>{props.goalContent}</span>
                                <span className='show-goal align-right'><Button style={{color:'#0046AD'}} onClick={() => handleOnClick(props.id,props.title)}>{displayButton}</Button></span>                      
                            </div>                 
                        </div>
                        {
                            <div style={{marginLeft:'20px'}}>
                                {showStrategies && strategies[0].id ? strategies.map((gd) => {
                                    return (<ImportantContent key={gd.id} title={gd.title} details={gd.details} id={gd.id} suggestedAge={11} domain={props.domain}/>)
                                }):null}
                            </div>
                            }
                    </div>
                        
                )
          


       
}

export default Goals;