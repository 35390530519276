import React, {useState, useRef} from 'react';
import {
  makeStyles,
  Box,
} from '@material-ui/core';
import EmptyPFPImage from '../../../img/empty_pfp.svg';
import { Tooltip } from 'react-tooltip'

const useStyles = makeStyles(() => ({
  PFPBox: {
    position: 'relative',
    borderRadius: '100%',
    borderWidth: '0px',
    height: '100px',
    width: '100px',
    margin: "17px 0px 12px 0px",
  },
  PFP: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderWidth: '5px',
    zIndex: 2,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  PFPDisabled: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderWidth: '5px',
    zIndex: 2,
    borderRadius: '50%',
    cursor: 'unset',
  },
  input: {
    display: 'none',
    borderRadius: '25%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
  },
  uploadText: {
    color: '#009B74',
    textDecorationLine: 'underline',
    fontWeight: 700,
    fontSize: '1.12rem',
    cursor: 'pointer',
  }
}));

//still needs more work on resizing and cropping
function PFPBox(props: any): JSX.Element {
  const classes = useStyles();
  const {handleUpload, defaultImage, disabled} = props;
 
  
  const [uploadedPFP, setuploadedPFP] = useState(defaultImage? defaultImage : EmptyPFPImage);
  


  const inputFile = useRef<HTMLInputElement>(null) ;
  const handlePFPUploadClick = (event: any) => {
   
    inputFile?.current?.click();
    if (event.target.files && event.target.files[0]) {
      setuploadedPFP(URL.createObjectURL(event.target.files[0]));
     
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        handleUpload(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      }
      
    }
  };

  return (
    <Box className={classes.root} data-tooltip-id="tooltip-upload" data-tooltip-content="Please ensure the photo you are uploading is in PNG format and does not exceed the maximum size limit of 1 MB.">
     
      <div className={classes.PFPBox} >
      
        <label htmlFor="contained-pfp-upload">
          {defaultImage?<img
            width="100%"
            className={disabled? classes.PFPDisabled : classes.PFP}
            src={defaultImage}
          />: <img
          width="100%"
          className={disabled? classes.PFPDisabled : classes.PFP}
          src={uploadedPFP}
        />}
         
          <input
            accept="image/*"
            className={classes.input}
            id="contained-pfp-upload"
            type="file"
            onChange={handlePFPUploadClick}
            disabled={disabled}
            ref={inputFile}
          />
        </label>
      </div>
      {!disabled &&<>
        <a className={classes.uploadText} onClick={handlePFPUploadClick} >
          Upload Photo
        </a>
        
        </>
      }
      <Tooltip id="tooltip-upload" place="bottom" style={{opacity:1,backgroundColor:'black !important',color:'#FFFFFF !important',fontSize: '1rem',marginLeft:'30px',width:'450px'}} />
     
    </Box>

  )
}

export default PFPBox;