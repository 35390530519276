import React from "react";

function YouTubeVideo() {
  return (
    <div>
      <iframe
        width="600" height="345" 
        src="https://www.youtube.com/embed/Uk0mg10zwRQ?si=Z0b-Wu6IQXarj87v"
        title="Introduction To WiseGPT"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default YouTubeVideo;