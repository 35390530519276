/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Dialog, DialogTitle, DialogContent, Button,
  DialogActions, FormGroup, FormControlLabel, Checkbox, Hidden
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


import { CoverPagePreview, RelationshipMattersPreview, DomainPreview, FridgePagePreview } from "./DSPPreview/MainModal";

//import icons for plan 3
import NTS_Communication from '../../../img/NTS_Communication.png';
import NTS_SocialEmotional from '../../../img/NTS_SocialEmotional.png';
import NTS_GrossMotor from '../../../img/NTS_GrossMotor.png';
import NTS_FineMotor from '../../../img/NTS_FineMotor.png';
import NTS_ProblemSolving from '../../../img/NTS_ProblemSolving.png';

// color leaves
import SmallBlueLeaf from '../../../img/small_blue_leaf.png';
import SmallGrayLeaf from '../../../img/small_gray_leaf.png';
import SmallGreenLeaf from '../../../img/small_green_leaf.png';
import SmallOrangeLeaf from '../../../img/small_orange_leaf.png';
import SmallRedLeaf from '../../../img/small_red_leaf.png';

//pdf
import axios from "axios";
import CommunicationPDFForm from "../../../img/pdfs/Breakdown pages/CommunicationPDFForm.pdf";
import FineMotorPDFForm from "../../../img/pdfs/Breakdown pages/FineMotorPDFForm.pdf";
import GrossMotorPDFForm from "../../../img/pdfs/Breakdown pages/GrossMotorPDFForm.pdf";
import ProblemSolvingPDFForm from "../../../img/pdfs/Breakdown pages/ProblemSolvingPDFForm.pdf";
import SocialEmotionalPDFForm from "../../../img/pdfs/Breakdown pages/SocialEmotionalPDFForm.pdf";
import CoverPagePDFForm from "../../../img/pdfs/Breakdown pages/CoverPagePDFForm.pdf";
import RelationshipMatterPDFForm from "../../../img/pdfs/Breakdown pages/RelationshipMatterPDFForm.pdf";

//import FridgePagePDFForm from "../../../img/pdfs/Breakdown pages/FridgePagePDFForm_font11.pdf";
import FridgePagePDFForm from "../../../img/pdfs/Breakdown pages/FridgePagePDFForm_font10.pdf";

//import FridgePagePDFForm from "../../../img/DSP_FridgePage.pdf";
import { PDFDocument } from 'pdf-lib'

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3%',
    borderRadius: '10px',
    width: '80%',
    maxHeight: '80%',
    minHeight: '50%',
  },
  root: {
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
    '& .MuiTypography-h6': {
      fontSize: '2rem',
      lineHeight: 1.3,
      "@media (max-width: 600px)": {
        fontSize: '1.3rem',
      },
      fontWeight: 'bold',
      color: '#0046AD'
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    borderRadius: '8px',
    width: '120px',
    backgroundColor: '#0046AD',
    marginTop: '10px',
    marginBottom: '5%',
    color: "#FFFFFF",
    fontSize: '20px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textTransform: 'none',
    "@media (max-width: 600px)": {
      fontSize: '12px',
      width: '100px',
    },
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    },
    '&:disabled': {
      background: 'gray',
      color: '#FFFFFF',
    }
  },
  checkbox: {
    '& .MuiSvgIcon-root': {
      color: '#009B74',
    },
    color: '#009B74',
  },
  disabledCheckbox: {
    color: 'gray'
  },
  formLabel: {
    '& .MuiTypography-root': {
      color: 'unset',
    },
    '& .MuiTypography-body1': {
      "@media (max-width: 600px)": {
        fontSize: '12px',
      },
      fontSize: '1.2rem',
      textAlign: 'start',
    },
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '85%',
  },
  hidden_preview_section: {
    position: 'absolute',
    top: '-10000px',
    '& .big_green_leaf': {
      width: '30%',
    },
    '& .info': {
      width: '600px',

    },
    '& .info_table': {
      width: '100%'
    }

  }
}));

interface Page {
  title: string,
  name?: string,
  checked: boolean,
  disabled: boolean,
  renderComponent: JSX.Element,
  domainPrefix?: string,
  pdfPrefix?: string
}

export default function ExportDialog(props: any): JSX.Element {
  const dialogClasses = useDialogStyles();
  const classes = useStyles();

  const { open, toggleOpen } = props;

  const [pages, setPages] = useState<Page[]>([]);
  const [exportAll, setExportAll] = useState(true)

  useEffect(() => {
    const allPages = [];
    allPages.push(
      {
        title: "Cover Page",
        name: "coverPage",
        checked: true,
        disabled: true,
        renderComponent: <CoverPagePreview />
      }
    );
    allPages.push(
      {
        title: "Why our relationship matters",
        name: "relationship",
        checked: true,
        disabled: true,
        renderComponent: <RelationshipMattersPreview />
      }
    );

    if (sessionStorage.getItem('communication') === 'completed') {
      allPages.push(
        {
          title: "DSP - How I Can Understand You and Talk to Others",
          name: 'communication',
          checked: true,
          disabled: true,
          domainPrefix: 'com',
          pdfPrefix: 'Communication',
          renderComponent: <DomainPreview domainName='Communication' domainPrefix='com' imgSrc={NTS_Communication} footerImg={SmallBlueLeaf} pageNum='2' />
        }
      );
    }
    if (sessionStorage.getItem('grossMotor') === 'completed') {
      allPages.push(
        {
          title: "DSP - How I Move My Body",
          name: "grossMotor",
          checked: true,
          disabled: true,
          domainPrefix: 'gro',
          pdfPrefix: 'Gross Motor',
          renderComponent: <DomainPreview domainName='GrossMotor' domainPrefix='gro' imgSrc={NTS_GrossMotor} footerImg={SmallGrayLeaf} pageNum='3' />
        }
      );
    }
    if (sessionStorage.getItem('fineMotor') === 'completed') {
      allPages.push(
        {
          title: "DSP - How I Ise My Hands",
          name: "fineMotor",
          checked: true,
          disabled: true,
          pdfPrefix: 'Fine Motor',
          domainPrefix: 'fin',
          renderComponent: <DomainPreview domainName='FineMotor' domainPrefix='fin' imgSrc={NTS_FineMotor} footerImg={SmallOrangeLeaf} pageNum='4' />
        }
      );
    }
    if (sessionStorage.getItem('problemSolving') === 'completed') {
      allPages.push(
        {
          title: "DSP - How I Explore and Figure Things Out",
          name: "problemSolving",
          checked: true,
          disabled: true,
          domainPrefix: 'pro',
          pdfPrefix: 'Problem Solving',
          renderComponent: <DomainPreview domainName='ProblemSolving' domainPrefix='pro' imgSrc={NTS_ProblemSolving} footerImg={SmallGreenLeaf} pageNum='5' />
        }
      );
    }
    if (sessionStorage.getItem('socialEmotional') === 'completed') {
      allPages.push(
        {
          title: "DSP - How I Show My Feelings and Act Around Others",
          name: "socialEmotional",
          checked: true,
          disabled: true,
          domainPrefix: 'soc',
          pdfPrefix: 'Social Emotional',
          renderComponent: <DomainPreview domainName='SocialEmotional' domainPrefix='soc' imgSrc={NTS_SocialEmotional} footerImg={SmallRedLeaf} pageNum='6' />
        }
      );
    }

    if (sessionStorage.getItem('fridge') === 'completed') {
      allPages.push(
        {
          title: "Fridge Page",
          name: "fridge",
          checked: true,
          disabled: true,
          renderComponent: <FridgePagePreview />
        }
      );
    }

    setPages(allPages);
  }, [open])

  // const handleSubmit = () => {

  //   (async function loop() {
  //     const divToPrint = document.getElementsByClassName('info');
  //     let pagesDemo = [].slice.call(divToPrint) as HTMLElement[];
  //     const doc = new jsPDF('p', 'mm', 'a4');
  //     console.log({ pagesDemo });

  //     for (let i = 0; i < pagesDemo.length; i++) {
  //       let positionInfo = pagesDemo[i].getBoundingClientRect();
  //       let divHeight = positionInfo.height;
  //       let divWidth = positionInfo.width;
  //       let ratio = divHeight / divWidth;
  //       //console.log({ divHeight }, { divWidth }, { ratio });
  //       await new Promise<void>(function (resolve) {
  //         // let w = 1200;
  //         // let h = 1700;

  //         // let canvas = document.createElement('canvas');
  //         // canvas.width = w * 2;
  //         // canvas.height = h * 2;
  //         // canvas.style.width = w + 'px';
  //         // canvas.style.height = h + 'px';
  //         // let context: any = canvas.getContext('2d');
  //         // context.scale(2, 2);
  //         html2canvas(pagesDemo[i], { scale: 2 }).then(function (canvas) {

  //           let image = canvas.toDataURL('image/jpeg', 1.0);
  //           let pageWidth = doc.internal.pageSize.getWidth();
  //           let pageHeight = doc.internal.pageSize.getHeight();

  //           let widthRatio = pageWidth / canvas.width;
  //           let heightRatio = pageHeight / canvas.height;
  //           let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

  //           let canvasWidth = canvas.width * ratio;
  //           //canvasWidth = canvasWidth < 190 ? canvasWidth : 190
  //           let canvasHeight = canvas.height * ratio;
  //           console.log({ pageWidth }, { ratio }, { canvasWidth }, { canvasHeight });

  //           let marginX = (pageWidth - canvasWidth) / 2;
  //           let marginY = (pageHeight - canvasHeight) / 2;


  //           doc.addImage(image, 'JPEG', 10, 0, 190, canvasHeight);

  //           // this way will generate a pdf with pages in different size, but it will look better
  //           //doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);

  //           if ((i + 1) == pagesDemo.length) {
  //             doc.save(`${sessionStorage.getItem('childName')!}.pdf`);

  //             let out = doc.output();
  //             let pdfBase64 = 'data:application/pdf;base64,' + btoa(out);

  //             // test by showing pdfBase64
  //             /*let iframe = "<iframe width='100%' height='100%' src='" + pdfBase64 + "'></iframe>"
  //             let x = window.open();
  //             x!.document.open();
  //             x!.document.write(iframe);
  //             x!.document.close();
  //             document.location.href = pdfBase64;*/

  //             console.log({pdfBase64});
  //           } else {
  //             doc.addPage();
  //           }
  //           resolve();
  //         });
  //       });
  //     }
  //   })();
  //   toggleOpen();
  // }


  const exportCoverPagePDF = async (mergedPdf: PDFDocument): Promise<PDFDocument> => {
    let formPdfBytes = await axios.get(CoverPagePDFForm, {
      responseType: "arraybuffer",
    });

    let pdfDoc = await PDFDocument.load(formPdfBytes.data);

    const form = pdfDoc.getForm();
    let picBase64=sessionStorage.getItem('childProfilePicture');
    let childPhoto = form.getButton("Child's Photo_af_image");
    //if(picBase64?.includes("image/png"))
      childPhoto.setImage(await pdfDoc.embedPng(picBase64!))
    /*else
      childPhoto.setImage(await pdfDoc.embedJpg(picBase64!))*/


    let nameField1 = form.getTextField("Cover Page_Child's Name");
    nameField1.setText(sessionStorage.getItem(`childName`)!);

    let nameField2 = form.getTextField("Cover Page_Childs Age");
    nameField2.setText(sessionStorage.getItem('age')!);

    let nameField3 = form.getTextField("Cover Page_Plan Creator(s)");
    nameField3.setText(sessionStorage.getItem('creatorList')!);

    let nameField4 = form.getTextField("Cover Page_Creation Date_af_date");
    let cDate=sessionStorage.getItem('creationDate')!.substring(4).split(" ");
  switch(cDate[0].toLowerCase()){
    case 'jan':cDate[0]="January";break;
    case 'feb':cDate[0]="February";break;
    case 'mar':cDate[0]="March";break;
    case 'apr':cDate[0]="April";break;
    case 'may':cDate[0]="May";break;
    case 'jun':cDate[0]="June";break;
    case 'jul':cDate[0]="July";break;
    case 'aug':cDate[0]="August";break;
    case 'sep':cDate[0]="September";break;
    case 'oct':cDate[0]="October";break;
    case 'nov':cDate[0]="November";break;
    case 'dec':cDate[0]="December";break;
  }
   
  nameField4.setText(`${cDate[0]} ${cDate[1]}, ${cDate[2]}`);

    let nameField5 = form.getTextField("Child's Additional Information");
    nameField5.setFontSize(18);
    nameField5.setText(sessionStorage.getItem('childAdditionalInformation')!);

    // const copiedPagesA = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());

    // copiedPagesA.forEach((page) => mergedPdf.addPage(page));
    form.flatten();
    await pdfDoc.save()
    return pdfDoc;
  }

  const exportDomianPagePDF = async (mergedPdf: PDFDocument, domainPrefix: string, pdfPrefix: string): Promise<PDFDocument> => {
    let step3_storedObj: any = sessionStorage.getItem(`${domainPrefix}_step3_value`);
    // console.log({step3_storedObj});
    let step3_list: string[] = JSON.parse(step3_storedObj).level5 as string[]

    let step2_storedObj = sessionStorage.getItem(`${domainPrefix}_step2_value`)!;
    let step2_value: string = JSON.parse(step2_storedObj).level4 as string

    let step4_storedObj = sessionStorage.getItem(`${domainPrefix}_step4_value`)!;
    let step4_value: string = JSON.parse(step4_storedObj).level6 as string


    let formPdfBytes;
    switch (domainPrefix) {
      case 'com':
        formPdfBytes = await axios.get(CommunicationPDFForm, {
          responseType: "arraybuffer",
        });
        break;
      case 'gro':
        formPdfBytes = await axios.get(GrossMotorPDFForm, {
          responseType: "arraybuffer",
        });
        break;
      case 'fin':
        formPdfBytes = await axios.get(FineMotorPDFForm, {
          responseType: "arraybuffer",
        });
        break;
      case 'pro':
        formPdfBytes = await axios.get(ProblemSolvingPDFForm, {
          responseType: "arraybuffer",
        });
        break;
      case 'soc':
        formPdfBytes = await axios.get(SocialEmotionalPDFForm, {
          responseType: "arraybuffer",
        });
        break;
      default:
        break;
    }


    console.log({ formPdfBytes });
    let pdfDoc = await PDFDocument.load(formPdfBytes?.data);

    const form = pdfDoc.getForm();
    //const fields = form.getFields()
    // fields.forEach(field => {
    //   const type = field.constructor.name
    //   const name = field.getName()
    //   console.log(`${type}: ${name}`)
    // })

    let nameField1 = form.getTextField(`${pdfPrefix}_What I can already do`);
    nameField1.setFontSize(11);
    nameField1.setText(sessionStorage.getItem(`${domainPrefix}_step1_value`)!);

    let nameField2 = form.getTextField(`${pdfPrefix}_My next goal`);
    nameField2.setFontSize(11)
    nameField2.setText(step2_value);


    for (let i = 0; i < 3; i++) {
      let nameField3 = form.getTextField(`${pdfPrefix}_Strategy ${i + 1}`);
      // You can use setFontSize to set the font size base on the length of the text. For ex:
      // if(step3_list[i].length < 300){
        
      // }

      nameField3.setFontSize(11)

      nameField3.setText(step3_list[i]);
    }

    let nameField4 = form.getTextField(`${pdfPrefix}_Why this is important to me`);
    console.log("length: ",step4_value.length);
    if(step4_value.length < 500){
      console.log({pdfPrefix})
      nameField4.setFontSize(11)
    }
    nameField4.setText(step4_value);
    // const copiedPagesA = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());

    // copiedPagesA.forEach((page) => mergedPdf.addPage(page));
    form.flatten();
    await pdfDoc.save()
    return pdfDoc
  }
  // const exportFridgePagePDF = async (): Promise<PDFDocument> => {
  //   let fridgeValue = JSON.parse(sessionStorage.getItem(`fridge_value`)!)

  //   let comGoal = sessionStorage.getItem(`com_step2_value`) ? JSON.parse(sessionStorage.getItem(`com_step2_value`)!).level4 : ''
  //   let comStrategy = fridgeValue.communication

  //   let groGoal = sessionStorage.getItem(`gro_step2_value`) ? JSON.parse(sessionStorage.getItem(`gro_step2_value`)!).level4 : ''
  //   let groStrategy = fridgeValue.grossMotor

  //   let finGoal = sessionStorage.getItem(`fin_step2_value`) ? JSON.parse(sessionStorage.getItem(`fin_step2_value`)!).level4 : ''
  //   let finStrategy = fridgeValue.fineMotor

  //   let proGoal = sessionStorage.getItem(`pro_step2_value`) ? JSON.parse(sessionStorage.getItem(`pro_step2_value`)!).level4 : ''
  //   let proStrategy = fridgeValue.problemSolving

  //   let socGoal = sessionStorage.getItem(`soc_step2_value`) ? JSON.parse(sessionStorage.getItem(`soc_step2_value`)!).level4 : ''
  //   let socStrategy = fridgeValue.socialEmotional


  //   let formPdfBytes = await axios.get(FridgePagePDFForm, {
  //     responseType: "arraybuffer",
  //   });

  //   let pdfDoc = await PDFDocument.load(formPdfBytes.data);

  //   const form = pdfDoc.getForm();

  //   let nameField1 = form.getTextField("Childs Name");
  //   nameField1.setText(sessionStorage.getItem('childName')!);

  //   let nameField2 = form.getTextField("Creation Date");

  //   let creationDate = new Date(sessionStorage.getItem('creationDate')!)
  //   let dateString = creationDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: '2-digit' })

  //   nameField2.setText(dateString);

  //   let nameField3 = form.getTextField("Communication Goal");
  //   nameField3.setText(comGoal);
  //   let nameField4 = form.getTextField("Communication Strategy");
  //   nameField4.setText(comStrategy);


  //   let nameField5 = form.getTextField("Gross Motor Goal");
  //   nameField5.setText(finGoal);
  //   let nameField6 = form.getTextField("Gross Motor Strategy");
  //   nameField6.setText(finStrategy);


  //   let nameField7 = form.getTextField("Fine Motor Goal");
  //   nameField7.setText(groGoal);
  //   let nameField8 = form.getTextField("Fine Motor Strategy");
  //   nameField8.setText(groStrategy);


  //   let nameField9 = form.getTextField("Problem Solving Goal");
  //   nameField9.setText(proGoal);
  //   let nameField10 = form.getTextField("Problem Solving Strategy");
  //   nameField10.setText(proStrategy);

  //   let nameField11 = form.getTextField("Social Emotional Goal");
  //   nameField11.setText(socGoal);
  //   let nameField12 = form.getTextField("Social Emotional Strategy");
  //   nameField12.setText(socStrategy);

  //   form.flatten();
  //   await pdfDoc.save()
  //   return pdfDoc;
  // }
  const exportFridgePagePDF = async (): Promise<PDFDocument> => {
    let fridgeValue = JSON.parse(sessionStorage.getItem(`fridge_value`)!)

    let comGoal = sessionStorage.getItem(`com_step2_value`) ? JSON.parse(sessionStorage.getItem(`com_step2_value`)!).level4 : ''
    let comStrategy = fridgeValue.communication

    let groGoal = sessionStorage.getItem(`gro_step2_value`) ? JSON.parse(sessionStorage.getItem(`gro_step2_value`)!).level4 : ''
    let groStrategy = fridgeValue.grossMotor

    let finGoal = sessionStorage.getItem(`fin_step2_value`) ? JSON.parse(sessionStorage.getItem(`fin_step2_value`)!).level4 : ''
    let finStrategy = fridgeValue.fineMotor

    let proGoal = sessionStorage.getItem(`pro_step2_value`) ? JSON.parse(sessionStorage.getItem(`pro_step2_value`)!).level4 : ''
    let proStrategy = fridgeValue.problemSolving

    let socGoal = sessionStorage.getItem(`soc_step2_value`) ? JSON.parse(sessionStorage.getItem(`soc_step2_value`)!).level4 : ''
    let socStrategy = fridgeValue.socialEmotional


    let formPdfBytes = await axios.get(FridgePagePDFForm, {
      responseType: "arraybuffer",
    });

    let pdfDoc = await PDFDocument.load(formPdfBytes.data);

    const form = pdfDoc.getForm();

    let nameField1 = form.getTextField("Fridge Page_Child's Name");
    nameField1.setText(sessionStorage.getItem('childName')!);

    let nameField2 = form.getTextField("Creation Date");

    let cDate=sessionStorage.getItem('creationDate')!.substring(4).split(" ");
  switch(cDate[0].toLowerCase()){
    case 'jan':cDate[0]="January";break;
    case 'feb':cDate[0]="February";break;
    case 'mar':cDate[0]="March";break;
    case 'apr':cDate[0]="April";break;
    case 'may':cDate[0]="May";break;
    case 'jun':cDate[0]="June";break;
    case 'jul':cDate[0]="July";break;
    case 'aug':cDate[0]="August";break;
    case 'sep':cDate[0]="September";break;
    case 'oct':cDate[0]="October";break;
    case 'nov':cDate[0]="November";break;
    case 'dec':cDate[0]="December";break;
  }
   
    nameField2.setText(`${cDate[0]} ${cDate[1]}, ${cDate[2]}`);

    let nameField3 = form.getTextField("Communication_My next goal");
    nameField3.setText(comGoal);
    let nameField4 = form.getTextField("Communication_Strategy");
    nameField4.setText(comStrategy);


    let nameField5 = form.getTextField("Gross Motor_My next goal");
    nameField5.setText(finGoal);
    let nameField6 = form.getTextField("Gross Motor_Strategy");
    nameField6.setText(finStrategy);


    let nameField7 = form.getTextField("Fine Motor_My next goal");
    nameField7.setText(groGoal);
    let nameField8 = form.getTextField("Fine Motor_Strategy");
    nameField8.setText(groStrategy);


    let nameField9 = form.getTextField("Problem Solving_My next goal");
    nameField9.setText(proGoal);
    let nameField10 = form.getTextField("Problem Solving_Strategy");
    nameField10.setText(proStrategy);

    let nameField11 = form.getTextField("Social Emotional_My next goal");
    nameField11.setText(socGoal);
    let nameField12 = form.getTextField("Socail Emotional_Strategy");
    nameField12.setText(socStrategy);

    form.flatten();
    await pdfDoc.save()
    return pdfDoc;
  }

  const exportRelationShipPage = async (): Promise<PDFDocument> => {
    let formPdfBytes = await axios.get(RelationshipMatterPDFForm, {
      responseType: "arraybuffer",
    });

    let pdfDoc = await PDFDocument.load(formPdfBytes?.data);

    const form = pdfDoc.getForm();
    const fields = form.getFields();
    let nameField1 = form.getTextField("Why Our Relationship Matters");
    nameField1.setText(sessionStorage.getItem('relationship_matters')!);

    form.flatten();
    await pdfDoc.save()
    return pdfDoc;
  }
  const handleSubmit = async () => {
    alert("Success!\n\nNow the pdf of the plan has been saved on your local computer and can be found by looking fora file titled ‘File.pdf’.");
    toggleOpen();
    const mergedPdf = await PDFDocument.create();
    // const pdfDataUriTEst = await mergedPdf.saveAsBase64({
    //   dataUri: true,
    //   updateFieldAppearances: true

    // });
    // console.log({ pdfDataUriTEst });
    // const mergedPdfFile = await mergedPdf.saveAsBase64();
    let pdfs: string[] = []
    let pdfA_base64: string, pdfB_base64: string, pdfC_base64: string, pdfD_base64: string;

    for (let i = 0; i < pages.length; i++) {

      if (pages[i].checked && sessionStorage.getItem(pages[i].name ?? '') === 'completed') {
        switch (pages[i].name) {

          case "coverPage":
            let pdfA = await exportCoverPagePDF(mergedPdf)
            pdfA_base64 = await pdfA.saveAsBase64()


            pdfs.push(pdfA_base64)
            let documentTest_pdfA = await PDFDocument.load(pdfA_base64!);
            let copiedPagesA = await mergedPdf.copyPages(documentTest_pdfA, documentTest_pdfA.getPageIndices());
            copiedPagesA.forEach((page) => mergedPdf.addPage(page));

            break;

          case "relationship":
            let pdfD = await exportRelationShipPage()
            pdfD_base64 = await pdfD.saveAsBase64()

            pdfs.push(pdfD_base64)
            let documentTest_pdfD = await PDFDocument.load(pdfD_base64!);
            let copiedPagesD = await mergedPdf.copyPages(documentTest_pdfD, documentTest_pdfD.getPageIndices());
            copiedPagesD.forEach((page) => mergedPdf.addPage(page));

            //downloadPDF(pdfA_base64)
            break;

          case "communication":
          case "fineMotor":
          case "grossMotor":
          case "problemSolving":
          case "socialEmotional":
            let pdfB = await exportDomianPagePDF(mergedPdf, pages[i].domainPrefix ?? '', pages[i].pdfPrefix ?? '')
            pdfB_base64 = await pdfB.saveAsBase64()
            pdfs.push(pdfB_base64)


            let documentTest_pdfB = await PDFDocument.load(pdfB_base64!);
            let copiedPagesB = await mergedPdf.copyPages(documentTest_pdfB, documentTest_pdfB.getPageIndices());
            copiedPagesB.forEach((page) => mergedPdf.addPage(page));
            break;

          case "fridge":

            let pdfC = await exportFridgePagePDF()
            pdfC_base64 = await pdfC.saveAsBase64()
            pdfs.push(pdfC_base64)


            let documentTest_pdfC = await PDFDocument.load(pdfC_base64!);
            let copiedPagesC = await mergedPdf.copyPages(documentTest_pdfC, documentTest_pdfC.getPageIndices());
            copiedPagesC.forEach((page) => mergedPdf.addPage(page));
            break;
          default:
            break;
        }
      }
    }


    // let documentTest_pdfB = await PDFDocument.load(pdfB_base64!);
    // let copiedPagesB = await mergedPdf.copyPages(documentTest_pdfB, documentTest_pdfB.getPageIndices());
    // copiedPagesB.forEach((page) => mergedPdf.addPage(page));





    const pdfDataUri = await mergedPdf.saveAsBase64({
      // dataUri: true,
      // updateFieldAppearances: true

    });

    // const mergedPdfFile = await mergedPdf.saveAsBase64();
    console.log({ pdfDataUri });


    downloadPDF(pdfDataUri)

    // let iframe = document.createElement('iframe')
    // iframe.setAttribute('style', 'width: 100%; height: 100%;')
    // iframe.setAttribute('src', pdfDataUri)
    // iframe.setAttribute('class', 'custom-pdf-frame')
    // document.body.appendChild(iframe)


    // if (sessionStorage.getItem("communication") === "completed") {

    //   let pdfBytes = await pdfDoc.saveAsBase64()
    //   console.log({ pdfBytes });
    //   //pdfBytes as []
    //   //let b64encoded = btoa(String.fromCharCode.apply(null, pdfBytes as number[] as unknown));

    //   downloadPDF(pdfBytes)
    // }

  }

  // async function mergePDFDocuments(documents: PDFDocument[]) {
  //   const mergedPdf = await PDFDocument.create();

  //   for (let document of documents) {

  //     let existingPdfBytes = await fetch(document).then(res => res.arrayBuffer())
  //     document = await PDFDocument.load(existingPdfBytes);

  //     const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
  //     copiedPages.forEach((page) => mergedPdf.addPage(page));
  //   }
  //   const pdfDataUri = await mergedPdf.saveAsBase64({
  //     dataUri: true,
  //     updateFieldAppearances: true
  //   });
  //   downloadPDF(pdfDataUri)

  // }

  function downloadPDF(pdf: string) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }


  const handleSelectAll = () => {
    if (exportAll) {
      pages.forEach(page => {
        page.disabled = false;
      })
    }
    else {
      pages.forEach(page => {
        page.checked = true;
        page.disabled = true;
      })
    }

    setExportAll(!exportAll);
  }

  const handleSelect = (index: number) => {
    const newPages = [...pages];
    const page = {
      ...pages[index],
      checked: !pages[index].checked,
    };
    newPages[index] = page;
    setPages(newPages);

  }


  return (
    <>
      <Dialog classes={dialogClasses} open={open} >
        <DialogTitle>Export to PDF</DialogTitle>
        <DialogContent style={{ width: '85%' }}>
          <FormGroup>
            <FormControlLabel className={classes.formLabel}
              control={<Checkbox className={classes.checkbox} checked={exportAll} name={"Export all pages"} onChange={handleSelectAll} />}
              label="Export all pages"
            />
            {pages.map((page, index) => {
              return (
                <FormControlLabel className={classes.formLabel}
                  control={<Checkbox className={page.disabled ? classes.disabledCheckbox : classes.checkbox} checked={page.checked} disabled={page.disabled} name={page.title} onChange={() => handleSelect(index)} />}
                  label={page.title}
                />
              )
            })}
          </FormGroup>

        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.submitButton} onClick={toggleOpen}>
            Back
          </Button>
          <Button className={classes.submitButton} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Hidden>
        {pages.map(page => page.checked && sessionStorage.getItem(page.name ?? '') === 'completed' && (
          <div className={`${classes.container} ${classes.hidden_preview_section}`}>
            {page.renderComponent}
          </div>
        )

        )}
      </Hidden>

    </>
  )

}