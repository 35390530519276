import * as React from "react";
import {
  DataGrid,
  GridColDef,
} from "@material-ui/data-grid";
import { Button, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { AdminBreadCrums } from "./AdminBreadCurms";
import { globalStyles } from "../../../GlobalStyles";
import axios from "axios";
import { useState } from "react";
import  './table.css';
import { Search } from "@material-ui/icons";



const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 130 },
  { field: "status", headerName: "Status", width: 130 },
  {
    field: "role",
    headerName: "Role",
    width: 150,
    editable: false,
    headerClassName: 'header',
    headerAlign: 'center',

    
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    type: "string",
    width: 300,
    editable: false,
  }
];

type UserType={
  id:number,
  status:string,
  role:string,
  name:string,
  email:string
}

const AllUserList = () => {
  const globalClasses = globalStyles();
  const[rows,setRows]=useState<UserType[]>([]);
  axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}user/allusersList`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-type': 'application/json',
      'api_key': process.env.REACT_APP_API_KEY,
      'access_token': sessionStorage.getItem('token'),
      crossdomain: true
    }
  }).then(res => {
    console.log(res.data.usersList);
    setRows(res.data.usersList);
  }).catch(error => {
    console.log(error);
  });
  return (
    <Grid container item xs={12} direction="row">
      <Grid item xs={12}>
        <AdminBreadCrums
          items={[
            { name: "Admin Site", link: "admin" },
            { name: "User Management", link: "userManage" },
          ]}
        />
        <Typography variant="h4" className={globalClasses.titleText}>
          User Management
        </Typography>

        
        <Grid container item xs={12} direction="row" style={{marginTop:'20px'}}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
          <Grid
          container
          item
          md={12}
          xs={12}
          style={{alignItems:'left',height:'40px'}}
        >
          <Grid item md={4} xs={12}>
            <Button
              variant="outlined"
              className='exportuser_button'           
            >
              Export User
            </Button>
            
          </Grid>
          <Grid item md={4} xs={12} >
          <Button variant="contained" className="adduser_button" >
              Add User
            </Button>   
          </Grid>
          <Grid item md={4} xs={12} >
          <TextField
                    variant="outlined"               
                    required
                    fullWidth
                    placeholder="Search for users"  
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                      style: {
                        height: "40px",
                      }
                    }}
                   
                  /> 
          </Grid>
        </Grid>
            <div style={{ height: 600, width: '100%',marginTop:'20px' }} className={globalClasses.UserListTable}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={15}
                disableSelectionOnClick
              />
            </div>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AllUserList };
