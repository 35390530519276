/* eslint-disable no-unused-vars */

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export default function AlertDialog(props: any): JSX.Element {

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    useEffect(() => {

        setIsDialogOpen(props.isOpen > 0);
    }, [props.isOpen])
const errMsg:string=props.msg;
    
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      
      <Dialog
        open={isDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}


