/* eslint-disable */
import React, { useEffect, useState } from 'react'

//material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';



// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClipboard, faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import AlertDialog from './AlertDialog';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) =>
({
  paper2: {
    height: '100%',
    width: '85%',
    margin: 'auto'
  },

  terms_title: {
    color: '#0046AD',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '48px',
  },
  terms_content_wrapper: {
    overflowY: 'hidden',
    position: 'relative',
    height: '35%',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  terms_content: {

    margin: '0 48px',
    //tablet
    [theme.breakpoints.down('md')]: {
      margin: '0 24px',
    },
    //mobile
    [theme.breakpoints.down('sm')]: {
      margin: '0',

    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#707071'
    },

    '&::-webkit-scrollbar-track':
    {
      borderRadius: '10px',
      backgroundColor: '#F5F5F5'
    },

    '&::-webkit-scrollbar':
    {
      width: '8px',
      backgroundColor: '#F5F5F5'
    }
  },
  terms_showing: {
    display: 'block',
    position: 'absolute',
    zIndex: 3,
    background: 'white',
    height: '95%',
    width: '95%',
    borderRadius: '15px',
  },

  get_code_btn: {
    background: 'linear-gradient(45deg,#0046AD,black)',
    color: 'white',
    fontWeight: 700,
    borderRadius: '10px',
    width: '50%',
    fontSize: '20px',
    height: '30%',
    '&.MuiButton-contained.Mui-disabled': {
      color: 'white',
      boxShadow: 'none',
      background: 'rgba(0, 0, 0, 0.12)',
    },
    //tablet
    [theme.breakpoints.down('md')]: {
      width: '70%',
      fontSize: '18px',
      height: '28%',
    },
    //mobile
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '15px',
      height: '25%',

    },
  },
  inputField: {
    background: 'white',
    borderRadius: '8px',
    marginBottom: theme.spacing(0.5),
    'input': {
      borderRadius: '8px',
      border: 'none',
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      boxShadow: '0 3px 3px #e5e5e5',
      borderColor: 'rgb(255 255 255)'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046AD'
    },
    '& .MuiFormHelperText-contained': {
      color: 'red',
    }
  },
  disabled_inpField: {
    border: '1px solid black',
    display: 'flex',
    width: '40%',
    margin: 'auto',
    textAlign: 'center',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    //tablet
    [theme.breakpoints.down('md')]: {
      width: '40%',
      height: '80%',
    },
    //mobile
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      height: '50%',
    },
    '& div.MuiInput-underline .ui-disabled:before': {
      borderBottom: 'none'
    },
    '& input': {
      textAlign: 'center',
      fontSize: '1.5rem',
      color: 'green'
    }
  },
  copied_icon: {
    fontSize: '2rem',
    '& .fa-check': {
      color: 'green'
    }
  }
}),
);

export default function AccessCodeDialog(props: any): JSX.Element {
  const classes = useStyles();

  const { getACodeOpen, closeModalFunc, agreeTerms, emailValue, accessCode, emailInput, getACode, aCodeVal } = props;

  const handleClose = () => {
    props.closeModalFunc(false);
  };

  const emailRegEx: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // email valid
  const [isEmailValid, setIsEmailValid] = useState(false);
  
  useEffect(() => {
    if (emailValue !== "")
      setIsEmailValid(emailValue !== '' && emailValue !== null && emailRegEx.test(emailValue));
  })

  const emailValidation = (e: any) => {

    setIsEmailValid(e.target.value !== '' && e.target.value !== null && emailRegEx.test(e.target.value));

    emailInput(e)
  }

  // TODO: Retrieve Access Code API
  const [alertMsg, setAlertMsg] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);


  const retrieveACode = () => {
    let accode;
    if (isEmailValid) {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}user/invitation/${emailValue}`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        },
      }).then(res => {
        console.log('res: ', res);
        getACode(res.data.invitationCode);
        setIsAlertOpen(false);

      }).catch(error => {
        console.log('exception');
        console.log(error.response.data);
        setAlertMsg(error.response.data.error);
        setIsAlertOpen(true);
        getACode("");
      });
    }

  }

  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(aCodeVal);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 4000)
  }


  return (
    <>

      <div style={{ display: getACodeOpen ? 'block' : 'none' }} className={classes.terms_showing}>
        <>
          <div className={classes.paper2}>
            <>
              <h2 className={classes.terms_title} id="transition-modal-title">Access Code</h2>
              <Box position="absolute" top={10} right={15}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </>

            <div id="transition-modal-description"
              className={classes.terms_content_wrapper}>
              <div className={classes.terms_content} id="terms_content">
                <p>To obtain the access code, you must complete the NTS training. For more information please visit <a href="https://www.imhpromotion.ca/" target="_blank">IEMHP website</a> or <Link to="contact" target="_blank">contact us</Link> if you need any help.</p>
                <Grid item xs={12} sm={10} md={6} style={{ margin: 'auto' }}>
                  <TextField
                    id="email"
                    fullWidth
                    required
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    helperText={!isEmailValid ? 'Please enter a valid email address' : ''}
                    className={classes.inputField}
                    value={emailValue}
                    // onChange={(e) => emailInput(e)}
                    onChange={emailValidation}
                  />
                </Grid>
              </div>

            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: '100%',
                height: '35%',
                flexDirection: 'column',
                textAlign: 'center'
              }}
            >
              <Button
                variant="contained"
                className={classes.get_code_btn}
                // onClick={() => {
                //   props.agreeTerms(true);
                //   handleClose();
                // }}
                onClick={retrieveACode}
                disabled={!isEmailValid}
              >
                Get My Code
              </Button>

              {/* <AlertDialog isOpen={isAlertOpen} msg={alertMsg} /> */}
              <div
                style={{
                  display: isAlertOpen ? 'block' : 'none',
                  width: '100%',
                  height: '45%',
                  color: '#D71F85',
                  fontWeight: 600
                }}>
                ***Sorry, we cannot find a valid invitation code with this email address, please try again
              </div>
              <div
                style={{
                  width: '100%',
                  height: '45%',
                  display: isAlertOpen ? 'none' : 'block'
                }}
              >
                <TextField
                  disabled
                  id="outlined-disabled"
                  value={aCodeVal}
                  className={classes.disabled_inpField}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <>
                        <span className={classes.copied_icon}>
                          <FontAwesomeIcon icon={isCopied ? faCheck as IconProp : faClipboard as IconProp} onClick={copyToClipboard} style={{ cursor: 'pointer' }} />
                        </span>
                      </>
                    )
                  }}

                />
              </div>
            </div>


          </div>
        </>
      </div>
    </>
  );
}
