/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { makeStyles, Grid, Hidden } from '@material-ui/core';

import { Prompt, useHistory, useLocation } from 'react-router-dom';
import NavSidebar from './Dashboard/NavSideBar';
import DSPHeader from './Dashboard/DashBoardHeader';
import axios from 'axios';
import FirstTimeProfileDialog from './component/FirstTimeProfileDialog';
import Plan from './Dashboard/Plan';
import ContactUs from './Dashboard/ContactUs';
import ProfilePage from './Dashboard/Profile';
import VerifyAccessToken from '../utils/VerifyAccessToken';
import MyTeam from './Dashboard/MyTeam';
import ExploreStrategies from './Dashboard/Strategies/ExploreStrategies';
import HelpCenter from './Dashboard/HelpCenter/HelpCenter';
import AdminCenter from './Dashboard/Admin/AdminCenter';
import { AdminBoard } from './Dashboard/Admin/AdminBoard';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      paddingTop: '90px',
    },
  paddingTop: '64px',
  margin:'auto',
},
  listIcon: {
    '&:hover': {
      cursor: "pointer",
    },
  },
  greetingText: {
    fontWeight: 600,
    margin: '20px 20px',

  },
  listText: {
    fontWeight: 600,
    maxWidth: 200,
    margin: '0 auto',
  },
  listContainer: {
    textAlign: 'center',
  },
  listContainerVline: {
    textAlign: 'center',
    borderLeft: '1px solid #d7d7d7',
  },
 
  backgroundPic: {
    borderTop: '8px solid #a83071',
    borderBottom: '8px solid #a83071',
    marginTop: '70px',
    width: '100%',
  },
  UserOptions: {
    // marginTop: '20px',
    width: '90%',
    float: 'left',
  },
  sideNavBar: {
    margin: '5px 0',
    width: '90%'
  },
  btnToggle: {
    cursor: 'pointer',
    width: '35px',
    height: '35px',
    background: '#353535',
    color: '#fff',
    textAlign: 'center',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15px',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#707070',
    opacity: 0.6,
},
}));




function CustomerDashBoard(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [firstTimeProfileModalOpen, setFirstTimeProfileModalOpen] = useState(false);
  const [selectmenu,setSelectMenu]=useState('dsp')

  const [locationKeys, setLocationKeys] = useState([]);

  

  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value: any) => {
    console.log('in');
    setToggled(value);
  };

  
  useEffect(() => {
    return () => {
        if (history.action === 'POP') {
            history.go(1);
        }
    };
}, [history]);



  

  useEffect((): void => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}user/profile`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      }
    }).then(res => {
      // user has profile
    }).catch(error => {
      // user does not have profile, open first time modal
      console.log(error);

      if(error.response.status===404)
        toggleFirstTimeProfileModalOpen();
      else
        history.push('/');
    });
    
    if(location.state!=null){
      setSelectMenu( location.state[0]);
    }

   
  }, []);

  const toggleFirstTimeProfileModalOpen = (): void => {
    setFirstTimeProfileModalOpen(!firstTimeProfileModalOpen);
  };
  return (
    <>
    <VerifyAccessToken/>
     <DSPHeader
        handleToggleSidebar={handleToggleSidebar}
        toggled={toggled}
        pageName={selectmenu}
      />    
        {/* <Grid container component="main" style={{ minHeight: window.innerHeight }}> */}
        <Grid container component="main" className={classes.root}>
        <p>&nbsp; </p>
          {/* <Grid item xs={false} sm={false} md={3} className={classes.sideNavBar}> */}
          <Hidden smDown={!toggled}>
          <Grid item className={`app ${toggled ? 'toggled' : ''}`}>
            <NavSidebar
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              setSelectMenu={setSelectMenu}
              selectmenu={selectmenu}
               />
          </Grid>
          </Hidden>
            <Grid container item md={8}  >
            {selectmenu==='contactUs' && <ContactUs></ContactUs>
            }
            {selectmenu==='dsp' && <Plan ></Plan>
            }
            {
              selectmenu==='profile'&&<ProfilePage setSelectMenu={setSelectMenu}></ProfilePage>
            }
            {
            selectmenu==='help'&&<HelpCenter></HelpCenter>
            }
            {
            selectmenu==='help1'&&<HelpCenter></HelpCenter>
            }
            {
              selectmenu==='myTeam'&&<MyTeam></MyTeam>
            }
            {
              selectmenu==='strategy'&&<ExploreStrategies></ExploreStrategies>
            }
            {
              selectmenu==='admin'&&<AdminBoard></AdminBoard>
            }
            {
              selectmenu==='admin1'&&<AdminBoard></AdminBoard>
            }
            
            </Grid>
         
         
        </Grid>

        {/* <Hidden mdUp>
            {toggled && <div className={classes.overlay} onClick={() => handleToggleSidebar(!toggled)}/> }
          </Hidden> */}
      

      <FirstTimeProfileDialog open={firstTimeProfileModalOpen} toggleOpen={toggleFirstTimeProfileModalOpen} />
     
    </>
  );
}

export default CustomerDashBoard


// <Box px={6}>