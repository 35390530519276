/* eslint-disable no-unused-vars */
import { Typography, makeStyles, Grid } from "@material-ui/core";
import React from "react";
import { globalStyles } from "../../../GlobalStyles";
import HelpCenterBreadCrums from "./HelpCenterBreadCrums";
import YouTubeVideo from "./YouTubeVideo";

const useStyles = makeStyles((theme) => ({

    root: {
        width: '100%',
    },
    border: {
        borderColor: '#DFDFDF',
        backgroundColor: '#F7F7F7',
        borderStyle: 'solid',
        padding: '10px 0',

    },
    question: {
        fontSize: 'x-large',
        color: '#0046AD',
        fontWeight: 'bold',
        textAlign: 'left',
        '&:hover': {
            cursor: "pointer",
        },
    },
    menu: {
        fontSize: 'large',
        color: '#0046AD',
        '&:hover': {
            cursor: "pointer",
        },
    },
    img: {
        marginRight: '5px'
    },
    answer: {
        backgroundColor: '#FFFFFF',
        color: '#000000',
        marginTop: '10px',
        padding: '10px'
    },
    answerP: {
        margin: '0'
    }

}));

const Video = (props:any): JSX.Element => {
    const classes = useStyles();
    const globalClasses = globalStyles();
   

   

    return (
        <>
            <Grid container item xs={12} direction="row">
                <Grid item xs={12}>
                    <HelpCenterBreadCrums
                        setHelp={props.setHelp}
                        items={[
                            { name: 'Help Centre', link: 'main' },
                            { name: 'Video Tutorials', link: 'coverPage' },
                        ]}
                    
                    />
                    <Typography variant="h4" className={globalClasses.titleText}>
                       Video Tutorial
                    </Typography>

                    <Grid container item xs={12} direction="row">

                       
                        <Grid item xs={12} lg={8} style={{paddingLeft:'10px'}}><YouTubeVideo/>
                          <br/>
                          <a style={{ fontSize: '1.5rem',}} href='pdf/Transcript.pdf' download>Download the App Tutorial Transcript (PDF)</a>
                    
                        </Grid>
                        <Grid item xs={12} lg={4} >
                            <Typography  className={globalClasses.subTitleText2}>
                                Table of Contents
                            </Typography>
                            <Typography  className={globalClasses.content}>
                                Use the timestamps below to skip to the applicable part:
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:00:46] </span> <span className={globalClasses.content1}>Part 1: Explore Strategies </span>
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:02:41] </span> <span className={globalClasses.content1}>Part 2: Help Centre </span>
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:03:36] </span> <span className={globalClasses.content1}>Part 3: Contact Us </span>
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:03:46] </span> <span className={globalClasses.content1}>Part 4: My Profile </span>
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:04:23] </span> <span className={globalClasses.content1}>Part 5: Create Plan </span>
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:15:28] </span> <span className={globalClasses.content1}>Part 6: Save and Export Plan </span>
                            </Typography>
                            <Typography  >
                                <span className={globalClasses.subTitleText2}>[00:19:10] </span> <span className={globalClasses.content1}>Part 7: Edit Plan </span>
                            </Typography>
                        </Grid>

                    </Grid>
                         
                </Grid>
            </Grid>


        </>);
}

export default Video;

