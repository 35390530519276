/* eslint-disable */
import React, { useState } from 'react';

//material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';


// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

// component Folder
import Header from './Header';
import SignUpBg1200 from '../img/sign_up_bg_1200.png';
import SignUpBg992 from '../img/sign_up_bg_992.png';
import SignUpBg768 from '../img/sign_up_bg_768.png';
import emailOpening from '../img/email.png';
import axios, { AxiosResponse } from 'axios';
import SignUpConfirm from './component/SignUpConfirm';
import AlertDialog from './component/AlertDialog';

import { useHistory } from "react-router-dom";

import Footer from './Footer'
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {TermsDialogView} from './component/TermsDialogNew';
import AccessCodeDialog from './component/AccessCodeDialog';
// import EmailVeriDialog from './EmailVeriPage';
// import { resolve } from 'app-root-path';
// import { rejects } from 'assert';
// styles
const useStyles = makeStyles((theme) => ({

  image: {
    backgroundImage: 'url(' + SignUpBg1200 + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 'auto',
    [theme.breakpoints.down('lg')]: {
      backgroundImage: 'url(' + SignUpBg1200 + ')',
    },
    //tablet
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'url(' + SignUpBg992 + ')',
    },
    //mobile
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(' + SignUpBg768 + ')',
    }
  },
  paper: {
    margin: theme.spacing(0, 8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    //tablet
    [theme.breakpoints.only('sm')]: {
      margin: theme.spacing(0, 10),
    },
    //mobile
    [theme.breakpoints.only('xs')]: {
      margin: theme.spacing(0, 4),
    }
  },
  paper_wrapper: {
    backgroundColor: "rgb(255 255 255 / 55%)", marginTop: '10%',
    marginBottom: '10%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',

  },
  '& main': {
    [theme.breakpoints.down('lg')]: {
      marginTop: "15%",
    }
  },

  title: {
    fontFamily: "Open Sans, sans-serif",
    color: "#0046AD",
    fontWeight: 700,
    margin: theme.spacing(4, 0),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '10px 0',
      fontSize: '1.7rem',
    }
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitBtn: {
    margin: theme.spacing(1, 0, 2),
    background: "#D71F85",
    color: 'white',
    fontSize: '20px',
    borderRadius: '10px',
    '&.MuiButton-root:hover': {
      backgroundColor: '#D71F85'
    }
  },
  dividerContainer: {
    display: "flex",
    alignItems: "center"
  },
  dividerBorder: {
    borderBottom: "2px solid #727679",
    width: "100%"
  },
  dividerContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: "#727679"
  },
  inputField: {
    background: 'white',
    borderRadius: '8px',
    marginBottom: theme.spacing(0.5),
    'input': {
      borderRadius: '8px',
      border: 'none',
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      boxShadow: '0 3px 3px #e5e5e5',
      borderColor: 'rgb(255 255 255)'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046AD'
    }
  },
  asterisk_input: {
    position: 'relative',
    fontSize: '1.5em'

  },
  root: {
    flexGrow: 1,
  },

  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },

  term_link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  email_verification_wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  email_veri_img_btn_wrapper: {
    width: "100%",
    height: "50%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& img': {
      
      display:'none',
      //tablet
      [theme.breakpoints.down('md')]: {
        
        display:'none',
      },
      //mobile
      [theme.breakpoints.down('xs')]: {
        width: '35%',
        height: 'auto',
        marginBottom: '5%',
        display:'block',
      }
    },

    '& button': {
      width: '50%',
    },
  },
  email_veri_text: {
    [theme.breakpoints.down('xs')]:{
      fontSize: '0.9rem',
      marginBottom: 0
    }
  }
}));



export default function SignUpPage(): JSX.Element {
  let history = useHistory();

  const classes = useStyles();

  // password valid: true or false
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  //confirm password
  const [isCPValid, setIsCPValid] = useState(true);
  // password value
  const [pwValue, setPwValue] = useState('');

  // full name valid
  const [isFNValid, setIsFNValid] = useState(true);
  // full name value
  const [fNValue, setFNValue] = useState('');

  // email valid
  const [isEmailValid, setIsEmailValid] = useState(true);
  // email value
  const [emailValue, setEmailValue] = useState('');
  // confirm email
  const [isCfEmailValid, setIsCfEmailValid] = useState(true);

  //access code valid
  const [isICValid, setIsICValid] = useState(true);
  //access code value
  const [iCValue, setICValue] = useState('');

  // agree terms and policy
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);
  // agree message
  const [termErrMsg, setTermErrMsg] = useState('')


  const [passwordShown, setPasswordShown] = useState(false);
  const [isACodeOpen, setIsACodeOpen] = useState(false);
  const [getACodeOpen, setGetACodeOpen] = useState(false);
  const [isTuDialogOpen, setIsTuDialogOpen] = useState(false);
  const [isSCOpen, setIsSCOpen] = useState(0);

  const [alertMsg, setAlertMsg] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(0);

  //state of success signing up
  const [isSUsuccess, setIsSUsuccess] = useState(false);

  //state to check if user is verified their email
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  // state to store status of user returned from API
  // const [userStatus, setUserStatus] = useState('');


  var privacyError = 'Please agree Privacy.';
  var termsError = 'Please agree to the Terms of use';


  const onPasswordChangeHandler = (e: any) => {
    /*^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$
    At least one upper case English letter, (?=.*?[A-Z])
    At least one lower case English letter, (?=.*?[a-z])
    At least one digit, (?=.*?[0-9])
    At least one special character, (?=.*?[#?!@$%^&*-])
    Minimum eight in length .{8,} (with the anchors)*/

    let regEx: RegExp = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}/;

    setIsPasswordValid(e.target.value !== '' && e.target.value !== null && regEx.test(e.target.value));
    setPwValue(e.target.value);


  };


  const onEmailBlurHandler = (e: any) => {
    let regEx: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setIsEmailValid(e.target.value !== '' && e.target.value !== null && regEx.test(e.target.value));
    setEmailValue(e.target.value);

  }

  const onSignupClickhandler = (e: any) => {
    let isValid: boolean = true;

    // check validity Password
    if (!isPasswordValid || pwValue === '') {
      setIsPasswordValid(false);
      isValid = false;
    }
    // check validity Confirm Password
    if (!isCPValid) {
      setIsCPValid(false);
      isValid = false;
    }

    // check validity Full name
    if (!isFNValid || fNValue === '') {
      setIsFNValid(false);
      isValid = false;
    }

    // check validity of Email
    if (!isEmailValid || emailValue === '') {
      setIsEmailValid(false);
      isValid = false;
    }

    // check validity Confirm Email
    if (!isCfEmailValid) {
      setIsCfEmailValid(false);
      isValid = false;
    }

    // check validity of Access Code 
    if (!isICValid || iCValue === '') {
      setIsICValid(false);
      isValid = false;
    }

    if (!isAgreeTerms) {
      setTermErrMsg(termsError);
      isValid = false;
    }


    if (isValid) {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}user/signup`,
        // url: `http://localhost:5000/user/signup`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        },
        data: {
          name: fNValue,
          email: emailValue,
          activationCode: iCValue,
          password: pwValue,
        },
      }).then(res => {
        console.log({ res });
        //setIsSCOpen(isSCOpen + 1);

        if (res.status === 200 || res.status === 201) {
          setTimeout(() => {
            fetchUserStatusIn10min();
          }, 500);
          setIsSUsuccess(true);
        }

      }).catch(error => {
        console.log('exception');
        console.log(error.response.data);
        setAlertMsg(error.response.data.error);
        setIsAlertOpen(isAlertOpen + 1);
      });
    }

  };

  const onPasswordToggle = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const agreeTerms = (isAgree: boolean) => {
    setIsAgreeTerms(isAgree);
    if (isAgree)
      setTermErrMsg('');
    else
      setTermErrMsg(termsError);
  };

  const handleClose = (isOpen: boolean) => {
    setIsTuDialogOpen(isOpen);
    setIsACodeOpen(isOpen);
    setGetACodeOpen(isOpen)
  };


  const RenderEmailForm = (props: any): JSX.Element =>
    <>
      <div style={{ display: props.show ? 'block' : 'none', width: '100%', height: '70vh' }}>
        <div className={classes.email_verification_wrapper}>
          <Typography component="h1" variant="h4" className={classes.title}>
            Email Verification
          </Typography>
          <div id="transition-modal-description"
          >
            <div id="terms_content">
              <Typography paragraph className={classes.email_veri_text}>
                An activation email has been sent to <b>{emailValue}</b>. Please check your inbox to verify your email address, and follow the instruction to complete the registration process.
              </Typography>
            </div>
          </div>
          {isEmailVerified &&
            <div className={classes.email_veri_img_btn_wrapper}>
              <img src={emailOpening} alt="email_opening" />
              <Button
                fullWidth
                className={classes.submitBtn}
                href='/login'
              >
                Verified
              </Button>
            </div>
          }

        </div>


      </div>

    </>


  /**
   * 
   * @returns Promise after calling get user's status api from the backend
   */
  const getUserStatus = (): Promise<AxiosResponse> => {
    //console.log('object');
    return new Promise((resolve, rejects) => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}user/status/${emailValue}`,
        //url: `http://localhost:5000/user/status/${emailValue}`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        }
      }).then(res => {
        //console.log(res);
        resolve(res.data);
      }).catch(error => {
        console.log('getUserStatus error: ',error);
        rejects(error)
      });
    })

  }

  /**
   * This function call the api getUserStatus() recursively until the condition status ='A' is met
   * @param nIntervId: interval's Id to clear if the condition is met
   * 
   */
  const getUserStatusConstant = async (nIntervId: NodeJS.Timeout) => {
    //console.log('getUserStatusConstant');
    let res: any, status: string;
    try {
      res = await getUserStatus();
      status = res.status;
      //console.log({ status });

      if (status === 'A') {
        setIsEmailVerified(status === 'A');
        clearInterval(nIntervId)
      }
      else {
        getUserStatusConstant(nIntervId);
      }
    } catch (error) {
      console.log(error);
    }
  }


  /**
   * This function will call the function getUserStatusConstant() to fetch the User's status in 10 mins until it gets the status = 'A'
   */
  const fetchUserStatusIn10min = () => {

    // nIntervId: holds the 10-min-interval so that we can clear it if we have the status = 'A'
    let nIntervId: NodeJS.Timeout;

    // startTime: will start the timer to track if its more than 10 mins or not
    let startTime = new Date().getTime();
    // let counter = 0;
    if (!isEmailVerified) {
      try {
        nIntervId = setInterval(async () => {
          getUserStatusConstant(nIntervId);
          //counter++;

          if (new Date().getTime() - startTime > 60 * 1000 * 10) {
            clearInterval(nIntervId);

            // console.log(`ended 10min with ${counter} times`);
          }
        }, 15000) // call every 15 seconds
      } catch (error) {

      }
    }
    /*
    //let test, nIntervId: NodeJS.Timeout;

    // setTimeout(function () {
    //   clearInterval(nIntervId);
    // }, 10000) // stop it after 10seconds


    //let startTime = new Date().getTime();


    // do {
    //   setTimeout(async () => {
    //     let res: any;
    //     try {
    //       res = await getUserStatus();
    //       test = res.status;
    //       console.log(test);
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }, 1000)

    //   if (new Date().getTime() - startTime > 60000) {
    //     clearInterval(nIntervId);
    //     test = 'F';
    //   }
    //   if(test === 'A')
    //   clearInterval(nIntervId);
    //   console.log({ test });
    // } while (test !== 'A');

    // console.log(test === 'A');
    // setIsEmailVerified(test === 'A');
    // setTimeout(() => {
    //   history.push('/')
    // }, 3000)
    */
  }



  return (
    <>
      <Header />
      <AlertDialog isOpen={isAlertOpen} msg={alertMsg} />

      <Container maxWidth={false} disableGutters={true}>
        
        <div className={classes.root}>
        <p>&nbsp; </p>
          <Grid container component="main" className={classes.image} direction="row" alignItems='center' justifyContent='center'>
            <Grid item xs={10} sm={9} md={10} xl={9} component={Paper} elevation={2} className={classes.paper_wrapper}>
              <TermsDialogView
                isTuDialogOpen={isTuDialogOpen}
                agreeTerms={agreeTerms}
                closeModalFunc={handleClose}
              />

              <AccessCodeDialog
                getACodeOpen={getACodeOpen}
                agreeTerms={agreeTerms}
                closeModalFunc={handleClose}
                emailInput={onEmailBlurHandler}
                getACode={(code: string) => setICValue(code)}
                aCodeVal={iCValue}
                emailValue={emailValue}
                isEmailValidProps={isEmailValid}
              />

              <div className={classes.paper}>
                <RenderEmailForm {...{ show: isSUsuccess }} />
                {/* <RenderSignUpFrom /> */}
                <div style={{ display: isSUsuccess ? 'none' : 'block', width: '100%', height: '100%' }}>
                  <Typography component="h1" variant="h4" className={classes.title}>
                    Sign Up
                  </Typography>

                  <form className={classes.form} noValidate>
                    <Grid
                      container item
                      xs={12} spacing={2}
                      style={{
                        position: 'relative',
                      }}>
                      <Grid
                        item
                        xs={12} sm={12} md={6}
                      >
                        <TextField
                          error={!isFNValid}
                          variant="outlined"
                          required
                          fullWidth
                          id="fName"
                          label="Full Name"
                          name="fName"
                          value={fNValue}
                          helperText={!isFNValid ? 'Please enter your full name' : ''}
                          onChange={(e) => { setFNValue(e.target.value); setIsFNValid(!(e.target.value === '' || e.target.value === undefined)) }}
                          className={classes.inputField}
                          InputProps={{
                            endAdornment: (
                              <>
                                <span className={classes.asterisk_input} > * </span>
                              </>
                            )
                          }}
                          InputLabelProps={{ required: false }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          error={!isICValid}
                          variant="outlined"
                          value={iCValue}
                          required
                          fullWidth
                          id="iCode"
                          label="Access Code"
                          name="iCode"
                          helperText={!isICValid ? <><span>Your access code doesn't match with your email. Please enter a valid access code.</span> <a href='#'>Contact us</a> <span>if it still doesn't work.</span></> : ''}
                          onChange={(e) => { setICValue(e.target.value); setIsICValid(!(e.target.value === '' || e.target.value === undefined)) }}
                          className={classes.inputField}
                          InputProps={{
                            endAdornment: (
                              <>
                                <FontAwesomeIcon icon={faQuestionCircle as IconProp} onClick={() => setIsACodeOpen(!isACodeOpen)} style={{ cursor: 'pointer' }} />
                                <span className={classes.asterisk_input} style={{ marginLeft: '8px' }}> * </span>
                              </>
                            )
                          }}
                          InputLabelProps={{ required: false }}
                        />

                      </Grid>
                      <Grid item xs={12} md={12} style={{
                        display: isACodeOpen ? "block" : "none",
                        padding: 0
                      }}>
                        <>
                          <div
                            style={{
                              position: 'absolute',
                              zIndex: 2,
                              width: '100%',
                              left: 0,
                              height: '200%',
                            }}
                          >
                            <div
                              style={{
                                background: 'white',
                                margin: '0px 8px',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: '0 0 3px #727679'
                              }}>
                              <h4 style={{ marginTop: 0 }}>Access Code</h4>
                              <p><span>Nurturing The Seed requires all users to provide the assigned access code as the proof of our essential training for registration.</span>
                                <br /><br /><br />
                                <Link onClick={() => setGetACodeOpen(true)}
                                  className={classes.term_link}>Get your access code!
                                </Link>
                              </p>
                            </div>
                          </div>
                        </>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          error={!isEmailValid}
                          variant="outlined"
                          required
                          fullWidth
                          name="email"
                          label="Email"
                          id="email"
                          type="email"
                          value={emailValue}
                          helperText={!isEmailValid && !getACodeOpen ? 'Please enter a valid email address' : ''}
                          onChange={onEmailBlurHandler}
                          className={classes.inputField}
                          InputProps={{
                            endAdornment: (
                              <>
                                <span className={classes.asterisk_input}> * </span>
                              </>
                            )
                          }}
                          InputLabelProps={{ required: false }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          error={!isCfEmailValid}
                          variant="outlined"
                          required
                          fullWidth
                          name="email"
                          label="Confirm email"
                          id="email"
                          type="email"
                          helperText={!isCfEmailValid ? 'Email does not match.' : ''}
                          onChange={(e) => { setIsCfEmailValid(e.target.value !== undefined && e.target.value === emailValue) }}
                          className={classes.inputField}
                          InputProps={{
                            endAdornment: (
                              <>
                                <span className={classes.asterisk_input}> * </span>
                              </>
                            )
                          }}
                          InputLabelProps={{ required: false }}

                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          error={!isPasswordValid}
                          variant="outlined"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          id="password"
                          value={pwValue}
                          type={passwordShown ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <>
                                <FontAwesomeIcon icon={passwordShown ? faEye as IconProp : faEyeSlash as IconProp} onClick={onPasswordToggle} style={{ cursor: 'pointer' }} />
                                <span className={classes.asterisk_input} style={{ marginLeft: '8px' }}> * </span>
                              </>
                            )
                          }}
                          onChange={onPasswordChangeHandler}
                          helperText={!isPasswordValid ? "* Your password must contain at least 1 upper case,1  numeric, 1 special character and be a minimum of 8 characters" : ""}
                          className={classes.inputField}
                          InputLabelProps={{ required: false }}

                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          error={!isCPValid}
                          variant="outlined"
                          required
                          fullWidth
                          name="password"
                          label="Confirm Password"
                          id="password"
                          type={passwordShown ? "text" : "password"}
                          onChange={(e) => { setIsCPValid(e.target.value !== undefined && e.target.value === pwValue) }}
                          helperText={!isCPValid ? 'Password does not match.' : ''}
                          className={classes.inputField}
                          InputProps={{
                            endAdornment: (
                              <>
                                <FontAwesomeIcon icon={passwordShown ? faEye as IconProp : faEyeSlash as IconProp} onClick={onPasswordToggle} style={{ cursor: 'pointer' }} />
                                <span className={classes.asterisk_input} style={{ marginLeft: '8px' }}> * </span>
                              </>
                            )
                          }}
                          InputLabelProps={{ required: false }}

                        />
                      </Grid>
                    </Grid>

                    <Grid item container direction="row" alignItems='center'>
                      <Grid item container xs={12} sm={12} md={6} direction="row" alignItems='center' >
                        <Checkbox
                          className={classes.checkbox}
                          disableRipple
                          color="default"
                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                          icon={<span className={classes.icon} />}
                          inputProps={{ 'aria-label': 'decorative checkbox' }}
                          checked={isAgreeTerms}
                          onChange={(e) => agreeTerms(e.target.checked)}
                        />
                        <div>
                          <Typography component="p" variant="body1">
                            I've read and agree with  <Link onClick={(e) => setIsTuDialogOpen(true)} className={classes.term_link}
                            >Terms of Use</Link>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} direction="row" alignItems='center' >
                        <div style={{ color: 'red' }}>{termErrMsg}</div>
                      </Grid>
                    </Grid>



                    <Grid container direction="row" alignItems='center' justifyContent='center' style={{ marginBottom: '32px' }}>
                      <Grid item xs={6} sm={6} md={6} direction="row" alignItems='center' >
                        <Button
                          fullWidth
                          className={classes.submitBtn}
                          onClick={onSignupClickhandler}
                          style={{}}
                        >
                          Sign Up
                        </Button>
                        <div className={classes.dividerContainer}>
                          <div className={classes.dividerBorder} />
                          <span className={classes.dividerContent}>Or</span>
                          <div className={classes.dividerBorder} />
                        </div>

                        <div style={{ textAlign: 'center' }}>
                          Already have an account?  <Link href="login" variant="body2">
                            {"Sign In"}
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                {/* <RenderFormOrEmail /> */}
              </div>
            </Grid>

          </Grid>
        </div>
      </Container>
      <Footer />
    </>
  );
}