/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';



ReactDOM.render(
 <App/>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

/*let appVersionInBrowser=localStorage.getItem('version')==null||''||undefined?0:Number(localStorage.getItem('version'))


{
  console.log("unregister old add")

  caches.keys().then((names) => {
    names.forEach((name) => {
      console.log(name);
      caches.delete(name);
    });
  });
  //serviceWorker.unregister();
 
  //localStorage.setItem('version',appVersion.toString());
}
*/

//serviceWorker.register();
