/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Grid
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ntsLogo from "../img/ntslogo.png";
import axios from 'axios';

const headersData: any[] = [
  {
    label: "ABOUT NTS",
    href: "/about",
    variant: "text",
    styleMobile: {
      marginTop: 'auto',
    },
  },
  {
    label: "CONTACT",
    href: "/contact",
    variant: "text",
  },
  {
    label: "SIGN IN",
    href: "/login",
    variant: "outlined",
    styleDesktop: {
      margin: '0px 1%',
    },
    styleMobile: {
      margin: '6% 0px',
    },
    style: {
      color: "#D71F85",
      fontSize: '16px',
      height: 'fit-content',
    }
  },
  /*{
    label: "SIGN UP",
    href: "/SignUp",
    variant: "contained",
    styleDesktop: {
      margin: '0px 1%',
    },
    styleMobile: {
      margin: '6% 0px',
    },
    style: {
      backgroundColor: "#D71F85",
      color: "#FFFFFF",
      fontSize: '16px',
      height: 'fit-content',
    }
  },*/
  {
    label: "Terms of Use",
    href: "/#",
    variant: "text",
    hideDesktop: true,
    styleMobile: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    style: {
      textDecoration: 'underline',
      fontSize: '1rem',
      fontWeight: 'normal',
    }
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    color: '#0046AD',
    boxShadow: '0px 2px 5px -1px rgb(100 160 200 / 20%)',
    padding: '5px 5%',
    height: '76px',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    float: 'left',
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    marginRight: "auto",
    display: "flex",
  	justifyContent: "center",
  	alignItems: "center",
  },
  menuButtonBase: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    textTransform: 'uppercase',
    borderColor: '#D71F85',
    borderRadius: 10,
    borderWidth: "2px",
  },
  drawerContainer: {
    textAlign: 'center',
    display: 'flex',
    width: '60vw',
    padding: '15% 0',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      padding: '6% 0',
    },
  },
  navbar: {
    display: "flex",
    justifyContent: "center", 
    alignItems: "center", 
    float: 'right',
    margin: '0 auto',
    width: '100%',
    "@media (max-width: 1300px)": {
      width: '100%',
    }
  },
  drawer: {
    top: '76px',
    '&  .MuiDrawer-paper': {
      top: '76px',
    },
    '& .MuiBackdrop-root': {
      top: '76px',
    },
  },
  drawerButtonBase: {
    fontSize: '30px',
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    width: '60%',
    borderRadius: 10, 
    borderWidth: '2px', 
    margin: '4% 0px',
    borderColor: '#D71F85',
    color: '#0046AD',
    boxShadow: 'none',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '22px',
      margin: '2% 0px',
    },
  },
}));

export default function Header() {
  const { header, logo, drawerContainer, navbar } = useStyles();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history=useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1024
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar>
        <Box className={navbar}>
          {headerLogo}
          {getMenuButtons()}
        </Box>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const toggleDrawer = () => {
      setState({ ...state, drawerOpen: !state.drawerOpen });
    }

    return (
      <Toolbar>
        <div>{headerLogo}</div>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: toggleDrawer,
            style: {
              marginLeft: "auto",
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
            anchor={"right"}
            open={drawerOpen}
            onClose={toggleDrawer}
            className={classes.drawer}
            style={{inset: 'inherit'}}
        >
        <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
      </Toolbar>
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget.innerText)
    if(event.currentTarget.innerText==='SIGN OUT')
      signOutAction();
    setAnchorEl(event.currentTarget);
  };

  const getDrawerChoices = () => {
    const isSignedIn = sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '';

    return headersData.map(({ label, href, variant, style, styleMobile }) => {
      if(label==='SIGN UP'&& isSignedIn) {
        label='SIGN OUT';
        href='';
      }
      else if(label==='SIGN IN'&& isSignedIn) {
        label='DASHBOARD';
        href='/customerDashboard';
      }

      return (
          <>
            <Button
              key={label}
              {...{
                to: href,
                component: RouterLink,
                className: classes.drawerButtonBase,
                variant: variant,
                style: {...style, ...styleMobile},
                onClick: handleClick,
              }}
            >
              {label}
            </Button>
          </>
      );
    });
  };

  const headerLogo = (
      <a href="/" className={logo}>
        <img src={ntsLogo} />
      </a>
  );

  const getMenuButtons = () => {

    const isSignedIn = sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '';

    return headersData.map(({ label, href, variant, style, styleDesktop, hideDesktop }) => {
      if(label==='SIGN UP' && isSignedIn) {
        label='SIGN OUT';
        href='';
      }
      else if(label==='SIGN IN'&& isSignedIn) {
        label='DASHBOARD';
        href='/customerDashboard';
      }
      return (  
        !hideDesktop ?   
        <Button
          {...{
            key: label,
            to: href,
            color: "inherit",
            component: RouterLink,
            className: classes.menuButtonBase,
            style: {...style, ...styleDesktop},
            variant: variant,
          }}
          onClick={handleClick}
        >
          {label}
        </Button>
      : <></>);
    });
  };

  const signOutAction = () => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}user/logout`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
    }).then(res => {
      console.log('logout success');
      sessionStorage.clear();
      history.push('/')
    }).catch(error => {
      console.log('exception');
      console.log(error.response.data.error);
    });
  }

  return (
    <header>
      <AppBar className={header} elevation={0}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>

  );
}
