/* eslint-disable */
import { Button, Checkbox, createStyles, Grid, Hidden, makeStyles, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Theme, Typography, withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import NavSidebar from '../NavSideBar'
import DSPHeader from '../DashBoardHeader'
import DSPBreadCrums from '../DSPPages/DSPCreation/DSPBreadCrums'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    [theme.breakpoints.up('md')]: {
      paddingTop: '80px',
    },
    paddingTop: '64px',
  },
  breadcumsWrapper: {
    margin: '15px 0 0 45px'
  },
  titleWrapper: {
    margin: '15px 0',

  },
  titleText: {
    color: '#0046AD',
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  tableContainer: {
    boxShadow: 'unset',
    '& th': {
      padding: '20px 0 20px 21px',
      fontSize: '20px'
    },
    '& $editWrapper': {
      padding: '20px 0 20px 67px'
    }
  },
  editWrapper: {},
  editTextField: {
    width: '100%',
    marginLeft: '10px',
    '& .MuiOutlinedInput-input': {
      fontSize: '20px',
      padding: '10px'
    }
  },
  editWrapperGrid: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  noteText: {
    color: '#0046AD',
    fontSize: '20px',
    margin: '10px 0'
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '35px'
  },
  submitBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#0046AD',
    padding: '8px 80px',
    border: '1px solid transparent',
    marginRight: '3rem',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',

    }
  },
  cancelBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#818A8F',
    padding: '8px 80px',
    border: '1px solid #707070',
    marginRight: '3rem',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#818A8F',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    }
  }
}))
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 41,
      height: 22,
      padding: 0,
      overflow: 'unset'
    },
    switchBase: {
      padding: 0,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#009B74',
          borderColor: '#009B74',
        },
        '& $thumb': {
          background: 'white',
          border: '2px solid #009B74',
          margin: 0
        }
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
      background: '#333333',
      margin: '2px',
      border: 'none',
      '&$checked': {

      }
    },
    track: {
      border: `1px solid #009B74`,
      borderRadius: 11,
      opacity: 1,
      backgroundColor: 'transparent',
      borderColor: '#333333',
    },
    checked: {},
  }),
)(Switch);

interface userInfoField {
  id: number,
  title: string,
  value: string
  changed: boolean,
  editValue: string,
  fieldName?: string
}

interface User {
  city: string,
  country: string,
  email: string,
  name: string
  status: string,
  profession: string,
  province: string
}

const ModalStyle = makeStyles((theme) => ({
  userActivationModal: {
    '& .MuiDialogTitle-root': {
      padding: '60px 90px 0',
      '& h2': {
        fontSize: '24px',
        fontWeight: 'bold',
        fontFamily: '"Arial"'
      }
    },
    '& .MuiDialogContent-root': {
      padding: '24px 35px 80px 45px',
      '& p': {
        fontSize: '20px',
        color: '#333333',
        fontFamily: '"Arial"'
      }
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center'
    }
  },
  btnGroup: {
    padding: '0 45px 50px',
  },
  submitBtn: {
    color: 'white',
    borderRadius: '5px',
    backgroundColor: '#0046AD',
    padding: '5px 35px',
    border: '1px solid transparent',
    fontSize: '20px',
    fontWeight: 700,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    }
  },
  cancelBtn: {
    color: 'white',
    borderRadius: '5px',
    backgroundColor: '#818A8F',
    padding: '5px 70px',
    border: '1px solid #707070',
    fontSize: '20px',
    marginRight: '3rem',
    fontWeight: 700,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
      borderRadius: '5px',
      backgroundColor: '#818A8F',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    }
  }
}))

const EditConfirmationModal = (props: any): JSX.Element => {
  const classes = ModalStyle();
  const history = useHistory();
  return (
    <>
      <Dialog
        open={props.openEditConfirmationModal}
        onClose={() => props.closeModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.userActivationModal}
      >
        <DialogTitle id="alert-dialog-title" >
          Request Sent Successfully!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for your request. Our admin team will
            review your request and contact you if additional
            information is required.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnGroup}>
            <Button variant="contained" color="primary" className={classes.submitBtn} onClick={() => history.push('/myTeam')}>
              Done
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StaffEdit = (props: any): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User>()

  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };
  const [selectmenu, setSelectMenu] = useState('staffListDashBoard')

  const dummyData: userInfoField[] = [
    {
      id: 1,
      title: 'Name',
      value: 'Firstname Lastname',
      changed: false,
      editValue: "",
      fieldName: 'name'
    },
    {
      id: 2,
      title: 'Email',
      value: 'Firstname.Lastname@gmail.com',
      changed: false,
      editValue: "",
      fieldName: 'email'

    },
    {
      id: 3,
      title: 'Profession/Sector',
      value: 'Early Learning Example',
      changed: false,
      editValue: "",
      fieldName: 'profession'
    },
    {
      id: 4,
      title: 'Location',
      value: 'City, Province, Country',
      changed: false,
      editValue: "",
      fieldName: 'location'
    },
    {
      id: 5,
      title: 'Organization/Agency',
      value: 'Organization Name',
      changed: false,
      editValue: "",
    },
    {
      id: 6,
      title: 'Team Leader',
      value: 'Organization Name',
      changed: false,
      editValue: "",
    },
  ]

  const [userInfo, setUserInfo] = useState<userInfoField[]>([])

  useEffect(() => {
    console.log(0);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}user/profile/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      }
    }).then(res => {
      console.log(1);
      //setUser(res.data)
      let user: User = res.data;
      dummyData.forEach(field => {
        if (field.fieldName) {
          if (field.fieldName === "location") {
            field.value = user.city + ', ' + user.province + ', ' + user.country
          } else {
            let fieldname = field.fieldName as keyof User
            field.value = user[fieldname]
          }
        }
      })
      setUserInfo(dummyData)
    }).catch(error => {
      console.log(2);
      history.push('/customerDashBoard');
    });
  }, [])

  const handleToggleTextField = (e: any, id: number) => {
    let userInfoArr = userInfo;
    let infoIndex = userInfoArr.findIndex(item => id === item.id)

    if (infoIndex !== -1) {
      // console.log("handleToggleTextField: ", { id }, e.target.checked, userInfoArr[infoIndex]);
      userInfoArr[infoIndex].changed = !userInfoArr[infoIndex].changed
      setUserInfo([...userInfoArr])
    }

  }

  const handleChangeTextField = (e: any, id: number) => {
    //    console.log("handleChangeTextField: ", { id });
    let userInfoArr = userInfo;
    let infoIndex = userInfoArr.findIndex(item => id === item.id)
    if (infoIndex !== -1) {
      userInfoArr[infoIndex].editValue = e.target.value
      setUserInfo([...userInfoArr])
    }
  }

  const submitUserEdit = () => {
    console.log({ userInfo });
    let newCity = '', newProvince = '', newCountry = ''
    if (userInfo[3].editValue !== "") {
      let newVals = userInfo[3].editValue.split(',');
      newCity = newVals[0]
      newProvince = newVals[1]
      newCountry = newVals[2]
    }
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}user/profile/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
      data: {
        name: userInfo[0].editValue === "" ? userInfo[0].value : userInfo[0].editValue,
        email: userInfo[1].editValue === "" ? userInfo[1].value : userInfo[1].editValue,
        profession: userInfo[2].editValue === "" ? userInfo[2].value : userInfo[2].editValue,
        city: newCity,
        province: newProvince,
        country: newCountry,
        organization: "",
        teamLeader: ""
      },
    }).then(res => {
      console.log({ res });
      if (res.status === 200 || res.status === 201) {
        setOpenEditConfirmationModal(true)
      }

    }).catch(error => {
      console.log('exception');
      console.log(error.response.data);
    });
  }
  const [openEditConfirmationModal, setOpenEditConfirmationModal] = useState(false);
  return (
    <>
 
      <DSPHeader
        handleToggleSidebar={handleToggleSidebar}
        toggled={toggled}
        pageName={selectmenu}
      />
      <Grid container component="main" className={classes.root}>

        <EditConfirmationModal
          openEditConfirmationModal={openEditConfirmationModal}
          closeModal={() => setOpenEditConfirmationModal(false)}
        />
        <Hidden smDown={!toggled}>
          <Grid item className={`app ${toggled ? 'toggled' : ''}`}>
            <NavSidebar
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              setSelectMenu={setSelectMenu}
              selectmenu={selectmenu}
            />
          </Grid>
        </Hidden>
        <Grid item md={9} className={classes.breadcumsWrapper}>
          <DSPBreadCrums
            setSession={props.setSession}
            session={props.session}
            setExpandMenu={props.setExpandMenu}
            items={[
              { name: 'My Team', link: 'staffListDashBoard' },
              { name: 'Firstname Lastname', link: '' },
              { name: 'Change Information', link: '' },
            ]}
          />
          <div className={classes.titleWrapper}>
            <Typography
              variant='h4'
              className={classes.titleText}
            >
              Change Information
            </Typography>
            <p className={classes.noteText}>
              Please select the item(s) below you would like to change:
            </p>
          </div>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="simple table">
              <TableBody>
                {userInfo.map(row => {
                  return (
                    <>
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <Checkbox
                            checked={row.changed}
                            onChange={(e) => handleToggleTextField(e, row.id)}
                            name={row.title}
                            color="primary"
                          />
                          <b>{row.title}:</b> {row.value}
                        </TableCell>
                      </TableRow>
                      {row.changed && (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row" className={classes.editWrapper}>
                            <Grid container>
                              <Grid item md={9} className={classes.editWrapperGrid}>
                                <b>New {row.title}: </b>
                                <TextField
                                  value={row.editValue}
                                  id="outlined-basic"
                                  onChange={(e) => handleChangeTextField(e, row.id)}
                                  variant="outlined"
                                  className={classes.editTextField}
                                />
                              </Grid>
                            </Grid>

                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )

                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.btnGroup}>
            <Button variant="contained" color="primary" className={classes.cancelBtn}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" className={classes.submitBtn} onClick={() => submitUserEdit()}>
              Submit
            </Button>
          </div>

        </Grid>

      </Grid>

    </>
  )
}

export default StaffEdit