/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Footer from './Footer';
import HomepageDevices from '../img/herobannerpic.png';
import Section1Background from '../img/homepage_section1_background.png';
import Section1BackgroundPhone from '../img/homepage_section1_background-768.png';
import Section2Background from '../img/homepage_section2_background.png';
import Section4Background from '../img/homepage_section4_background.png';
import EasyToNavigateIcon from '../img/homepage_easy_to_navigate_icon.png';
import TimeEfficientIcon from '../img/homepage_time_efficient_icon.png';
import FamilyFriendlyIcon from '../img/homepage_family_friendly_icon.png';
import PortableIcon from '../img/homepage_portable_icon.png';
import CommunicationIcon from '../img/homepage_communication_icon.svg';
import GrossMotorIcon from '../img/homepage_gross_motor_icon.svg';
import FineMotorIcon from '../img/homepage_fine_motor_icon.svg';
import ProblemSolvingIcon from '../img/homepage_problem_solving_icon.svg';
import SocialEmotionalIcon from '../img/homepage_social_emotional_icon.svg';
import WelcomeHorizontalImage from './/../img/homepage_welcome_horizontal.png';
import WelcomeVerticalImage from '../img/homepage_welcome_vertical.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: '#0046AD',
  },
  firstTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '60px',
    },
  },
  title: {
    fontSize: 'calc(28px + (48 - 28) * ((100vw - 300px) / (1600 - 300)))',
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.5',
    },
    fontWeight: 'bold'
  },
  section1Description: {
    fontSize: 'calc(19px + (32 - 19) * ((100vw - 300px) / (1600 - 300)))',
    lineHeight: 1.1,
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.2',
    },
  },
  subtitle: {
    fontSize: 'calc(24px + (45 - 24) * ((100vw - 300px) / (1600 - 300)))',
    lineHeight: 1.1,
    fontWeight: 'bold'
  },
  description: {
    fontSize: 'calc(17px + (24 - 17) * ((100vw - 300px) / (1600 - 300)))',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.5,
    },
    lineHeight: 2,
  },
  section: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      backgroundSize: 'cover',
    },
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
  },
  gridItem: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center'
    },

    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: 'calc(150px + (300 - 150) * ((100vw - 300px) / (1600 - 300)))',
    background: 'linear-gradient(0deg, #002357, #0044A9)',
    marginBottom: '40px',
    color: "#FFFFFF",
    fontSize: '20px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  link: {
    color: '#156670',
    fontSize: '22px',
    marginTop: '0',
    marginBottom: '2%',
    display: 'flex',
    justifyContent: 'left',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    fontWeight: 'bold',
  },
  secondSectionCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondSectionCardContainer: {
    width: '80%',
    marginLeft: '40px',
    position: 'relative',
  },
  secondSectionCard: {
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 1,
    shadowRadius: 2,
    textAlign: 'left',
    paddingLeft: '40px',
    marginBottom: '10px',
    marginTop: '10px',
    color: '#0046AD',
    '&  .MuiCardHeader-title': { fontWeight: 'bold' },
    lineHeight: '1.5',
    [theme.breakpoints.down('xs')]: {
      '&  .MuiCardHeader-title': { fontSize: '20px' },
      fontSize: '14px',
      marginTop: '25px',
      marginBottom: '25px',
    },
  },
  secondSectionCardIcon: {
    position: "absolute",
    width: '104px',
    top: 0,
    bottom: 0,
    left: -60,
    margin: "auto",
  },
  thirdSectionCard: {
    margin: 'auto',
    width: '80%',
    marginBottom: '5%',
  },
  thirdSectionCardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    color: '#333333',
    fontSize: 'calc(13px + (24 - 13) * ((100vw - 300px) / (1600 - 300)))',
    "&:last-child": {
      paddingBottom: 'calc(5px + (15 - 5) * ((100vw - 300px) / (1600 - 300)))'
    },
    padding: 'calc(5px + (15 - 5) * ((100vw - 300px) / (1600 - 300)))',
    [theme.breakpoints.down('xs')]: {
     // padding: '5px',
     '& img':{
       width: '30%'
     }
      
    },
    overflowWrap: 'anywhere',
    fontWeight: 'bold',
  }
}));

export default function HomePage(): JSX.Element {
  const styles = useStyles();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const history = useHistory();
  function handleWindowSizeChange() {
    setScreenWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const renderSecondSectionCards = () => {
    const cards =
      [
        {
          title: 'Easy to Navigate',
          description: 'Quickly navigating and locating developmental strategies by developmental area.',
          color: '#E1F3F7',
          shadowColor: '#00A1C7',
          image: EasyToNavigateIcon,
        },
        {
          title: 'Time Efficient',
          description: 'Easily creating a printable developmental support plan without any formatting effort.',
          color: '#F8EDE4',
          shadowColor: '#D36A20',
          image: TimeEfficientIcon,
        },
        {
          title: 'Family-Friendly',
          description: 'Collaboratively ensuring caregivers’ expertise and knowledge of the child are reflected in the plan.',
          color: '#FBF1F7',
          shadowColor: '#C91D7C',
          image: FamilyFriendlyIcon,
        },
        {
          title: 'Portable',
          description: 'Being able to create a plan anywhere by using a mobile device and easily sharing with families.',
          color: '#E7F1E6',
          shadowColor: '#3A8F31',
          image: PortableIcon,
        },

      ]

    return (
      <div className={styles.secondSectionCardsContainer}>
        {
          cards.map((card) => {
            return (
              // <div>
              <span className={styles.secondSectionCardContainer}>
                <Card className={styles.secondSectionCard} style={{ backgroundColor: card.color, boxShadow: "5px 5px 0px " + card.shadowColor }} >
                  <CardHeader title={card.title} />
                  <CardContent style={{ paddingTop: '0px' }}>
                    {card.description}
                  </CardContent>
                </Card>
                <img className={styles.secondSectionCardIcon} src={card.image} alt="icon" />
              </span>
              // </div>
            )
          })
        }
      </div>
    )
  }

  const renderThirdSectionCards = () => {
    const cards =
      [
        {
          title: 'Communication',
          image: CommunicationIcon,
        },
        {
          title: 'Gross Motor',
          image: GrossMotorIcon,
        },
        {
          title: 'Fine Motor',
          image: FineMotorIcon,
        },
        {
          title: 'Problem-Solving',
          image: ProblemSolvingIcon,
        },
        {
          title: 'Social-Emotional',
          image: SocialEmotionalIcon,
        },
      ]

    return (
      <Grid container>
        {
          cards.map((card) => {
            return (
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Card className={styles.thirdSectionCard} >
                  <CardContent className={styles.thirdSectionCardContent}>
                    <img src={card.image} />
                    <p style={{ display: "inline", marginLeft: '5%' }}>
                      {card.title}
                    </p>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    )
  }

  const Section1BackgroundImage = screenWidth > 1200 ? Section1Background : Section1BackgroundPhone;
  
  return (
    <>
      <Header />
      <Container maxWidth={false} disableGutters={true}>
        <div className={styles.root}>


          {/* section 1 */}
          <Grid container item className={styles.section} style={{
            // columnGap: '4%',
            paddingTop: '10%',
            paddingBottom: '5%',
            backgroundImage: 'url(' + Section1BackgroundImage + ')'
          }}>
           
            <Grid item xs={10} sm={10} md={10} lg={5}>
              <p className={`${styles.title} ${styles.firstTitle}`}>
                The Journey to Infant and Early Mental Health
              </p>
              <p className={styles.section1Description}>Supporting the developmental needs of Indigenous Children from birth to six.</p>

              <br />
{/*
             <div style={{ color: '#FFFFFF' }}>
                <Button className={styles.button} fullWidth variant="contained" color="primary" onClick={() => history.push('/signup')} >
                  Sign up!
                </Button>

              </div>
        */}

            </Grid>

            <Grid item className={styles.gridItem} xs={false} sm={10} md={10} lg={5}>
              <img src={HomepageDevices} style={{ width: '80%', maxWidth: '600px' }} />
            </Grid>
          </Grid>

          {/* section 2 */}
          <Grid container item className={styles.section} style={{
            paddingTop: '5%',
            paddingBottom: '2%',
            backgroundImage: 'url(' + Section2Background + ')',
          }}>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <p className={styles.subtitle}>
                About
              </p>

              <p className={styles.description}>
              The Nurturing the Seed App is an online developmental support planning tool (DSP) that helps frontline practitioners create a developmental support plan for young Indigenous children based on the child’s strengths and developmental needs. 
              </p>

              <br />

             

              <br />

              {renderSecondSectionCards()}

            </Grid>
          </Grid>

          {/* section 3 */}
          <Grid container item className={styles.section} style={{ paddingTop: '5%' }}>

            <Grid item xs={10} sm={10} md={10} lg={10}>
              <p className={styles.subtitle}>
                The Developmental Support Plan
              </p>

              <p className={styles.description}>
              A Developmental Support Plan is created based on the child’s strengths, and supports the child from a developmental perspective, while keeping in mind the unique needs of the family. 
              </p>

              <br />

             

              <br />

              {renderThirdSectionCards()}

            </Grid>
          </Grid>

          {/* section 4 */}
          <Grid container item className={styles.section} style={{
            paddingTop: '8%',
            paddingBottom: '8%',
            backgroundImage: 'url(' + Section4Background + ')',
          }}>

            <Grid item xs={10} sm={10} md={10} lg={6}>
              <p className={styles.title}>
                New Communities Welcome!
              </p>
              <p className={styles.description}>
                
              Want to make the most of this app and further promote the wellness of young Indigenous children and their families?  
              <br/>
              <Link to="/contact" style={{ color: '#156670'}}>Contact us</Link> to join the NtS Study today
              </p>

              <br /><br />

             

            </Grid>

            <Hidden lgUp>
              <Grid item xs={false} sm={8} md={8} lg={false}>
                <img src={WelcomeHorizontalImage} style={{ width: "80%" }} />
              </Grid>
            </Hidden>

            <Hidden mdDown>
              <Grid item className={styles.gridItem} xs={false} sm={false} md={false} lg={4}>
                <img src={WelcomeVerticalImage} style={{ height: '100%' }} />
              </Grid>
            </Hidden>
          </Grid>


        </div>
      </Container>
      <Footer />
    </>
  );
}