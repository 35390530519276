/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";


const  VerifyAccessToken=()=>{

  const history=useHistory();
    useEffect(()=>{
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/verifyToken`,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-type': 'application/json',
              'api_key': process.env.REACT_APP_API_KEY,
              'access_token': sessionStorage.getItem('token'),
              crossdomain: true
            },
           
          }).then(res => {
         
          }).catch(error => {
            if(error.response!.status===401)
            {
              console.log('invalid token');
              sessionStorage.clear();
              history.push('/')
            }
            
          });

    },[])
   return(<></>)
  
}

export default VerifyAccessToken;