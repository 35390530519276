/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { makeStyles } from "@material-ui/core/styles";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import sidebarBgDesktop from '../../img/sidebarBg3_desktop.png';
import sidebarBgMobile from '../../img/sidebarBg3_mobile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import ntsLogo from "../../img/ntslogo.png";
import axios from "axios";


const useStyles = makeStyles((theme) => ({

  sidebar: {
    width: 'fit-content',
    height: 'fit-content',
    color: 'white',
    minWidth: '224px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '370px',
    },
    '& > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content': {
      flexGrow: 'unset',
    },
    '&.toggled > .pro-sidebar-inner > .pro-sidebar-layout': {
      overflowY: 'auto',
    },
    '& > .pro-sidebar-inner > .pro-sidebar-layout': {
      overflowY: 'hidden'
    },
    '& .pro-sidebar-inner': {
      background: `url(${sidebarBgDesktop})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

    },
    '& > .pro-sidebar-inner > img.sidebar-bg': {
      opacity: 'unset'
    },
    '& .pro-menu.circle .pro-menu-item > .pro-inner-item > .pro-icon-wrapper': {
      borderRadius: '0',
      backgroundColor: 'transparent'
    },
    '& svg': {
      height: '1.75em',
      color: 'white'
    },
    '& .pro-item-content': {
      color: 'white',
      fontWeight: '700',
      fontSize: '16px',
      textAlign: 'left'
    },
    '& .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper > .pro-icon > span': {
      color: 'white',
      fontSize: '32px'
    },
    '& .pro-menu .pro-menu-item.active > .pro-inner-item > .pro-icon-wrapper > .pro-icon > span': {
      color: '#0046AD'
    },
    '& > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header': {
      borderBottom: 'none'
    },
    '& > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer': {
      border: 'none'
    },

    '&.collapsed .pro-menu > ul > .pro-menu-item$collapsed_submenu > .pro-inner-item': {
      pointerEvents: 'all'
    },
    '&.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item': {
      minWidth: '160px'
    },
    '&.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner': {
      paddingLeft: 0,
      backgroundColor: 'white',
      border: '4px solid #CCE6FF',
      borderRadius: '10px',
      position: 'relative',
      overflowY: 'unset',
      '&:before, &:after': {
        right: '100%',
        top: '50%',
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
      },
      '&:after': {
        borderColor: 'rgba(255, 255, 255, 0)',
        borderRightColor: '#fff',
        borderWidth: '4px',
        marginTop: '-4px'
      },
      '&:before': {
        borderColor: 'rgba(204, 230, 255, 0)',
        borderRightColor: '#CCE6FF',
        borderWidth: '10px',
        marginTop: '-10px'
      }

    },
    '&.collapsed .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul': {
      paddingTop: 0,
      paddingBottom: 0
    },
    '&.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item': {
      color: '#0046AD',
      padding: '8px',
      background: 'white',
      '& .pro-item-content': {
        color: '#0046AD',
        textAlign: 'left'
      }
    },

    //tablet
    [theme.breakpoints.only('sm')]: {

      '&.md.toggled': {
        position: 'absolute',
        width: '360px',
      },
      '&.toggled > .pro-sidebar-inner > .pro-sidebar-layout': {
        overflowY: 'auto',
        padding: '45px 60px 0 55px',
      },
      '& > .pro-sidebar-inner': {
        height: 'auto',
        color: 'white',
        background: `url(${sidebarBgMobile})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      '&.md.toggled .overlay': {
        // backdropFilter: 'blur(6px)'
      }
    },

    //mobile
    [theme.breakpoints.down('xs')]: {
      '&.md.toggled': {
        position: 'absolute',
        minWidth: '100vw',
        textAlign: 'center'
      },
      '& > .pro-sidebar-inner': {
        height: 'auto',
        color: 'white',
        background: `url(${sidebarBgMobile})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      '&.toggled > .pro-sidebar-inner > .pro-sidebar-layout': {
        overflowY: 'auto',
        padding: '30px',
      },
      '& > .pro-sidebar-inner > .pro-sidebar-layout ul': {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
  },
  sidebar_content: {
    '& .pro-menu': {
      width: '100%',
      paddingTop: 0,
      paddingBottom: 0
    },
    '& .pro-menu-item': {
      marginBottom: '5%'
    },
    '& .pro-menu-item.logout_btn': {
      marginTop: '90px',
      marginBottom: '80px'
    },
    '& .pro-menu .pro-menu-item > .pro-inner-item': {
      // width: '70%',
      marginLeft: '10px',
      marginRight: '20px',
      color: 'white',
      padding: '10px',
      transition: 'color 0.3s, background 0.2s',
      background: 'transparent',
      borderRadius: '10px'
    },
    '& .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper': {
      width: '32px',
      minWidth: '32px',
      height: '32px',
      lineHeight: '32px',
    },
    '& .pro-menu .pro-menu-item.active .pro-inner-item': {
      background: 'white',
      borderRadius: '10px'
    },
    '& .pro-menu .pro-menu-item:hover > .pro-inner-item': {
      background: 'white',
      borderRadius: '10px'
    },
    '& .pro-inner-item .pro-item-content': {
      color: 'white',
      transition: 'color 0.3s, background 0.2s',
    },
    '& .pro-inner-item svg': {
      color: 'white',
      transition: 'color 0.3s, background 0.2s',
    },
    '& .pro-menu-item:hover > .pro-inner-item .pro-item-content': {
      color: '#0046AD'
    },
    '& .pro-menu .pro-menu-item.active > .pro-inner-item .pro-item-content': {
      color: '#0046AD'
    },
    '& .pro-menu .pro-menu-item.active > .pro-inner-item > .pro-icon-wrapper > .pro-icon > span': {
      color: '#0046AD'
    },
    '& .pro-menu .pro-menu-item:hover > .pro-inner-item > .pro-icon-wrapper > .pro-icon > span': {
      color: '#0046AD'
    },
    //tablet
    [theme.breakpoints.only('sm')]: {
      '& .pro-inner-item .pro-item-content': {
        color: 'white',
        transition: 'color 0.3s, background 0.2s',
      },
      '& .pro-inner-item svg': {
        color: '#0046AD',
        transition: 'color 0.3s, background 0.2s',
      },
      '& nav': {
        margin: 'auto',
      },
    },
    //mobile
    [theme.breakpoints.down('xs')]: {
      '& .pro-inner-item .pro-item-content': {
        color: 'white',
        transition: 'color 0.3s, background 0.2s',
        overflow: 'unset !important'
      },
      '& .pro-menu .pro-menu-item > .pro-inner-item': {
        margin: 0,
        paddingLeft: 0
      },
      '& .pro-inner-item .pro-icon span': {
        color: '#0046AD',
        transition: 'color 0.3s, background 0.2s',
      },
      '& nav': {
        margin: 'auto',
        width: '50%'
      },
      '& .pro-menu-item': {
        width: '100%'
      },
      '& .pro-menu .pro-menu-item:hover > .pro-inner-item > .pro-icon-wrapper > .pro-icon > span': {
        color: '#0046AD'
      },
    }
  },

  collapse_btn: collapsed => ({
    position: 'absolute',
    right: collapsed ? '7%' : '15%',
    top: '55%',
    borderRadius: '50%',
    border: 'none',
    background: 'white',
    boxShadow: '0 0 3px grey',
    padding: '2px 7px',
    '& svg': {
      color: '#0046AD'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      right: '17%',
    },
  }),
  collapsed_submenu: {
    '& > .pro-inner-item': {
      pointerEvents: 'all !important'
    },
    '& > .popper-inner': {
      paddingLeft: 0,

    },
    '& > .pro-inner-list-item > div > ul': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  logo: {
    float: 'left',
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    marginLeft: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sidebar_header: {
    margin: '30px 0 16px 25px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    //tablet
    [theme.breakpoints.only('sm')]: {

      color: 'white',

    },
    //mobile
    [theme.breakpoints.down('xs')]: {

      color: 'white',

    },

  },

}));

export default function NavSidebar(props: any): JSX.Element {

  const [collapsed, setCollapsed] = useState(false);
  const classes = useStyles(collapsed);
  const history = useHistory();
  


  const handleCollapsedChange = (checked: boolean) => {
    setCollapsed(checked);
  };

  const headerLogo = (
    <a href="/" className={classes.logo}>
      <img src={ntsLogo} />
    </a>
  );

  const logout = () => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}user/logout`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      }

    }).then(res => {
    }).catch(error => {
      console.log('exception');

    });
    sessionStorage.clear();
    history.push('/');
  };

  return (
    <>
      <ProSidebar
        //image={sidebarBg}
        collapsed={props.toggled}
        breakPoint="md"
        toggled={props.toggled}
        onToggle={props.handleToggleSidebar}
        className={classes.sidebar}
        //onClick={() => props.handleToggleSidebar(!props.toggled)}
      >
        
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none' }, margin: '2rem auto 0' }}>
          {headerLogo}
        </Box>
        
        <SidebarHeader>
          <div
            className={classes.sidebar_header}
          >
            {!collapsed ? "Menu" : ""}
          </div>
        </SidebarHeader>

        <SidebarContent className={classes.sidebar_content}>

          <Menu>
            {collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  edit_document
                </span>}
                className={classes.collapsed_submenu}
                onClick={() => {  props.setSelectMenu('dsp')}}
              >
                <MenuItem>
                  Create/Edit Plan
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                icon={<span className="material-symbols-rounded">
                  edit_document
                </span>}
                data-tooltip='Create New Plan'
                onClick={() => window.location.reload()}//{  props.selectmenu != 'dsp1'?props.setSelectMenu('dsp'):props.setSelectMenu('dsp') }}
                active={props.selectmenu != 'dsp!'?props.selectmenu == 'dsp':props.selectmenu == 'dsp1'}
              >
                Create/Edit Plan
              </MenuItem>
            )}

            {collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  saved_search
                </span>}
                className={classes.collapsed_submenu}
                onClick={() => { props.setSelectMenu('strategy');props.handleToggleSidebar(false) }}
              >
                <MenuItem>
                  Explore Strategies
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  saved_search
                </span>}
                active={props.selectmenu == 'exploreStrategies'}
                onClick={() => { props.setSelectMenu('strategy'); props.handleToggleSidebar(false)}}

              >
                Explore Strategies
              </MenuItem>
            )}

            {collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  help
                </span>}
                className={classes.collapsed_submenu}
                onClick={() => { props.setSelectMenu('help');props.handleToggleSidebar(false)  }}
              >
                <MenuItem >
                  Help Centre
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  help
                </span>}
                 active={props.selectmenu == 'help'}
                 onClick={() => { if(props.selectmenu === 'help')props.setSelectMenu('help1');else props.setSelectMenu('help');props.handleToggleSidebar(false) }}
              >
                Help Centre
              </MenuItem>
            )}



            {collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  mail
                </span>}
                className={classes.collapsed_submenu}
                onClick={() => { props.setSelectMenu('contactUs');props.handleToggleSidebar(false)  }}
              >
                <MenuItem active={props.selectmenu === 'contactUs'}>
                  Contact Us
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  mail
                </span>}
                onClick={() => { props.setSelectMenu('contactUs');props.handleToggleSidebar(false) }}
                active={props.selectmenu === 'contactUs'}
              >
                Contact Us
              </MenuItem>
            )}
          </Menu>

        </SidebarContent>


        <SidebarHeader>
          <div
            className={classes.sidebar_header}
          >
            {!collapsed ? "Account" : ""}
          </div>
        </SidebarHeader>

        <SidebarContent className={classes.sidebar_content}>
          <Menu>
            {collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  account_circle
                </span>}
                className={classes.collapsed_submenu}
                onClick={() => { props.setSelectMenu('profile');props.handleToggleSidebar(false)}}
              >
                <MenuItem>
                  My Profile
                </MenuItem>
              </SubMenu>
              
            ) : (
              
                <MenuItem
                  icon={<span className={`material-symbols-rounded menu_icon`}>
                    account_circle
                  </span>}
                  onClick={() => { props.setSelectMenu('profile');props.handleToggleSidebar(false)}}

                  active={props.selectmenu === 'My Profile'}
                >
                  My Profile
                </MenuItem>
            )}

            {/*{(sessionStorage.getItem('userRole')==='A'||sessionStorage.getItem('userRole')==='M'||sessionStorage.getItem('userRole')==='S')?(collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  group
                </span>}
                onClick={() => { props.setSelectMenu('myTeam') }}           
              
              >
                <MenuItem>
                My Team
                </MenuItem>
              </SubMenu>
              
            ) : (
              
                <MenuItem
                  icon={<span className={`material-symbols-rounded menu_icon`}>
                    group
                  </span>}
                   onClick={() => { props.setSelectMenu('myTeam') }}           
                   active={props.selectmenu === 'My Team'}
                >
                  My Team
                </MenuItem>
            )):''}*/}
            {(sessionStorage.getItem('userRole')==='S') ?(collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  manage_accounts
                </span>}
                onClick={() => { props.setSelectMenu('admin') }}           
              
              >
                <MenuItem>
                Admin Site
                </MenuItem>
              </SubMenu>
              
            ) : (
              
                <MenuItem
                  icon={<span className={`material-symbols-rounded menu_icon`}>
                    manage_accounts
                  </span>}
                    onClick={() => { if(props.selectmenu === 'admin')props.setSelectMenu('admin1');else props.setSelectMenu('admin');props.handleToggleSidebar(false) }}
                       
                   active={props.selectmenu === 'admin'}
                >
                Admin Site
                </MenuItem>
            )):''}
            

               
          </Menu>

        </SidebarContent>



        <SidebarFooter className={classes.sidebar_content}>
          <Menu>
            {collapsed ? (
              <SubMenu
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  logout
                </span>}
                className={`${classes.collapsed_submenu} logout_btn`}
                onClick={() => logout()}
              >
                <MenuItem>
                  Log Out
                </MenuItem>
              </SubMenu>
            ) : (
              <MenuItem
                icon={<span className={`material-symbols-rounded menu_icon`}>
                  logout
                </span>}
                className={`logout_btn`}
                onClick={() => logout()}
              >
                Log Out
              </MenuItem>
            )}


          </Menu>
        </SidebarFooter>

        {/* <button
          className={classes.collapse_btn}
          onClick={() => handleCollapsedChange(!collapsed)}

        >

          <span>
            {collapsed ?
              <FontAwesomeIcon icon={faChevronRight} /> : <FontAwesomeIcon icon={faChevronLeft} />}
          </span>
        </button> */}
      </ProSidebar>

    </>
  );
}




function setIsLoginSuccess(arg0: boolean) {
  throw new Error("Function not implemented.");
}