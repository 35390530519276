/* eslint-disable no-unused-vars */

import { Grid, Hidden, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import manageWideImg from '../../../img/User_Management_btn.png';
import strategyWideImg from '../../../img/Strategy_Database_btn.png';
import {globalStyles} from "../../../GlobalStyles";
import { AllUserList } from "./AllUserList";
import { AdminContext, AdminProvider, useAdminContext } from "./AdminProvider";



const useStyles = makeStyles((theme) => ({
  newDsplistIcon: {

    '&:hover': {
      cursor: "pointer"
    },
  },
  updateDsplistIcon: {

    '&:hover': {
      cursor: "pointer"

    },
  },
  listText: {
    fontWeight: 600,

    margin: '0 auto',
  },
  listContainer: {
    textAlign: 'center',
  },
}));

const AdminCenter = (props:any): JSX.Element => {
  const classes = useStyles();
  const globalClasses=globalStyles();
  const {section,setSection} = useAdminContext();

  
  return (
    <>
   
     {section==='userManage'&&<AllUserList></AllUserList>}
      {section==='admin'&&<>
        <Grid item xs={12}>
              <Typography
                variant='h4'
                className={globalClasses.titleText}
              >
                System Admin
              </Typography>
            </Grid><Grid container style={{ marginTop: '-15px' }}>


            <Hidden smDown>
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={()=>{console.log('userManage');setSection('userManage')}}>
              <img src={manageWideImg} alt='User Management' className={classes.newDsplistIcon} />
      
            </Grid>
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={()=>setSection('strategy')}>
            <img src={strategyWideImg} alt='Strategy' className={classes.updateDsplistIcon} />
            </Grid>
            </Hidden>

            <Hidden mdUp>
              <Grid item xs={12}  alignItems='center' justifyContent="center" style={{marginTop:'60px'}} >
            <Grid item xs={12} md={6} className={classes.listContainer} onClick={()=>{console.log('userManage');setSection('userManage')}}>
            <img src={manageWideImg} alt='User Management' className={classes.newDsplistIcon} style={{ width: '70%' }} />
      
            </Grid>
            <Grid item xs={12} md={6} className={classes.listContainer}  onClick={()=>setSection('strategy')}>
            <img src={strategyWideImg} alt='Strategy' className={classes.updateDsplistIcon} style={{ width: '70%' }} />
        </Grid>
        </Grid>
              </Hidden>

      </Grid>
    </>
    }

    </>
  )

}

export default AdminCenter;
