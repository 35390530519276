/* eslint-disable */
import { Button, createStyles, Grid, Hidden, makeStyles, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Theme, Typography, withStyles } from '@material-ui/core'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import NavSidebar from '../NavSideBar'
import DSPHeader from '../DashBoardHeader'
import DSPBreadCrums from '../DSPPages/DSPCreation/DSPBreadCrums'

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    [theme.breakpoints.up('md')]: {
      paddingTop: '90px',
    },
    paddingTop: '64px',
  },
  breadcumsWrapper: {
    margin: '15px 0 0 45px'
  },
  titleWrapper: {
    margin: '15px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    color: '#0046AD',
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  tableContainer: {
    boxShadow: 'unset',
    '& th': {
      padding: '25px 0 25px 21px',
      fontSize: '20px'
    }
  },
  noteText: {
    color: '#0046AD',
    fontSize: '20px',
    margin: '10px 0'
  }
}))
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 41,
      height: 22,
      padding: 0,
      overflow: 'unset'
    },
    switchBase: {
      padding: 0,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#009B74',
          borderColor: '#009B74',
        },
        '& $thumb': {
          background: 'white',
          border: '2px solid #009B74',
          margin: 0
        }
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
      background: '#333333',
      margin: '2px',
      border: 'none',
      '&$checked': {

      }
    },
    track: {
      border: `1px solid #009B74`,
      borderRadius: 11,
      opacity: 1,
      backgroundColor: 'transparent',
      borderColor: '#333333',
    },
    checked: {},
  }),
)(Switch);

interface User {
  city: string,
  country: string,
  email: string,
  name: string
  status: string,
  profession: string,
  province: string
}
const StaffDashboard = (props: any): JSX.Element => {
  const classes = useStyles();
  const { userId } = props;
  const history = useHistory();

  const [user, setUser] = useState<User>()

  useEffect(() => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}user/profile/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      }
    }).then(res => {
      //console.log(res.data);
      setUser(res.data)
    }).catch((error) => {   
      alert("User has not setup profile");
      props.setSession("main");
    });
  }, [])

  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };
  const [selectmenu, setSelectMenu] = useState('staffListDashBoard')
  return (
    <>
      <DSPHeader
        handleToggleSidebar={handleToggleSidebar}
        toggled={toggled}
        pageName={selectmenu}
      />
      <Grid container component="main" className={classes.root}>
        <Hidden smDown={!toggled}>
          <Grid item className={`app ${toggled ? 'toggled' : ''}`}>
            <NavSidebar
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              setSelectMenu={setSelectMenu}
              selectmenu={selectmenu}
            />
          </Grid>
        </Hidden>
        {user !== undefined ?
          <Grid item md={9} className={classes.breadcumsWrapper}>
            <DSPBreadCrums
              setSession={props.setSession}
              session={props.session}
              setExpandMenu={props.setExpandMenu}
              items={[
                { name: 'My Team', link: 'MyTeam' },
                { name: user!.name, link: '' },
              ]}
            />
            <div className={classes.titleWrapper}>
              <Typography
                variant='h4'
                className={classes.titleText}
              >
                {user!.name}
              </Typography>
              {/* <AntSwitch checked={true} onChange={() => setUserActive(false)} name="checkedC" /> */}
              <AntSwitch checked={user.status === "A"} name="checkedC" />
            </div>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      <b>Name:</b> {user!.name}
                    </TableCell>
                  </TableRow>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      <b>Email:</b> {user!.email}
                    </TableCell>
                  </TableRow>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      <b>Profession/Sector: </b>{user!.profession}
                    </TableCell>
                  </TableRow>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      <b>Location: </b>{user!.city}, {user!.province}, {user!.country}
                    </TableCell>
                  </TableRow>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      <b>Organization/Agency: </b> Organization Name
                    </TableCell>
                  </TableRow>
                  <TableRow key="1">
                    <TableCell component="th" scope="row">
                      <b>Team Leader: </b>Leader Name
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <p className={classes.noteText}>To change information for your team member, please submit your request below.</p>
              <Button variant="contained" color="primary"
              onClick={() => history.push(`/staffEdit/${userId}`)}>
                Request
              </Button>
            </div>

          </Grid>:""
        }
      </Grid>

    </>
  )
}

export default StaffDashboard