/* eslint-disable */
import React, { useEffect, useState } from 'react';

import { makeStyles, Grid, Switch, Typography, ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, FormControlLabel, Checkbox, List, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import DSPHeader from './DashBoardHeader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import StaffDashboard from './MyTeam/StaffDashboard';
import {globalStyles} from "../../GlobalStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      paddingTop: '90px',
    },
    paddingTop: '64px',
  },
  listItem: {
    width: '100%',
    marginLeft:'20px',
    
    '& .MuiListItem-root': {
      paddingTop: '15px',
      paddingBottom: '15px',
    
    }
  },
  listItemContent: {
    border: '1.5px solid #1051B2',
    transition: '0.3s max-height',
    '&:first-child': {
      borderRadius: '4px 4px 0 0'
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px'
    }
  },
  listContainer: {
    margin: '0 auto'
  },
  listItemTextName: {
    fontSize: 'calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)))',
    fontWeight: 700,
    margin: 0
  },
  listItemTextEmail: {
    fontSize: 'calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)))',
    margin: '10px 0'
  },
  userActiveItem: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontFamily: '"Arial"',
      margin: '0 5px 0',
      fontSize: 'calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)))',
      '&.activated': {
        color: '#156570'
      }
    },
    '& .MuiGrid-item': {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      margin: "0 0 0 15px"
    },
    [theme.breakpoints.only('xs')]: {
      margin: "15px 0 0 15px"
    }
  },
  rightArrowItem: {
    fontSize: '3rem',
    color: '#0046AD',
    margin: 'auto',
  },
  viewBtn: {
    fontSize: 'calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)))',
    color: '#0046AD',
    fontWeight: 700,
    margin: 0,
    '&:hover': {
      backgroundColor: 'white',
    }
  },
  viewBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  chevronUpIcon: {
    transition: '0.2s all',
    '&.rotated': {
      transform: 'rotate(180deg)'
    }
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

}));

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 41,
      height: 22,
      padding: 0,
      overflow: 'unset'
    },
    switchBase: {
      padding: 0,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(19px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#009B74',
          borderColor: '#009B74',
        },
        '& $thumb': {
          background: 'white',
          border: '2px solid #009B74',
          margin: 0
        }
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
      background: '#333333',
      margin: '2px',
      border: 'none',
      '&$checked': {

      }
    },
    track: {
      border: `1px solid #009B74`,
      borderRadius: 11,
      opacity: 1,
      backgroundColor: 'transparent',
      borderColor: '#333333',
    },
    checked: {},
  }),
)(Switch);

const ModalStyle = makeStyles((theme) => ({
  userActivationModal: {
    '& .MuiDialogTitle-root': {
      padding: '60px 90px 0',
      '& h2': {
        fontSize: '24px',
        fontWeight: 'bold',
        fontFamily: '"Arial"'
      }
    },
    '& .MuiDialogContent-root': {
      padding: '24px 35px 80px 45px',
      '& p': {
        fontSize: '20px',
        color: '#333333',
        fontFamily: '"Arial"'
      }
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center'
    }
  },
  btnGroup: {
    padding: '0 45px 50px',
  },
  submitBtn: {
    color: 'white',
    borderRadius: '5px',
    backgroundColor: '#0046AD',
    padding: '5px 35px',
    border: '1px solid transparent',
    fontSize: '20px',
    fontWeight: 700,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    }
  },
  cancelBtn: {
    color: 'white',
    borderRadius: '5px',
    backgroundColor: '#818A8F',
    padding: '5px 70px',
    border: '1px solid #707070',
    fontSize: '20px',
    marginRight: '3rem',
    fontWeight: 700,
    textTransform: 'unset',
    '&:hover': {
      color: 'white',
      borderRadius: '5px',
      backgroundColor: '#818A8F',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    }
  }
}))

export interface User {
  id: number,
  email: string,
  name: string
  status: string,
  role_id: string
}

const UserActivtionModal = (props: any): JSX.Element => {
  const classes = ModalStyle();
  const deactivateUser = (userId:number,status:string) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/user/status`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        'api_key': process.env.REACT_APP_API_KEY,
        'access_token': sessionStorage.getItem('token'),
        crossdomain: true
      },
      data: {
        userId: userId,
        status: status==='D'?'A':'D'   
      }
    }).then(res => {
     
      props.closeModal(true);
      
    }).catch(error => {
      console.log(error);
    });
  }

  return (
    <>
      <Dialog
        open={props.openUserActivationModal}
        onClose={() => props.closeModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.userActivationModal}
      >
        <DialogTitle id="alert-dialog-title" >
          Are you sure you want to deactivate {props.user.email}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deactivating this account will remove this person's access. This user will no longer be able to use the Nurturing the Seed app.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnGroup}>
            <Button
              variant="contained"
              color="primary"
              className={classes.cancelBtn}
              onClick={() => props.closeModal(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" className={classes.submitBtn} onClick={() => deactivateUser(props.user.id,props.user.status)}>
              {props.user.status==='A'?'Deactive Now':'Active '}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

const MyTeam = (props: any): JSX.Element => {
  const classes = useStyles();
  const [openUserActivationModal, setOpenUserActivationModal] = useState(false);
  const [selectmenu, setSelectMenu] = useState('staffListDashBoard')
  const [userId, setUserId] = useState<number>(0)

  const [session, setSession] = useState<string>('main');
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };

  const IsGetAllUsers=props.IsGetAllUsers;
  const [openFilter, setOpenFilter] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<boolean[]>([true, true]);
  const options = ['Active Users', 'Inactive Users'];

  const [originalUserList, setOriginalUserList] = useState<User[]>([])
  const [userList, setUserList] = useState<User[]>([])
  const [clickedUser, setClickedUser] = useState<User>()

  // const handleClick = () => {
  //   console.info(`You clicked ${options[selectedIndex]}`);
  // };

  const handleMenuItemClick = (e: any, index: number) => {
    let temp = selectedIndex;
    temp[index] = !temp[index]
    setSelectedIndex(temp);

    let test: User[] = []
    if (temp[0] === true && originalUserList) {
      const filteredRows = originalUserList.filter(row => {
        return row.status === "A"
      })
      test.push(...filteredRows)
    }
    if (temp[1] === true && originalUserList) {
      const filteredRows = originalUserList.filter(row => {
        return row.status !== 'A'
      })
      test.push(...filteredRows)
    }

    setUserList(test)

    //setOpenFilter(false);
  };

  //const [searched, setSearched] = useState<string>("");
  const handleSearch = (searchedVal: string) => {
    const filteredRows = originalUserList.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase()) || row.email.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setUserList(filteredRows);
  };

  const handleToggle = () => {
    setOpenFilter((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenFilter(false);
  };

  useEffect(() => {
    let isCancelled = false;
    if(IsGetAllUsers){
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}user/allusersList`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          'access_token': sessionStorage.getItem('token'),
          crossdomain: true
        }
      }).then(res => {
        if (!isCancelled) {
          setUserList(res.data.usersList)
          setOriginalUserList(res.data.usersList)
        }
        
      }).catch(error => {
        console.log(error);
      });
    }
    else{
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}user/usersList`,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-type': 'application/json',
              'api_key': process.env.REACT_APP_API_KEY,
              'access_token': sessionStorage.getItem('token'),
              crossdomain: true
            }
          }).then(res => {
            if (!isCancelled) {
              setUserList(res.data.usersList)
              setOriginalUserList(res.data.usersList)
            }
            
          }).catch(error => {
            console.log(error);
          });
        }
    return () => {
      isCancelled = true;
    };
  }, []);

 
  const handleSwitchToggle = (e: any, user: User) => {
    setClickedUser(user)
    setOpenUserActivationModal(true)
  }

  const globalClasses=globalStyles();
  const history = useHistory();
  
  // const viewUser = (e: any, user: User) => {
  //   history.push('/staffDashboard', [user]);
  // }
  return (
    <>

      <DSPHeader
        handleToggleSidebar={handleToggleSidebar}
        toggled={toggled}
        pageName={selectmenu}
        handleSearch={handleSearch}
      />
      {session === 'main' &&          
     <>
        {clickedUser &&
          <UserActivtionModal
            openUserActivationModal={openUserActivationModal}
            closeModal={() => setOpenUserActivationModal(false)}
            user={clickedUser}
          />}
       
       <Grid item md={12}>  
            <Typography
              variant='h4'
              className={globalClasses.titleText}
            >
              My Team
            </Typography>
            <Grid item xs={12} style={{marginBottom:'40px'}}>
              <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button" style={{float:'right'}}>
                <Button
                  color="primary"
                  size="small"
                  aria-controls={openFilter ? 'split-button-menu' : undefined}
                  aria-expanded={openFilter ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <span>Filter</span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`${classes.chevronUpIcon} ${!openFilter ? "" : "rotated"}`} />
                </Button>
              </ButtonGroup>
              <Popper
                open={openFilter}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: '10' }}
                placement='bottom-end'
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: 'right bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {options.map((option, index) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedIndex[index]}
                                  onChange={(e) => handleMenuItemClick(e, index)}
                                  name={option}
                                  color="primary"
                                />
                              }
                              label={option}
                            />
                          ))}</div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          
          <List component="nav" className={classes.listItem} aria-label="contacts">
            {userList.map(user => (
              <Grid
                key={user.id}
                container
                item
                xs={12}
                className={classes.listItemContent}
                
              >
                <Grid container item md={9} xs={9}>
                  <Grid container item md={4} sm={3} xs={12}>
                    <Grid item md={12} className={classes.userActiveItem}>
                      <Grid item md={4} justifyContent="center">
                        <AntSwitch checked={user.status === "A"} onChange={(e) => handleSwitchToggle(e, user)} name="checkedC" />
                      </Grid>
                      <Grid item md={6}>
                        <p className={`${user.status === "A" ? "activated" : "inactivated"}`}>{user.status === "A" ? "Active" : "Inactive"}</p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={7} sm={9} xs={12}>
                    <ListItem>
                      <Grid item md={8} xs={12}>
                        <p className={classes.listItemTextName}>{user.name}</p>
                        <p className={classes.listItemTextEmail}>{user.email}</p>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Grid>
                <Grid item xs={3} className={classes.viewBtnWrapper}>
                  <Button className={classes.viewBtn}
                    onClick={()=>{setSession('view');setUserId(user.id);}}>View</Button>
                </Grid>
              </Grid>
            ))}
          </List>
        </Grid>

      </>}
      {session==='view'&&<StaffDashboard userId={userId} setSession={setSession} ></StaffDashboard>}


    </>
  )
}

export default MyTeam