/* eslint-disable */
import React, { useState } from 'react';
import axios from 'axios';
import { Button } from '@material-ui/core';


const ImportantContent = (props:any) => {

    const benefitItem={children:[{id:'',title:'',details:'',suggestedAge:''}]};
   

    const [showImportantContent, setShowImportantContent] = useState(false)
    const [displayButton,setDisplayButton]=useState("Why This is important");

    const [importantLists, setImportantLists] = useState(benefitItem)


    const handleImportantClick = (id:any,title:any) => {
        setShowImportantContent(!showImportantContent);
        if(!showImportantContent)
            setDisplayButton("Hide");
        else
            setDisplayButton("Why This is important")
        
        axios.get(`${process.env.REACT_APP_API_URL}/catalog/strategies/${props.domain}/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'api_key': process.env.REACT_APP_API_KEY
            }
        }).then((response) => {
            console.log(response.data)
            setImportantLists(response.data)
        });

    }



    return (
        <>
               <div>
                    <div className='content-border' key={props.id}>
                        
                        <div className='content-grid'><span>{props.details}</span>
                            <span className='show-goal align-right'><Button style={{color:'#0046AD'}} onClick={() => handleImportantClick(props.id,props.title)} >{displayButton}</Button></span>                      
                        </div>                 
                    </div>
                    {
                        <div style={{marginLeft:'20px'}}>
                            {showImportantContent&& importantLists.children.map((gd) => {
                                return ( <div>
                                    <ul>
                                        <li key={gd.id}>{gd.details}</li>                                   
                                    </ul>
                                </div>);
                            })}
                        </div>
                    }
                </div>
                              
        </>   
    )
}

export default ImportantContent;