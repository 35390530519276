import { makeStyles } from "@material-ui/core";


    const globalStyles = makeStyles((theme) => ({
        
        titleText: {
          color: '#0046AD',
          fontSize: '3rem',
          fontWeight: 'bold',
          fontFamily: 'Arial',
          marginLeft: '5%',
          marginTop:'15px',
          [theme.breakpoints.down('sm')]: {
            display: 'none',
      
          }
        },
        titleText1: {
          color: '#0046AD',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          fontFamily: 'Arial',
          marginLeft: '5%',
          marginTop:'15px',
          
        },
        subTitleText2: {
          color: '#0046AD',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          fontFamily: 'Arial',
          marginLeft: '5%',
          
        },
        content: {
          color: '#0046AD',
          fontSize: '1.2rem',
          fontFamily: 'Arial',
          marginLeft: '5%',
          
        },
        content1: {
          color: '#0046AD',
          fontSize: '1.2rem',
          fontFamily: 'Arial',      
        },
        UserListTable:{
         /* '& .header': {
            color: '#0046AD',
            fontSize: '2rem',
            fontWeight:'900 !important',
            fontFamily: 'Arial', 
          },
          */
        }
       
      }));


 
export  {globalStyles};