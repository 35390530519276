/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import signin768 from "../img/signin768.png";
import signin992 from "../img/signin992.png";
import signin1200 from "../img/signin1200.png";
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import mockup from '../img/mockup.png';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import AlertDialog from './component/AlertDialog';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${signin768})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${signin992})`,
    },

    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${signin1200})`,
    },
  },

  signinTitle: {
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
    margin: '20px auto',
    fontWeight: 600,
    color: '#0046AD'
  },

  loginBox: {
    [theme.breakpoints.between('md', 'md')]: {
      width: '550px',
      padding: '50px 100px'
    },

    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: '400px'
    },
    padding: '35px 50px',
    textAlign: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, .7)',
    height: '450px',
    width: '460px',
    justifyContent: 'center'
  },

  inner: {
    padding: '7% 0',
  },

  inputField: {
    margin: '10px auto',
    backgroundColor: 'white',
    borderRadius: '8px',
    'input': {
      borderRadius: '8px',
      border: 'none',
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      boxShadow: '0 3px 3px #e5e5e5',
      borderColor: 'rgb(255 255 255)'
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#0046AD'
    }
  },

  submitBtn: {
    margin: theme.spacing(1, 0, 2),
    background: "#D71F85",
    color: 'white',
    fontSize: '20px',
    borderRadius: '10px',
    '&.MuiButton-root:hover': {
      backgroundColor: '#D71F85'
    }
  },

  dividerContainer: {
    display: "flex",
    alignItems: "center"
  },

  dividerBorder: {
    borderBottom: "2px solid #727679",
    width: "100%"
  },

  dividerContent: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    fontSize: 14,
    color: "#727679"
  },

  mockup: {
    [theme.breakpoints.between('md', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      order: '2',
    },
  },

  mockupInner: {
    [theme.breakpoints.down('sm')]: {
      height: '200px',
      marginTop: '30px',
    },
  },

  outerLogin: {
    justifyContent: 'flex-end',
    display: 'flex',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      order: '3',
    },
  },

  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },

  forgetPW: {
    color: '#707070',
    textDecoration: 'underline',
    float: 'right',
    marginTop: '18px',
    [theme.breakpoints.down('md')]: {
      marginTop: 'auto',
      float: 'none'
    },
  },

  rememberMe: {
    float: 'left',
    marginTop: '10px',
  }

}));

export default function ForgotPassword(): JSX.Element {
  const classes = useStyles();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [email, setEmail] = useState('')
  const [isAlertOpen, setIsAlertOpen] = useState(0);
  const [alertMsg, setAlertMsg] = useState('');
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isEnableButton, setIsEnableButton] = useState(false);



  const onEmailChangeHandler = (e: any) => {
    const regEx: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(e.target.value);
    if(e.target.value !== '' && e.target.value !== null && regEx.test(e.target.value))
    {
      setIsEmailValid(true);
     
      setIsEnableButton(true);
    }else{
      
      setIsEmailValid(false);   
      setIsEnableButton(false);
    }
   

  };

  const onResetPasswordHandler = (e: any) => {
    e.preventDefault();

    if (isEmailValid && email) {
      axios({
        method: 'post',
         url: `${process.env.REACT_APP_API_URL}user/forgotPassword`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
          'api_key': process.env.REACT_APP_API_KEY,
          crossdomain: true
        },
        data: {
          email
        },
      }).then(res => {
        console.log(res)
        setIsLinkSent(true);
        // getACode(res.data.invitationCode);
        // setIsAlertOpen(false);

      }).catch(error => {
        console.log(error)
        setAlertMsg(error.response.data.error);
        setIsAlertOpen(isAlertOpen + 1);
        //console.log(error.response.data);
        // setAlertMsg(error.response.data.error);
        // setIsAlertOpen(true);
        // getACode("");
      });
    }
    else {
      setIsEmailValid(false)
    }
  }

  return (
    <>
   <AlertDialog isOpen={isAlertOpen} msg={alertMsg} />
      <Header />
      <Container maxWidth={false} disableGutters={true} className={classes.root}>
        <Box className={classes.inner}>
          <Grid container justifyContent="center" alignItems="center" className={classes.gridContainer}>
            <Grid item md={6} sm={12} xs={12} className={classes.outerLogin}>
            <Box
                className={classes.loginBox}
                style={{
                  display: isLinkSent ? 'block' : 'none'
                }}>
                <Typography
                  variant="h4"
                  className={classes.signinTitle}>
                  Link Sent
                </Typography>
                <p style={{ textAlign: 'left' }}>
                  We've sent a password recovery link to: <br />
                  <b>{email}</b>
                  <br />
                  Please allow a few minutes while the email is transit
                </p>
                <Button
                  fullWidth
                  className={classes.submitBtn}
                  href="/login"
                >Sign In</Button>
              </Box>
              <Box className={classes.loginBox} style={{
                  display: isLinkSent ? 'none' : 'block'
                }}>
                <Typography variant="h4" className={classes.signinTitle}>Forgot Your Password?</Typography>
                <Typography variant="h6">Please enter the e-mail address you use to log in, and we'll send you a link to reset your password.</Typography>
                <br/>
                <form noValidate onSubmit={onResetPasswordHandler}>
                  <TextField
                    variant="outlined"
                    error={!isEmailValid}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"               
                    className={classes.inputField}
                    onChange={onEmailChangeHandler}
                    helperText={!isEmailValid ? 'Email invalid' : ''}                  
                  />                 
                  <Button
                          fullWidth
                          disabled={!isEnableButton}
                          className={classes.submitBtn}
                          type="submit"
                          style={{background:!isEnableButton?"grey": "#D71F85",color:'white'}}
                          
                        >
                          Send Reset Link
                        </Button>
                </form>
               
                <Button href="/login">Back</Button>
             
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className={classes.mockup}>
              <img src={mockup} className={classes.mockupInner} alt="mockup" />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  )
}