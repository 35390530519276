/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Grid, Hidden, Typography, Box, Button, } from '@material-ui/core';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import DSPCover from './DSPCover';
import DSPRelationPage from './DSPRelationPage';
import DSPFridgePage from './DSPFridgePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown,faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import DSPCreationStep1 from './DSPCreation/DSPCreationStep1';
import DSPCreationStep3 from './DSPCreation/DSPCreationStep3';
import DSPCreationStep4 from './DSPCreation/DSPCreationStep4';
//import icons for plan 3
import NTS_Communication from '../../../img/NTS_Communication.png';
import NTS_SocialEmotional from '../../../img/NTS_SocialEmotional.png';
import NTS_GrossMotor from '../../../img/NTS_GrossMotor.png';
import NTS_FineMotor from '../../../img/NTS_FineMotor.png';
import NTS_ProblemSolving from '../../../img/NTS_ProblemSolving.png';
import DSPCreationStep2 from './DSPCreation/DSPCreationStep2';
import circleCheck from '../../../img/complete_check.png';
import { SaveFile } from '../../../utils/FileHelper';
import MainModal from './DSPPreview/MainModal';
import ExportDialog from './ExportDialog';
import {globalStyles} from "../../../GlobalStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      paddingTop: '90px',
    },
    paddingTop: '64px'
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#707070',
    opacity: 0.6,
  },
  DSPItem: {
    width: '100%',
  },
  titleBox: {
    marginTop: '5px',
  },
  item_number: {
    width: '60px',
    height: '60px',
    background: '#64A0C8',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '2rem',
    margin: '1.5rem',
    borderRadius: '10px',

    '& img': {
      width: '100%',
      height: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      width: '40px',
      height: '40px',
      fontSize: '1rem',
      borderRadius: '5px',
      margin: '.5rem',
    }
  },

  item_title: {
    fontSize: 'calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)))',
    marginTop: '0 auto',
    fontWeight: 'bold',
    color: 'black',
    textDecoration: 'none',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '2px 0 4px 0px',
      
    },
    [theme.breakpoints.up('xl')]: {

      margin: '0'

    }
  },
  item_title1: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-20px'
    }
  },
  item_wrapper: {
    background: 'white',
    margin: '1.2rem 0',
    boxShadow: '0 0 5px #0000002e',
   
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft:'10px',
    },
    '&.plan_menu': {
      margin: 0,
      transition: '0.3s max-height',
      visibility: 'hidden'
    },
    '&.plan_menu.active': {
      visibility: 'visible',
      marginTop: '1.2rem',
      '& $item_title': {
        margin: 0,
        textAlign: 'unset',
        justifyContent: 'unset',
      },
      '&.Communication': {
        border: '3px solid #62A0C9'
      },
      '&.GrossMotor': {
        border: '3px solid #5A595C'
      },
      '&.FineMotor': {
        border: '3px solid #E37225'
      },
      '&.ProblemSolving': {
        border: '3px solid #156671'
      }
      , '&.SocialEmotional': {
        border: '3px solid #df4461'
      }
    },
  },

  complete_text: {
    
    [theme.breakpoints.down('sm')]: {
      padding: '5px 8px',
      borderRadius: '50%',
      '& span': {
        display: 'none'
      },

    },
    [theme.breakpoints.up('md')]: {
      background: '#1565701f',
      color: '#3F9C35',
      padding: '8px 23px',
      borderRadius: '10px',
      fontWeight: 'bold',
      fontSize: '1rem',
      '& img': {
        display: 'none'
      }
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'clamp(100%, 1rem + 2vw, 24px)',
    }
  },

  previewBtn: {
    color: '#0046AD',
    border: '2px solid #0046AD',
    borderRadius: '10px',
    background: 'white',
    fontWeight: 'bold',
    font: 'Arial',
    width: '50%',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',
    }
  },
  saveBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#0046AD',
    width: '30%',
    padding: '5px',
    border: '2px solid transparent',
    marginRight: '3rem',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',

    }
  },
  exportBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#707070',
    width: '30%',
    padding: '5px',
    border: '2px solid transparent',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#707070',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '1rem',

    }
  },
  btns_wrapper: {
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      margin: '1rem auto',
      width: '50%'
    }
  },
  save_export_wrapper: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',

    }
  },

  plan_accordion: {
    width: '100%',
    '& .MuiAccordionSummary-root': {
      padding: '0',
      minHeight: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      margin: 0
    }
  },
  chevronUpIcon: {
    transition: '0.2s all',
    '&.rotated': {
      transform: 'rotate(180deg)'
    }
  },

  domain_value: {
    fontSize: '1.375rem',
    color: '#727679',
    margin: '0.5rem 0',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'90%',
      fontSize: '0.8rem',
      margin:0
    }
  }

}));

interface Item {
  key: string;
  value: {
    id: number,
    name: string,
    status: string,
    session: string
  }
}

interface DSPDomain {
  key: string;
  value: {
    prefix: string,
    imgUrl: string,
    title: string,
    desc: string,
    isInit: boolean,
  }
}

// interface BreadCrumItem 
function CreateNewDSP(props:any): JSX.Element {
  const classes = useStyles();
  const globalClasses=globalStyles();
  const history = useHistory();
  const location = useLocation();
  const [selectmenu, setSelectMenu] = useState('dsp')
  const [session, setSession] = useState<string>('main');
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };

  // data to check for completion and render all items in the menu
  const itemListData: Item[] = [
    {
      key: 'coverPage',
      value: {
        id: 1,
        name: 'Cover Page',
        status: 'current',
        session: 'coverPage'
      }
    },
    {
      key: 'relationship',
      value: {
        id: 2,
        name: 'Why Our Relationship Matters',
        status: 'not_started',
        session: 'relationship'

      }
    },
    {
      key: 'plan',
      value: {
        id: 3,
        name: 'Developmental Support Plan (DSP)',
        status: 'not_started',
        session: 'plan'

      }
    },
    {
      key: 'fridge',
      value: {
        id: 4,
        name: 'Fridge Page',
        status: 'not_started',
        session: 'fridge'
      }
    }

  ];
  // data to check for completion and render all domains in the drop down menu of item 3
  const itemListDomains: DSPDomain[] = [
    {
      key: 'communication',
      value: {
        prefix: 'com_',
        desc: 'How I Can Understand You and Talk to Others',
        imgUrl: NTS_Communication,
        title: 'Communication',
        isInit: false
      }
    },
    {
      key: 'fineMotor',
      value: {
        prefix: 'fin_',
        desc: 'How I Use My Hands',
        imgUrl: NTS_FineMotor,
        title: 'Fine Motor',
        isInit: false
      }
    },
    {
      key: 'grossMotor',
      value: {
        prefix: 'gro_',
        desc: 'How I Move My Body',
        imgUrl: NTS_GrossMotor,
        title: 'Gross Motor',
        isInit: false
      }
    },
    {
      key: 'problemSolving',
      value: {
        prefix: 'pro_',
        desc: 'How I Explore and Figure Things Out',
        imgUrl: NTS_ProblemSolving,
        title: 'Problem Solving',
        isInit: false
      }
    },
    {
      key: 'socialEmotional',
      value: {
        prefix: 'soc_',
        desc: 'How I Show My Feelings and Act Around Others ',
        imgUrl: NTS_SocialEmotional,
        title: 'Social Emotional',
        isInit: false
      }
    },
  ]

  const [itemList, setItemList] = useState<Item[]>([]);
  const [domainList, setDomainList] = useState<DSPDomain[]>([]);
  const [currDomain, setCurrDomain] = useState<DSPDomain | null>(null);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [constHeight, setConstHeight] = useState<number>(0); const content = useRef<HTMLDivElement>(null);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [showFridge, setShowFridge] = useState(false);
  const [subtitle,setSubtitle]=useState('');

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    console.log(location.state)
    if (location.state == null || location.state == undefined) {
      const token = sessionStorage.getItem('token');
      const email = sessionStorage.getItem('email');
      const userRole=sessionStorage.getItem('userRole');
      if(!props.isKeep)
        sessionStorage.clear();
      if (token)
        sessionStorage.setItem('token', token);
      if (email)
        sessionStorage.setItem('email', email);
      if(userRole)
        sessionStorage.setItem('userRole',userRole);

      setSession('main');
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    }
  }, []);

  useEffect(() => {
    let count: number = 0;
    itemListDomains.forEach(item => {

      if (sessionStorage.getItem(item.key) === 'completed') {
        item.value.isInit = true;
        itemListData[2].value.status = 'completed';
        count++;
      }
    });
    if(count==5)
      setShowFridge(true);
    setDomainList(itemListDomains);

    itemListData.every(item => {
      if (sessionStorage.getItem(item.key) === 'completed') {
        item.value.status = 'completed';
        return true;
      }

      else {
        item.value.status = 'current';
        return false;
      }

    });
    setItemList(itemListData);
  }, [session])

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    const height = content.current!.scrollHeight;
    setConstHeight(height);
    setHeightState(
      setActive === "active" ? "0px" : `${height}px`
    );
  }


  function openAccordion(command: string) {
    if (command === 'open') {
      setActiveState("active");
      setHeightState(`${constHeight}px`);
    }
    else {
      setActiveState("");
      setHeightState(`${0}px`);
    }

  }

  const toggleExportDialogOpen = () => {
    setExportDialogOpen(!exportDialogOpen);
  }

  // Render DSP DropDown list for item 3
  const DSPDropDownList = (init: boolean): JSX.Element => {
    return (
      <>
        <Grid
          container
          item
          md={12}
          xs={12}
          className={classes.item_wrapper}
          onClick={() => { if (init) toggleAccordion() }}
          style={{ marginBottom: 0, cursor: init ? 'pointer' : 'default' }}
        >
          <Grid item md={2} sm={2} xs={3}>
            <h4
              className={classes.item_number}
              style={{ background: init ? '#64A0C8' : '#707070' }}
            >3</h4>
          </Grid>


          <Grid
            item
            md={7}
            xs={7}
            className={classes.item_title1}
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              textAlign: 'center'

            }}
          >
            <p className={classes.item_title}> 
             Developmental Support Plan (DSP)
            </p>
          </Grid>
          {sessionStorage.getItem('plan') === 'completed' ? <>
            <Grid
              item
              md={2}
              xs={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {/* <span className={classes.complete_text}>
                <span></span>
                <DoneIcon />
              </span> */}

              <FontAwesomeIcon
               icon={faChevronDown}
                className={`${classes.chevronUpIcon} ${setActive === "" ? "" : "rotated"}`} />
            </Grid>
          </>
            :
            <Grid
              item
              md={2}
              xs={2}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`${classes.chevronUpIcon} ${setActive === "" ? "" : "rotated"}`} />
            </Grid>
          }

        </Grid>

        {domainList.map(domain => (
          <>
            <Grid
              key={domain.key}
              container
              item
              md={12}
              xs={12}
              className={`${classes.item_wrapper} ${domain.key.split(' ').join('')} plan_menu ${setActive === "" ? "" : "active"}`}
              ref={content}
              style={{ maxHeight: `${setHeight}`, cursor: init ? 'pointer' : 'default' }}
              onClick={() => { setSession('step1'); setCurrDomain(domain) }}
            >
              <Grid item md={2} sm={2} xs={3}>
                <div
                  className={classes.item_number}
                  style={{ borderRadius: '50%' }}
                >
                  <img src={domain.value.imgUrl} />
                </div>
              </Grid>
              <Grid
                item
                md={7}
                xs={8}
                className={classes.item_title1}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'start',
                  textAlign: 'center',
                  flexDirection: 'column'
                }}
              >
                <p
                  className={classes.item_title}
                // onClick={() => { setSession('step1'); setCurrDomain(domain) }}
                >
                  {domain.value.title}
                </p>
                <p
                  className={classes.domain_value}
                >
                  {domain.value.desc}
                </p>
              </Grid>
              {domain.value.isInit ? (
                <Grid
                  item
                  md={2}
                  xs={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span className={classes.complete_text}>
                    <span>Completed</span>
                    <img src={circleCheck} />
                  </span>

                </Grid>
              ) : ""}

            </Grid>
          </>
        ))}
      </>
    )

  }

  const renderItemList = (): JSX.Element[] => {

    let isHideFridge 
    itemListDomains
    return itemList?.map(item => {
      const isDisabled = item.value.status === 'not_started';
      
      return (
       
        item.key !== 'plan' ? (
          <>
            <Grid
              key={item.value.id}
              container
              item
              md={12}
              xs={12}
              className={classes.item_wrapper}
              onClick={() => { if (!isDisabled) setSession(item.value.session) }}
              style={{ cursor: !isDisabled ? 'pointer' : 'default',visibility : item.key==='fridge'&&!showFridge?'hidden':'visible' }}
            >
              <Grid item md={2} sm={2} xs={3}>
                <h4
                  className={classes.item_number}
                  style={{ background: isDisabled ? '#707070' : '#64A0C8' }}
                >{item.value.id}</h4>
              </Grid>
              <Grid
                item
                md={7}
                xs={7}
                className={classes.item_title1}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  textAlign: 'center'
                  
                }}
              >
                <p
                  className={classes.item_title}
                >
                  {item.value.name}
                </p>
              </Grid>
              {item.value.status === 'completed' ?
                <Grid
                  item
                  md={2}
                  xs={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <span className={classes.complete_text}>
                    <span>Completed</span>
                    <img src={circleCheck} />
                  </span>

                </Grid>
                :
                ""
              }
            </Grid>
          </>) :
          (<>
            {DSPDropDownList(!isDisabled)}
          </>)
      )
    })
  }

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const renderBtns = (): JSX.Element => {
    const isExportDisabled = sessionStorage.getItem('plan') !== 'completed';
    //const isExportDisabled=false;
    return (
      <>
        <Grid
          container
          item
          md={12}
          xs={12}
          className={classes.btns_wrapper}
        >
          <Grid item md={4} xs={12}>
            <Button
              variant="outlined"
              className={classes.previewBtn}
              onClick={() => setIsPreviewModalOpen(true)}
              disabled={sessionStorage.getItem('coverPage') !== 'completed'}
            >
              Preview
            </Button>
          </Grid>
          <Grid item md={8} xs={12} className={classes.save_export_wrapper}>
            <Button variant="contained" className={classes.saveBtn} onClick={() => SaveFile()} >
              Save Plan
            </Button>
            <Button variant="contained" style={{ backgroundColor: isExportDisabled ? '#707070' : '#0046AD', color: 'white' }} className={classes.exportBtn}
              disabled={isExportDisabled} onClick={toggleExportDialogOpen}>
              Export to PDF
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  const alertUser = (e: any) => {
    e.preventDefault();
    // Modern browsers no longer support custom messages.
    e.returnValue = "you have not saved your inputs yet! Exit from the current page, you will be losing all the data immediately. Please save your progress now!";
    console.log('alerting user');
  }

  return (
    <>
     {session === 'main'&&
      <Grid item xs={12}>
                <Typography variant="h4" className={globalClasses.titleText}>
                {props.isKeep?'Edit Plan':'Create New Plan'}
                </Typography>
                {sessionStorage.getItem('coverPage')==='completed'&&
                <Typography className={globalClasses.titleText1}>
                This plan was created for {sessionStorage.getItem('childName')} aged {sessionStorage.getItem('age')} on {sessionStorage.getItem('creationDate')} by {sessionStorage.getItem('creatorList')}.
                </Typography>}
            </Grid>
      }
        {/* <Grid container component="main" style={{ background: '#FAFAFA', marginTop: '90px' }}> */}
        {
          isPreviewModalOpen && <MainModal
            isPreviewModalOpen={isPreviewModalOpen}
            setIsPreviewModalOpen={setIsPreviewModalOpen}
          />
        }
      
        <Grid xs={10} style={{ margin: 'auto' }}>
          
          <Grid item xs={12}>

            {session === 'main' &&  <Grid item md={12}>
            
              {renderItemList()}
              {renderBtns()}
            </Grid>}
            {session === 'coverPage' && <DSPCover setSession={setSession} session={session} setExpandMenu={openAccordion} />}
            {session == 'relationship' &&
              <DSPRelationPage
                setSession={setSession}
                session={session}
                setExpandMenu={openAccordion}
              />}

            {currDomain && session == 'step1' &&
              <DSPCreationStep1
                setSession={setSession}
                session={session}
                setExpandMenu={openAccordion}
                //name={itemListDomains[0].value.title}
                domain={currDomain} />}

            {session == 'step2' &&
              <DSPCreationStep2
                setSession={setSession}
                session={session}
                setExpandMenu={openAccordion}
                domain={currDomain} />}


            {session == 'step3' &&
              <DSPCreationStep3
                setSession={setSession}
                session={session}
                setExpandMenu={openAccordion}
                domain={currDomain} />
            }
            {session == 'step4' &&
              <DSPCreationStep4
                setSession={setSession}
                session={session}
                setExpandMenu={openAccordion}
                domain={currDomain} />
            }
            {session == 'fridge' &&
              <DSPFridgePage setSession={setSession} session={session} setExpandMenu={openAccordion} />}

            <Hidden mdUp>
              {toggled && <div className={classes.overlay} onClick={() => handleToggleSidebar(!toggled)} />}
            </Hidden>
          </Grid>
        </Grid>

     
      <Prompt when={true} message="you have not saved your inputs yet! Exit from the current page, you will be losing all the data immediately. Please save your progress now!" />
      <ExportDialog open={exportDialogOpen} toggleOpen={toggleExportDialogOpen} />

    </>
  );
}

export default CreateNewDSP;