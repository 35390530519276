/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';

interface RouteParams {
    token: string
}

const ForgotPasswordValidate = () => {
    const { token } = useParams<RouteParams>();
    const history = useHistory();

    // useLayoutEffect(() => {
    //     window.addEventListener('load', validateToken(token));
    //     return () => window.removeEventListener('load', validateToken(token));
    // }, [token]);

    const validateToken = (token: any) => {
        if (token) {
            axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}user/forgotPassword/${token}`,              
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'api_key': process.env.REACT_APP_API_KEY,
                    crossdomain: true
                },

            }).then(res => {
                //console.log({ res });
                if (res.status == 200)                 
                    history.push('/ResetPassword', [{token}])
                else
                    history.push('/')

            }).catch(err => {
                console.log({ err });
                history.push('/')
            })
        }
    }
    useEffect(() => {
        validateToken(token);


    }, [])


    return (
        <></>
    )
}

export default ForgotPasswordValidate