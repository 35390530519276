/* eslint-disable no-unused-vars */
import { Button, Divider, Hidden, List, ListItem, TextField, Typography, makeStyles, Grid, InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import axios from "axios";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { globalStyles } from "../../GlobalStyles";

const useStyles = makeStyles((theme) => ({


    titleText: {
        color: '#0046AD',
        fontSize: '3rem',
        fontWeight: 'bold',
        fontFamily: 'Arial',
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',

        }
    },
    subtitleText: {
        color: '#0046AD',
        fontSize: '1.5rem',
        fontFamily: 'Arial',
        marginBottom: '20px',
        "@media (max-width: 1279px)": {
            fontSize: '1.2rem',
            textAlign: 'center'
        },
    },
    textField: {

        display: 'block',
        marginBottom: '20px',
        borderRadius: '5px',
        "&  .MuiInputBase-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: '5px',
        }
    },
    subjectField: {
        width: '65%',
        height: '60px',
        marginBottom: '20px',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        textTransform: 'none',
        color: '#727679',
        fontFamily: 'Arial',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        fontSize: '1rem',
        justifyContent: 'space-between',
    },
    errorSubjectField: {
        width: '65%',
        height: '60px',
        marginBottom: '0px',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        textTransform: 'none',
        color: '#F44336',
        borderColor: '#F44336',
        fontFamily: 'Arial',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        fontSize: '1rem',
        justifyContent: 'space-between',
    },
    sendButton: {
        borderRadius: '25px',
        width: '160px',
        background: 'linear-gradient(0deg, #002357, #0044A9)',
        marginTop: '10px',
        marginBottom: '5%',
        color: "#FFFFFF",
        fontSize: '20px',
        fontFamily: 'Arial',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    resultMessage: {
        color: '#0046AD',
        fontSize: '1rem',
        fontFamily: 'Arial',
        "@media (max-width: 1279px)": {
            fontSize: '0.8rem',
            textAlign: 'center'
        },
    },
    addressCard: {
        width: '50%',
        marginLeft: '15%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        paddingBottom: '20px',
        borderRadius: '10px',
        marginTop: '200px',
        marginBottom: '100px',
    },
    addressIcon: {
        height: '80%'
    },
    addressTextContainer: {
        color: '#0046AD',
        fontSize: '1rem',
        fontFamily: 'Helvetica',
        marginLeft: '40px',
    },
    addressText: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    subjectSelector: {
        width: '25%',
        position: 'absolute',
        marginLeft: '1%',
        top: '400px',
        backgroundColor: 'white',
        padding: '1%',
        borderRadius: '10px',
    },
    subjectSelectorMobile: {
        width: '80%',
        position: 'absolute',
        left: 0,
        right: 0,
        margin: "auto",
        top: '400px',
        backgroundColor: 'white',
        padding: '1%',
        borderRadius: '10px',
    },
    subjectRow: {
        width: '100%',
        margin: '1%',
        justifyContent: 'center',
        color: '#727679',
        display: 'block',
    },
    listContainer: {
        textAlign: 'center',
    },
    listText: {

        margin: '0 auto',
    },
}));

const ContactUs = (): JSX.Element => {
    const styles = useStyles();
    const globalClasses = globalStyles();


    const [subject, setSubject] = useState('Subject');
    const [message, setMessage] = useState('');
    const [subjectErrorMessage, setSubjectErrorMessage] = useState('');
    const [messageErrorMessage, setMessageErrorMessage] = useState('');
    const [resultMessage, setResultMessage] = useState('');
    const [subjectFieldBoundingClientRect, setSubjectFieldBoundingClientRect] = useState<DOMRect>();
    const [isSubjectSelectorOpen, setIsSubjectSelectorOpen] = useState(false);
    const subjectField = useRef<HTMLButtonElement>(null);

    const subjects = [
        "Technical support",
        "Feedback regarding strategies",
        "Personal information change",
        "Other"
    ]

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
        setMessageErrorMessage('');
    }

    useEffect(() => {
        // need to get bounds of subject field to render subject selector
        window.scrollTo(0, 0);
        setSubjectFieldBoundingClientRect(subjectField.current?.getBoundingClientRect());
    }, []);

    const handleSend = () => {

        subject === 'Subject' ? setSubjectErrorMessage('Please select a subject.') : setSubjectErrorMessage('');
        message === '' ? setMessageErrorMessage('Please enter a message.') : setMessageErrorMessage('');


        if (subject === 'Subject' || message === '') {
            return;
        }

        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/sendEmail`,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'application/json',
                'api_key': process.env.REACT_APP_API_KEY,
                'access_token': sessionStorage.getItem('token'),
                crossdomain: true
            },
            data: {
                subject: subject,
                msgBody: message,
            },

        }).then(res => {
            console.log(res);
            setSubject('Subject');
            setMessage('');
            setResultMessage('Your message has been sent.');
        }).catch(error => {
            setResultMessage('There was an error sending your message. Please try again later.');
        });
    }

    const toggleSubjectSelector = () => {
        setIsSubjectSelectorOpen(!isSubjectSelectorOpen);
    }

    const handleChange = (event: any) => {
        setSubject(event.target.value);
    };


    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h4" className={globalClasses.titleText} >
                    Contact Us
                </Typography>
                <Grid container justifyContent="center"  style={{marginTop:'30px'}}>

                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} justifyContent="center" alignItems="center">
                        <Grid item xs={12}  >
                            <Typography variant="h4">
                                Your voice is definitely important to us. If you encounter any problems OR have any feedback on our tool, feel free to contact us at <a href="mailto:iemhp.mail@sickkids.ca">iemhp.mail@sickkids.ca</a>
                            </Typography>
                        </Grid>
                        {/*
    <br />
    <Grid item xs={12}>
        <FormControl fullWidth>
            <InputLabel id="subject-select-label" style={{ marginLeft: "15px" }}>Subject</InputLabel>
            <Select
                labelId="subject-select-label"
                id="subject-select"
                value={subject}
                variant="outlined"
                label="Subject"
                required
                fullWidth
                error={subjectErrorMessage != ''}
                onChange={handleChange}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,

                }}
            >

                {subjects.map((option: string, index: number) =>
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                )}
            </Select>
            {subjectErrorMessage != '' && <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"
                style={{ 'marginBottom': '20px', 'width': '65%' }}>{subjectErrorMessage}</p>}
            <br />
            <TextField className={styles.textField}
                label='Message'
                required multiline rows={5} variant="outlined"
                fullWidth
                onChange={handleMessageChange}
                error={messageErrorMessage != ''}
                helperText={messageErrorMessage}
                value={message}
            />
        </FormControl>
    </Grid>


    <Grid item xs={12}>
        <Button variant="contained" className={styles.sendButton} onClick={handleSend}>
            Send
        </Button>
    </Grid>
    <Grid item xs={12}>
        <Typography className={styles.resultMessage} variant="h6">
            {resultMessage}
        </Typography>
    </Grid>*/}
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default ContactUs;

