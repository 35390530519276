/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';

import DSPCreationSteper from '../../../../utils/DSPCreationSteper';
import DSPBreadCrums from './DSPBreadCrums';

const useStyles = makeStyles((theme) => ({
  DSPCard: {
    marginBottom: '48px',
  },

  DSPItem: {
    width: '100%',
  },

  DSPContent: {
    position: 'relative',
    padding: "0px",
  },

  DSPFooter: {
    backgroundColor: '#009B74',
    width: '100%',
  },

  footerText: {
    color: '#fff',
    verticalAlign: 'middle',
    padding: '10px 90px',
  },

  cardContent: {
  },



  textfield: {
    width: '100%',
    height: '20vh',
    fontFamily: 'Arial',
    lineHeight: '1.5em',
    fontSize: '16px',
    background: 'white',
    border: '1px solid #707070',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      height: '15vh',
    },
    '& textarea': {
      height: '90%',
      lineHeight: '1.5rem',
      paddingRight: '20px'
    },
    '& .MuiInputBase-root': {
      height: '95%',
      paddingTop: 0,
      paddingBottom: 0
    },
    '& fieldset': {
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',

      },
    },
    // "& .Mui-focused fieldset": {
    //   borderColor: "#C52328",
    //   borderWidth: "2px"
    // },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: 'transparent'
      },
      "&.Mui-focused textarea:read-only fieldset": {
        borderColor: 'white'
      },
      "&.Mui-focused fieldset": {
        borderWidth: "3px",
        borderColor: '#64A0C8 !important',
      }
    }
  },

  titleBox: {
    marginTop: '5px',
    marginBottom: '1.5rem'

  },

  templateButton: {
    color: '#E37222',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
  },

  backBtn: {
    color: '#0046AD',
    borderColor: '#0046AD',
    borderRadius: '10px'
  },
  selectBtn: {
    color: 'white',
    borderRadius: '10px',
    backgroundColor: '#0046AD',
    '&:hover': {
      color: 'white',
      borderRadius: '10px',
      backgroundColor: '#0046AD',
    }
  },
  breadcrumb: {
    //tablet
    [theme.breakpoints.down('md')]: {
      margin: '2rem 0 1rem'
    },
  },
  age: {
    color: 'black',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    margin: '14px 0 0 14px',

  },
  editBtn: {
    width: 'fit-content',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'end',
    paddingRight: '2rem',
    fontSize: '1.2rem',
    color: '#009B74',
    marginBottom: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      marginBottom: '0',
    },
    '& .MuiButton-label': {
      flexDirection: 'column-reverse'
    }
  },
  btns_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2em 0',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      margin: '3em auto',
      width: '50%',
      '& $backBtn': {
        marginTop: '2rem',
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      margin: '3em auto',
      width: '80%',
      '& $backBtn': {
        marginTop: '2rem',
      }
    },
  },
  content_grid: {
    [theme.breakpoints.down('md')]: {

      '& + $content_wrapper': {
        height: '50vh',

      }
    },
  },
  content_wrapper: {
  },
  manual_text: {
    color: 'black',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem'
    }
  },

}));

// should be: 'completed', 'current', 'uncompleted'
interface StepValue {
  stepNum: string;
  status: string;
  name: string;
}


interface DSPDomain {
  key: string;
  value: {
    prefix: string,
    imgUrl: string,
    title: string,
    desc: string,
    isInit: boolean,
  }
}

interface Props {
  setSession: (session: string) => void;
  session: string;
  setExpandMenu: (command: string) => void;
  domain: DSPDomain;
}
function DSPCreationStep1(props: Props): JSX.Element {
  const [textAreaValue, setTextAreaValue] = useState('');

  useEffect(() => {
    if (sessionStorage.getItem(props.domain.key) === 'completed' ||
      sessionStorage.getItem(`${props.domain.value.prefix}step1_value`) !== null) {
      setTextAreaValue(sessionStorage.getItem(`${props.domain.value.prefix}step1_value`) as string);
    }
  }, [])


  const classes = useStyles();

  const stepValues: StepValue[] = [
    {
      stepNum: '1',
      status: 'current',
      name: 'What I can already do'
    },
    {
      stepNum: '2',
      status: 'uncompleted',
      name: 'My next goal'
    },
    {
      stepNum: '3',
      status: 'uncompleted',
      name: 'How you can help me reach my goal'
    },
    {
      stepNum: '4',
      status: 'uncompleted',
      name: 'Why this is important for me'
    }
  ]

  const handleNextBtn = () => {
    console.log(`${props.domain.value.prefix}step1_value`);
    console.log(textAreaValue);
    sessionStorage.setItem(`${props.domain.value.prefix}step1_value`, textAreaValue);
    props.setSession('step2')
  }

  const renderBtns = (): JSX.Element => {
    return (
      <Box className={classes.btns_wrapper}>
        <Button
          variant="outlined"
          className={classes.backBtn}
          onClick={()=>props.setSession("main")}
         
        >
          Back
        </Button>
        <Button
          variant="contained"
          className={classes.selectBtn}
          disabled={textAreaValue.length === 0}
          onClick={handleNextBtn}
        >
          Next
        </Button>
      </Box>
    )
  }
  const handleChange = (e: any): void => {
    setTextAreaValue(e.target.value);
  }
  const defaultShowing = (): JSX.Element => {
    return (
      <>
        <div className={classes.content_wrapper} style={{ background: 'white', padding: '20px' }}>
          <Typography
            variant={'h5'}
            className={classes.manual_text}
          >
            In this section, please manually input ONE of your child's strengths by using a sentence starting with “ I can..”
          </Typography>
          <Box className={classNames(classes.DSPItem, classes.DSPContent)}>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={5}
              variant="outlined"
              className={classes.textfield}
              value={textAreaValue}
              onChange={handleChange}
              focused={false}
              placeholder="eg: I can communicate with my parents by using my gestures."
            />

          </Box>

        </div>
        {renderBtns()}
      </>
    )
  }

  const renderSteps = (): JSX.Element => {
    return (
      <DSPCreationSteper steps={stepValues} />
    )
  }
  return (
    <>

      <Grid
        container
        item
        xs={11}
        style={{
          margin: '0 auto',
          display: 'block',
          height: 'unset',
        }}
      >
        <DSPBreadCrums
          setSession={props.setSession}
          session={props.session}
          setExpandMenu={props.setExpandMenu}
          items={[
            { name: 'Create New Plan', link: 'main' },
            { name: 'DSP', link: 'main' },
            { name: props.domain.value.title, link: props.session },
          ]}
        />
        <Box
          className={classNames(classes.DSPItem, classes.titleBox)}
          display={{ xs: 'none', md: 'block' }}
        >
          <Typography
            variant={'h4'}
            style={{ color: '#0046AD', fontWeight: 'bold' }}
          >
            {props.domain.value.title}
          </Typography>
        </Box>

        {renderSteps()}
        <Grid container item md={12} style={{ display: 'flex', flexDirection: 'column' }} className={classes.content_grid}>
          {defaultShowing()}
        </Grid>

      </Grid>



    </>
  )
}

export default DSPCreationStep1;